import React, { useEffect, useState, Fragment } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
//Helpers function related imports
import getDefaultBarcodeType from '../../../../../helpers/getDefaultBarcodeType';
import getDefaultCurrency from '../../../../../helpers/getDefaultCurrency';
import getDefaultVolume from '../../../../../helpers/getDefaultVolume';
import getDefaultWeight from '../../../../../helpers/getDefaultWeight';
import getDefaultWeight_KG from '../../../../../helpers/getDefaultWeight_KG';
import getDefaultDimension from '../../../../../helpers/getDefaultDimension';
import getDefaultShelfLife from '../../../../../helpers/getDefaultShelfLife';

//Apis related imports
import getShelfLifeList from '../../../../../apis/Supplier/getShelfLifeList';
import productUpdate from '../../../../../apis/Supplier/productUpdate';
import InformationIcon from "../../../../../icons/svgs/infromationIcon";

// React route related imports
import { useNavigate } from "react-router-dom";

//Redux related imports
import { useDispatch } from "react-redux";

//Components related imports
import Loader from "../../../Loader";
import AlertMessage from "../../../AlertMessage";
import TextEditor from "../../TextEditor";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// Icons related imports
import EditImageIcon from "../../../../../icons/svgs/editImageIcon";
const digitsOnly = (value) => /^\d+$/.test(value)
const numericwithhypen = (value) => /^[0-9\-]+$/.test(value)
const alphanumericwithspace = (value) => /^[a-z\d\-_\s]+$/.test(value)
const numericWithHyphenWithDot = (value) => /^[0-9-.]+$/.test(value);
const schema = yup
    .object({
        barcode: yup.lazy((value) => {
            if (value && document.getElementById(`barcode`)) {
                return yup.string()
                    .trim()
                    .min(3, "Too short - should be at least 3 characters")
                    .max(40, 'Maximum allowed characters is 40')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        hs_code: yup.lazy((value) => {
            if (value && document.getElementById(`hs_code`)) {
                return yup.string()
                    .test('Digits only', 'Enter only number or hyphen (-)', numericWithHyphenWithDot)
                    .typeError('Enter only number or hyphen (-)')
                    .max(18, 'Maximum allowed characters is 18');
            }
            return yup.string()
                .typeError('Enter only number or hyphen (-) or periods (.)')
                .notRequired();
        }), 
        price: yup.lazy((value) => {
            if (value && document.getElementById(`price`)) {
                return yup.number().positive("Min price should be above 0")
            }

            return yup.string().typeError(`Min price should be above 0`).notRequired()
        }),
        width: yup.lazy((value) => {
            if (value && document.getElementById(`width`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        height: yup.lazy((value) => {
            if (value && document.getElementById(`height`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        depth: yup.lazy((value) => {
            if (value && document.getElementById(`depth`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        volume: yup.lazy((value) => {
            if (value && document.getElementById(`volume`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        weight: yup.lazy((value) => {
            if (value && document.getElementById(`weight`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        item: yup.lazy((value) => {
            if (value && document.getElementById(`item`)) {
                return yup.number().positive("Max price should be above 0")
            }

            return yup.string().typeError(`Max price should be above 0`).notRequired()
        }),

        MSRP: yup.lazy((value) => {
            if (value && document.getElementById(`MSRP`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),

        p_p_case: yup.lazy((value) => {
            if (value && document.getElementById(`p_p_case`)) {
                return yup.string().test('Digits only', 'you must specify a number', digitsOnly)
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),

        p_p_case1: yup.lazy((value) => {
            if (value && document.getElementById(`p_p_case1`)) {
                return yup.string().test('Digits only', 'you must specify a number', digitsOnly)
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        
        casewidth: yup.lazy((value) => {
            if (value && document.getElementById(`casewidth`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        caseheight: yup.lazy((value) => {
            if (value && document.getElementById(`caseheight`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        casedepth: yup.lazy((value) => {
            if (value && document.getElementById(`casedepth`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        casevolume: yup.lazy((value) => {
            if (value && document.getElementById(`casevolume`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        caseweight: yup.lazy((value) => {
            if (value && document.getElementById(`caseweight`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),

        units_per_inner: yup.lazy((value) => {
            if (value && document.getElementById(`units_per_inner`)) {
                return yup.string().test('Digits only', 'you must specify a number', digitsOnly)
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),

        innerwidth: yup.lazy((value) => {
            if (value && document.getElementById(`innerwidth`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        innerheight: yup.lazy((value) => {
            if (value && document.getElementById(`innerheight`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        innerdepth: yup.lazy((value) => {
            if (value && document.getElementById(`innerdepth`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        innervolume: yup.lazy((value) => {
            if (value && document.getElementById(`innervolume`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        innerweight: yup.lazy((value) => {
            if (value && document.getElementById(`innerweight`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        priceComments: yup.lazy((value) => {
            if (value && document.getElementById(`priceComments`)) {
                return yup.string()
                    .trim()
                    .max(200, 'Maximum allowed characters is 200')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        gtn14: yup.lazy((value) => {
            if (value && document.getElementById(`gtn14`)) {
                return yup.string()
                    .trim()
                    .min(3, "Too short - should be at least 3 characters")
                    .max(40, 'Maximum allowed characters is 40')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        pallett1: yup.lazy((value) => {
            if (value && document.getElementById(`pallett1`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        palletth1: yup.lazy((value) => {
            if (value && document.getElementById(`palletth1`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        palletquantity: yup.lazy((value) => {
            if (value && document.getElementById(`casevolume`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        innerbarcode: yup.lazy((value) => {
            if (value && document.getElementById(`innerbarcode`)) {
                return yup.string()
                    .trim()
                    .min(3, "Too short - should be at least 3 characters")
                    .max(40, 'Maximum allowed characters is 40')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        shelf_life_value: yup.lazy((value) => {
            if (value && document.getElementById(`shelf_life_value`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        shelf_life_value: yup.lazy((value) => {
            if (value && document.getElementById(`shelf_life_value`)) {
                return yup.string().test('Digits only', 'Enter only number or hyphen (-)', numericwithhypen)
                    .typeError('Enter only number or hyphen (-)')
            }
            return yup.string().typeError(`Enter only number or hyphen (-)`).notRequired()
        }),
        container_loadability_20_ft: yup.lazy((value) => {
            if (value && document.getElementById(`container_loadability_20_ft`)) {
                return yup.number()
                    .typeError('you must specify a number')
                // return yup.string().test('Alphanumeric with space only', 'Enter only alphanumeric characters, space and underscore', alphanumericwithspace)
                //     .typeError('Enter only Alphanumeric with space')
                //     .trim()
                //     .max(40, 'Maximum allowed characters is 40')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        container_loadability_40_ft: yup.lazy((value) => {
            if (value && document.getElementById(`container_loadability_40_ft`)) {
                return yup.number()
                    .typeError('you must specify a number')
                // return yup.string().test('Alphanumeric with space only', 'Enter only alphanumeric characters, space and underscore', alphanumericwithspace)
                //     .typeError('Enter only Alphanumeric with space')
                //     .trim()
                //     .max(40, 'Maximum allowed characters is 40')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        container_loadability_40_ft_loose: yup.lazy((value) => {
            if (value && document.getElementById(`container_loadability_40_ft_loose`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        full_truck_load: yup.lazy((value) => {
            if (value && document.getElementById(`full_truck_load`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        container_loadability_20_ft_loose: yup.lazy((value) => {
            if (value && document.getElementById(`container_loadability_20_ft_loose`)) {
                return yup.number()
                    .typeError('you must specify a number')
                // return yup.string().test('Alphanumeric with space only', 'Enter only alphanumeric characters, space and underscore', alphanumericwithspace)
                //     .typeError('Enter only Alphanumeric with space')
                //     .trim()
                //     .max(40, 'Maximum allowed characters is 40')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        packaging_comments: yup.lazy((value) => {
            if (value && document.getElementById(`packaging_comments`)) {
                return yup.string()
                    .trim()
                    .min(3, "Too short - should be at least 3 characters")
                    .max(100, 'Maximum allowed characters is 100')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        inner_packaging: yup.lazy((value) => {
            if (value && document.getElementById(`inner_packaging`)) {
                return yup.string()
                    .trim()
                    .min(3, "Too short - should be at least 3 characters")
                    .max(100, 'Maximum allowed characters is 100')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        carton_case_packaging: yup.lazy((value) => {
            if (value && document.getElementById(`carton_case_packaging`)) {
                return yup.string()
                    .trim()
                    .min(3, "Too short - should be at least 3 characters")
                    .max(100, 'Maximum allowed characters is 100')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
    })
    .required();

const ProductDetailPage = (props) => {
    console.log(props)
    const {
        itemCodeChange,
        barcodeChange,
        hscodeChange,
        priceCurrentChange,
        priceChange,
        itemChange,
        msrpTypeChange,
        msrpChange,
        widthChange,
        heightChange,
        depthChange,
        dimensionsChange,
        volumeChange,
        selectedVolumeChange,
        weightChange,
        selectedWeightChange,
        selectedBarcodeChange,
        onSectionApiCallSuccess,
        containerLoadability20FtChange,
        containerLoadability40FtChange,
        containerLoadability40FtlooseChange,
        containerLoadability20FtLooseChange,
        fullTruckLoadChange
    } = props;
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const [barcodeList, setBarcodeList] = useState(props?.barcodeList || []),
        [priceCurrency, setPriceCurrency] = useState(``),
        [currencyList, setCurrencyList] = useState(props?.currencyList || []),
        [dimensionList, setDimensionList] = useState(props?.dimensionList || []),
        [shelfLifeList, setShelfLifeList] = useState([]),
        [volumeList, setvolumeList] = useState(props?.volumeList || []),
        [weightList, setWeightList] = useState(props?.weightList || []),
        [productDetails, setProductDetails] = useState(props?.productDetails || {}),
        [isLoading, setIsLoading] = useState(false),
        [selectedBarcodeType, setSelectedBarcodeType] = useState(productDetails?.barcodetype || getDefaultBarcodeType() || ``),
        [selectedPriceCurrency, setSelectedPriceCurrency] = useState(productDetails?.product_price?.[0]?.s_price_currency || getDefaultCurrency() || ``),
        [selectedMSRPType, setSelectedMSRPType] = useState(productDetails?.product_price?.[0]?.m_price_currency || getDefaultCurrency() || ``),
        [barcodeValue, setBarcodeValue] = useState(productDetails?.barcode || ``),
        [hsCodeValue, setHsCodeValue] = useState(productDetails?.hs_code || ``),
        [itemCodeValue, setItemCodeValue] = useState(productDetails?.sku_id || ``),
        [priceValue, setPriceValue] = useState(parseFloat(productDetails?.product_price?.[0]?.s_price_from) || ``),
        [itemValue, setItemValue] = useState(parseFloat(productDetails?.product_price?.[0]?.s_price_to) || ``),
        [msrpValue, setMsrpValue] = useState(parseFloat(productDetails?.product_price?.[0]?.m_price) || ``),
        [propercase, setPropercase] = useState(parseFloat(productDetails?.product_price?.[0]?.p_p_case) || ``),
        [casePrice, setCasePrice] = useState(``),
        [procomments, setProcomments] = useState(productDetails?.product_price?.[0]?.p_comments || ``),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [productName, setProductName] = useState(productDetails?.name || ``),
        [productpackagingAvailabel, setProductpackagingAvailabel] = useState(productDetails?.product_packaging?.[0]?.product_packaging == true ? 'yes' : productDetails?.product_packaging?.[0]?.product_packaging == false ? 'no' : 'no'),
        [selectedDimesnion, setSelectedDimension] = useState(productDetails?.product_packaging?.[0]?.dimensiontype || getDefaultDimension() || ``),
        [selectedVolume, setSelectedVolume] = useState(productDetails?.product_packaging?.[0]?.volumetype || getDefaultVolume() || ``),
        [selectedWeight, setSelectedWeight] = useState(productDetails?.product_packaging?.[0]?.weighttype || getDefaultWeight() || ``),
        [productWidth, setProductWidth] = useState(productDetails?.product_packaging?.[0]?.pro_width || ``),
        [productHeight, setProductHeight] = useState(productDetails?.product_packaging?.[0]?.pro_height || ``),
        [productDepth, setProductDepth] = useState(productDetails?.product_packaging?.[0]?.pro_depth || ``),
        [productVolume, setProductVolume] = useState(productDetails?.product_packaging?.[0]?.pro_volume || ``),
        [productWeight, setProductWeight] = useState(productDetails?.product_packaging?.[0]?.pro_weight || ``),
        [isProductNameHasError, setIsProductNameHasError] = useState(false),
        [productNameErrorMessage, setProductNameErrorMessage] = useState(``),
        [isItemCodeHasError, setIsItemCodeHasError] = useState(false),
        [itemCodeErrorMessage, setItemCodeErrorMessage] = useState(``),
        [productcasepackAvailable, setProductcasepackAvailable] = useState(productDetails?.product_case_package?.[0]?.product_case_pack == true ? 'yes' : productDetails?.product_case_package?.[0]?.product_case_pack == false ? 'no' : 'no'),
        [productCaseGtin, setProductCaseGtin] = useState(productDetails?.product_case_package?.[0]?.product_gtin || ``),
        [selectedCaseDimesnion, setSelectedCaseDimension] = useState(productDetails?.product_case_package?.[0]?.dimensiontype || getDefaultDimension() || ``),
        [selectedCaseVolume, setSelectedCaseVolume] = useState(productDetails?.product_case_package?.[0]?.volumetype || getDefaultVolume() || ``),
        [selectedCaseWeight, setSelectedCaseWeight] = useState(productDetails?.product_case_package?.[0]?.weighttype || getDefaultWeight_KG() || ``),
        [productCaseWidth, setProductCaseWidth] = useState(productDetails?.product_case_package?.[0]?.case_width || ``),
        [productCaseHeight, setProductCaseHeight] = useState(productDetails?.product_case_package?.[0]?.case_height || ``),
        [productCaseDepth, setProductCaseDepth] = useState(productDetails?.product_case_package?.[0]?.case_depth || ``),
        [productCaseVolume, setProductCaseVolume] = useState(productDetails?.product_case_package?.[0]?.case_volume || ``),
        [productCaseWeight, setProductCaseWeight] = useState(productDetails?.product_case_package?.[0]?.case_weight || ``),
        [productCasePalletet1, setProductCasePalletet1] = useState(productDetails?.product_case_package?.[0]?.case_pallete_t1 || ``),
        [productCasePalleteh1, setProductCasePalleteh1] = useState(productDetails?.product_case_package?.[0]?.case_pallete_h1 || ``),
        [container_loadability_20_ft, setcontainer_loadability_20_ft] = useState(productDetails?.product_case_package?.[0]?.container_loadability_20_ft || ``),
        [container_loadability_20_ft_loose, setcontainer_loadability_20_ft_loose] = useState(productDetails?.product_case_package?.[0]?.container_load_20_ft_loose || ``),
        [container_loadability_40_ft_loose, setcontainer_loadability_40_ftloose] = useState(productDetails?.product_case_package?.[0]?.container_load_40_ft_loose || ``),
        [container_loadability_40_ft, setcontainer_loadability_40_ft] = useState(productDetails?.product_case_package?.[0]?.container_loadability_40_ft || ``),
        [full_truck_load, setfull_truck_load] = useState(productDetails?.product_case_package?.[0]?.full_truck_load || ``),
        [productCasePalletequan, setProductCasePalletequan] = useState(productDetails?.product_case_package?.[0]?.case_pallete_quantity || ``),
        [productinnerpackAvailable, setProductinnerpackAvailable] = useState(productDetails?.product_inner_package?.[0]?.product_inner_pack == true ? 'yes' : productDetails?.product_inner_package?.[0]?.product_inner_pack == false ? 'no' : 'no'),
        [unitesperInner, setUnitesperInner] = useState(parseFloat(productDetails?.product_inner_package?.[0]?.units_per_inner) || ``),
        [productInnerGtin, setProductInnerGtin] = useState(productDetails?.product_inner_package?.[0]?.pro_inner_barcode || ``),
        [selectedInnerDimesnion, setSelectedInnerDimension] = useState(productDetails?.product_inner_package?.[0]?.dimensiontype || getDefaultDimension() || ``),
        [selectedInnerVolume, setSelectedInnerVolume] = useState(productDetails?.product_inner_package?.[0]?.volumetype || getDefaultVolume() || ``),
        [selectedInnerWeight, setSelectedInnerWeight] = useState(productDetails?.product_inner_package?.[0]?.weighttype || getDefaultWeight_KG() || ``),
        [productInnerWidth, setProductInnerWidth] = useState(productDetails?.product_inner_package?.[0]?.inner_width || ``),
        [productInnerHeight, setProductInnerHeight] = useState(productDetails?.product_inner_package?.[0]?.inner_height || ``),
        [productInnerDepth, setProductInnerDepth] = useState(productDetails?.product_inner_package?.[0]?.inner_depth || ``),
        [productInnerVolume, setProductInnerVolume] = useState(productDetails?.product_inner_package?.[0]?.inner_volume || ``),
        [productInnerWeight, setProductInnerWeight] = useState(productDetails?.product_inner_package?.[0]?.inner_weight || ``),
        [productShelflifeavailable, setProductShelflifeavailable] = useState(productDetails?.products_detail?.[0]?.shelf_life ? 'yes' : 'no'),
        [selectedShelflifeType, setSelectedShelflifeType] = useState(productDetails?.products_detail?.[0]?.shelf_life_type || getDefaultShelfLife() || ``),
        [productShelflifevalue, setProductShelflifevalue] = useState(productDetails?.products_detail?.[0]?.shelf_life_value || ``),
        [productIngredientsavailable, setProductIngredientsavailable] = useState(productDetails?.products_detail?.[0]?.pro_ingredients ? 'yes' : 'no'),
        [ingredientsValues, setIngredientsValues] = useState(productDetails?.products_ingredients || []),
        [isIngredientError, setIngredientErrorValue] = useState(false),
        [isIngredientErrorCheck, setIngredientErrorCheckValue] = useState(false),
        [productNutritionalavailable, setProductNutritionalavailable] = useState(productDetails?.products_detail?.[0]?.nutritional_label ? 'yes' : 'no'),
        [NutritionImage, setNutritionImage] = useState(productDetails?.products_detail?.[0]?.nutritioanl_file || ``),
        [NutritionuploadImage, setNutritionuploadImage] = useState(``),
        [productDescription, setProductDescription] = useState(productDetails?.p_description || ``),
        [packagingComment, setPackagingComment] = useState(productDetails?.product_packaging?.[0]?.pack_description || ``),
        [innerPackagingComments, setInnerPackgaingComments] = useState(productDetails?.product_case_package?.[0]?.case_description || ``),
        [cartonPackagingComments, setCartongPackaginComments] = useState(productDetails?.product_inner_package?.[0]?.inner_description || ``),
        [userSettings, setUserSettings] = useState(props?.userSettings || {});

    const dispatch = useDispatch(), navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        getShelfLifeList()
            .then((response) => {
                if (response?.status === 200 && response?.data?.status && response?.data?.data) {
                    setShelfLifeList(response?.data?.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have errot at ShelfLife", error);
            });
    }, []);

    useEffect(() => {
        let productDetails = props?.productDetails || {};
        setProductDetails(productDetails);
        setProductName(productDetails?.name);
        setPriceValue(parseFloat(productDetails?.product_price?.[0]?.s_price_from));
        setItemValue(parseFloat(productDetails?.product_price?.[0]?.s_price_to));
        setSelectedPriceCurrency(productDetails?.product_price?.[0]?.s_price_currency || getDefaultCurrency() || ``);
        setMsrpValue(parseFloat(productDetails?.product_price?.[0]?.m_price) || ``);
        setPropercase(parseInt(productDetails?.product_price?.[0]?.p_p_case) || ``);
        setProcomments(productDetails?.product_price?.[0]?.p_comments || ``);
        setSelectedMSRPType(productDetails?.product_price?.[0]?.m_price_currency || getDefaultCurrency() || ``);
        setSelectedBarcodeType(productDetails?.barcodetype || getDefaultBarcodeType() || ``);
        setBarcodeValue(productDetails?.barcode || ``);
        setHsCodeValue(productDetails?.hs_code || ``);
        setItemCodeValue(productDetails?.sku_id || ``);
        setProductWidth(productDetails?.product_packaging?.[0]?.pro_width || ``);
        setProductHeight(productDetails?.product_packaging?.[0]?.pro_height || ``);
        setProductDepth(productDetails?.product_packaging?.[0]?.pro_depth || ``);
        setProductVolume(productDetails?.product_packaging?.[0]?.pro_volume || ``);
        setProductWeight(productDetails?.product_packaging?.[0]?.pro_weight || ``);
        setProductpackagingAvailabel(productDetails?.product_packaging?.[0]?.product_packaging == true ? 'yes' : productDetails?.product_packaging?.[0]?.product_packaging == false ? 'no' : 'no');
        setSelectedDimension(productDetails?.product_packaging?.[0]?.dimensiontype || getDefaultDimension() || ``);
        setSelectedVolume(productDetails?.product_packaging?.[0]?.volumetype || getDefaultVolume() || ``);
        setSelectedWeight(productDetails?.product_packaging?.[0]?.weighttype || getDefaultWeight() || ``);
        setProductcasepackAvailable(productDetails?.product_case_package?.[0]?.product_case_pack == true ? 'yes' : productDetails?.product_case_package?.[0]?.product_case_pack == false ? 'no' : 'no');
        setProductCaseGtin(productDetails?.product_case_package?.[0]?.product_gtin || ``);
        setProductCaseWidth(productDetails?.product_case_package?.[0]?.case_width || ``);
        setProductCaseHeight(productDetails?.product_case_package?.[0]?.case_height || ``);
        setProductCaseDepth(productDetails?.product_case_package?.[0]?.case_depth || ``);
        setProductCaseVolume(productDetails?.product_case_package?.[0]?.case_volume || ``);
        setProductCaseWeight(productDetails?.product_case_package?.[0]?.case_weight || ``);
        setSelectedCaseDimension(productDetails?.product_case_package?.[0]?.dimensiontype || getDefaultDimension() || ``);
        setSelectedCaseVolume(productDetails?.product_case_package?.[0]?.volumetype || getDefaultVolume() || ``);
        setSelectedCaseWeight(productDetails?.product_case_package?.[0]?.weighttype || getDefaultWeight_KG() || ``);
        setProductCasePalletet1(productDetails?.product_case_package?.[0]?.case_pallete_t1 || ``);
        setProductCasePalleteh1(productDetails?.product_case_package?.[0]?.case_pallete_h1 || ``);
        setcontainer_loadability_20_ft(productDetails?.product_case_package?.[0]?.container_loadability_20_ft || ``);
        setcontainer_loadability_40_ft(productDetails?.product_case_package?.[0]?.container_loadability_40_ft || ``);
        setcontainer_loadability_20_ft_loose(productDetails?.product_case_package?.[0]?.container_load_20_ft_loose || ``);
        setcontainer_loadability_40_ftloose(productDetails?.product_case_package?.[0]?.container_load_40_ft_loose || ``);
        setProductCasePalletequan(productDetails?.product_case_package?.[0]?.case_pallete_quantity || ``);
        setProductinnerpackAvailable(productDetails?.product_inner_package?.[0]?.product_inner_pack == true ? 'yes' : productDetails?.product_inner_package?.[0]?.product_inner_pack == false ? 'no' : 'no');
        setUnitesperInner(parseInt(productDetails?.product_inner_package?.[0]?.units_per_inner) || ``);
        setProductInnerGtin(productDetails?.product_inner_package?.[0]?.pro_inner_barcode || ``);
        setProductInnerWidth(productDetails?.product_inner_package?.[0]?.inner_width || ``);
        setProductInnerHeight(productDetails?.product_inner_package?.[0]?.inner_height || ``);
        setProductInnerDepth(productDetails?.product_inner_package?.[0]?.inner_depth || ``);
        setProductInnerVolume(productDetails?.product_inner_package?.[0]?.inner_volume || ``);
        setProductInnerWeight(productDetails?.product_inner_package?.[0]?.inner_weight || ``);
        setSelectedInnerDimension(productDetails?.product_inner_package?.[0]?.dimensiontype || getDefaultDimension() || ``);
        setSelectedInnerVolume(productDetails?.product_inner_package?.[0]?.volumetype || getDefaultVolume() || ``);
        setSelectedInnerWeight(productDetails?.product_inner_package?.[0]?.weighttype || getDefaultWeight_KG() || ``);
        setProductShelflifeavailable(productDetails?.products_detail?.[0]?.shelf_life ? 'yes' : 'no');
        setSelectedShelflifeType(productDetails?.products_detail?.[0]?.shelf_life_type || getDefaultShelfLife() || ``);
        setProductShelflifevalue(productDetails?.products_detail?.[0]?.shelf_life_value || ``);
        setProductIngredientsavailable(productDetails?.products_detail?.[0]?.pro_ingredients ? 'yes' : 'no');
        setProductIngredientsavailable(productDetails?.products_detail?.[0]?.pro_ingredients ? 'yes' : 'no');
        setProductIngredientsavailable(productDetails?.products_detail?.[0]?.pro_ingredients ? 'yes' : 'no');
        setIngredientsValues(productDetails?.products_ingredients || []);
        setProductNutritionalavailable(productDetails?.products_detail?.[0]?.nutritional_label ? 'yes' : 'no');
        setNutritionImage(productDetails?.products_detail?.[0]?.nutritioanl_file || ``);
        setProductDescription(productDetails?.p_description || ``);
        setPackagingComment(productDetails?.product_packaging?.[0]?.pack_description || ``);
        setInnerPackgaingComments(productDetails?.product_case_package?.[0]?.case_description || ``);
        setCartongPackaginComments(productDetails?.product_inner_package?.[0]?.inner_description || ``);

        if (productDetails?.products_ingredients && productDetails?.products_ingredients.length > 0) {
            setIngredientsValues(productDetails?.products_ingredients?.[0]?.name || ``)
        }

    }, [props?.productDetails]);

    useEffect(() => {
        setUserSettings(props?.userSettings);
    }, [props?.userSettings])

    useEffect(() => {
        setBarcodeList(props?.barcodeList || []);
    }, [props?.barcodeList])

    useEffect(() => {
        setCurrencyList(props?.currencyList || []);
    }, [props?.currencyList]);

    useEffect(() => {
        currencyList && currencyList.length > 0 &&
            currencyList.map((country) => {
                if (country[`currency_id`] === selectedPriceCurrency) {
                    setPriceCurrency(country[`currency`]);
                }
            })
    }, [priceCurrency, selectedPriceCurrency, currencyList])

    useEffect(() => {
        setDimensionList(props?.dimensionList || []);
    }, [props?.dimensionList]);

    useEffect(() => {
        setvolumeList(props?.volumeList || []);
    }, [props?.volumeList]);

    useEffect(() => {
        setWeightList(props?.weightList || []);
    }, [props?.weightList])

    useEffect(() => {

        let caseprice = "-"
        let concaseprice1 = 0
        let concaseprice2 = 0
        let percase = parseInt(propercase);
        if (percase) {

            if (priceValue && itemValue) {
                concaseprice1 = parseFloat(priceValue) * propercase
                concaseprice2 = parseFloat(itemValue) * propercase
                caseprice = priceCurrency + " " + concaseprice1 + " - " + priceCurrency + " " + concaseprice2
            }
            else if (priceValue) {
                concaseprice1 = parseFloat(priceValue) * propercase
                caseprice = priceCurrency + " " + concaseprice1
            }
            else if (itemValue) {
                concaseprice2 = parseFloat(itemValue) * propercase
                caseprice = priceCurrency + " " + concaseprice2
            }
        }
        setCasePrice(caseprice)

    }, [priceValue, itemValue, propercase, priceCurrency])

    const onSubmit = (data) => {

        let tempCountryList = [];
        if (!productName) {
            setIsProductNameHasError(true);
            setProductNameErrorMessage(`Product Name is required`);
            return;
        }

        if (!itemCodeValue) {
            setIsItemCodeHasError(true);
            setItemCodeErrorMessage(`Seller SKU ID is required`);
            return;
        }

        if (data[`price`] && data[`item`]) {
            if (data[`price`] >= data[`item`]) {
                setAlternateMessageShow(true);
                setAlertMessageType("information");
                setAlertMessage(`Maximum price should be greater than minimum price`);
                return;
            }
        }

        // let tempArray = [];
        // ingredientsValues && ingredientsValues.length > 0 &&
        //     ingredientsValues.map((data) => {
        //         tempArray.push(data[`name`]);
        //     })

        let priceObject = {
            "s_price_currency_id": selectedPriceCurrency ? selectedPriceCurrency : ``,
            "s_price_from": data[`price`] ? data[`price`] || priceValue : priceValue || ``,
            "s_price_to": data[`item`] ? data[`item`] || itemValue : itemValue || ``,
            "m_price_currency_id": selectedMSRPType ? selectedMSRPType : ``,
            "m_price": data[`MSRP`] ? data[`MSRP`] || msrpValue : msrpValue || ``,
            "p_p_case": propercase?  propercase : propercase || ``,
            "p_comments": data[`p_comments`] ? data[`p_comments`] || procomments : procomments || ``,
        }


        if (priceObject[`m_price`] && priceObject[`s_price_from`] && (priceObject[`m_price`] < priceObject[`s_price_from`] || priceObject[`m_price`] < priceObject[`s_price_to`])) {
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`Retail Price has to be greater than maximum price range`);
            return;
        }

        if (priceObject[`m_price`] && priceObject[`s_price_from`] && (priceObject[`m_price`] == priceObject[`s_price_from`] || priceObject[`m_price`] == priceObject[`s_price_to`])) {
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`Retail Price cannot to be equal maximum price range`);
            return;
        }


        if (productDetails?.products_sold_countries && productDetails?.products_sold_countries.length > 0) {
            productDetails?.products_sold_countries.map((soldCountry) => {
                tempCountryList.push(soldCountry?.country);
            })
        }

        const pageDimension = {
            "product_packaging": productpackagingAvailabel == "yes" ? "True" : "False",
            "dimensiontype_id": selectedDimesnion ? selectedDimesnion : '',
            "pro_width": productWidth ? productWidth : '',
            "pro_height": productHeight ? productHeight : '',
            "pro_depth": productDepth ? productDepth : '',
            "volumetype_id": selectedVolume ? selectedVolume : '',
            "pro_volume": productVolume ? productVolume : '',
            "weighttype_id": selectedWeight ? selectedWeight : '',
            "pro_weight": productWeight ? productWeight : '',
            "pack_description": packagingComment ? packagingComment : '',
        }

        const pageCaseDimension = {
            "product_case_pack": productcasepackAvailable == "yes" ? "True" : "False",
            "product_gtin": productCaseGtin ? productCaseGtin : '',
            "dimensiontype_id": selectedCaseDimesnion ? selectedCaseDimesnion : '',
            "case_width": productCaseWidth ? productCaseWidth : '',
            "case_height": productCaseHeight ? productCaseHeight : '',
            "case_depth": productCaseDepth ? productCaseDepth : '',
            "volumetype_id": selectedCaseVolume ? selectedCaseVolume : '',
            "case_volume": productCaseVolume ? productCaseVolume : '',
            "weighttype_id": selectedCaseWeight ? selectedCaseWeight : '',
            "case_weight": productCaseWeight ? productCaseWeight : '',
            "case_pallete_t1": productCasePalletet1 ? productCasePalletet1 : '',
            "case_pallete_h1": productCasePalleteh1 ? productCasePalleteh1 : '',
            "container_loadability_20_ft": container_loadability_20_ft ? container_loadability_20_ft : '',
            "container_loadability_40_ft": container_loadability_40_ft ? container_loadability_40_ft : '',
            "container_load_20_ft_loose" : container_loadability_20_ft_loose ? container_loadability_20_ft_loose : '',
            "container_load_40_ft_loose": container_loadability_40_ft_loose ? container_loadability_40_ft_loose : '',
            "full_truck_load": full_truck_load ? full_truck_load : '',
            "case_pallete_quantity": productCasePalletequan ? productCasePalletequan : '',
            "case_description": innerPackagingComments ? innerPackagingComments : '',
        }

        const pageInnerDimension = {
            "product_inner_pack": productinnerpackAvailable == "yes" ? "True" : "False",
            "units_per_inner": data[`units_per_inner`] ? data[`units_per_inner`] || unitesperInner : unitesperInner || ``,
            "pro_inner_barcode": productInnerGtin ? productInnerGtin : '',
            "dimensiontype_id": selectedInnerDimesnion ? selectedInnerDimesnion : '',
            "inner_width": productInnerWidth ? productInnerWidth : '',
            "inner_height": productInnerHeight ? productInnerHeight : '',
            "inner_depth": productInnerDepth ? productInnerDepth : '',
            "volumetype_id": selectedCaseVolume ? selectedCaseVolume : '',
            "inner_volume": productInnerVolume ? productInnerVolume : '',
            "weighttype_id": selectedInnerWeight ? selectedInnerWeight : '',
            "inner_weight": productInnerWeight ? productInnerWeight : '',
            "inner_description": cartonPackagingComments ? cartonPackagingComments : ''
        }

        const productDetailObject = {
            "shelf_life": productShelflifeavailable == "yes" ? "True" : "False",
            "shelf_life_type_id": selectedShelflifeType ? selectedShelflifeType : ``,
            "shelf_life_value": productShelflifevalue ? productShelflifevalue : ``,
            "pro_ingredients": productIngredientsavailable == "yes" ? "True" : "False",
            "nutritional_label": productNutritionalavailable == "yes" ? "True" : "False",
            "nutritioanl_file": NutritionImage ? NutritionImage : ``,
        }

        const productObject = {
            'productId': productDetails?.products_id,
            'name': productName,
            'sku_id': itemCodeValue,
            'barcodetype': selectedBarcodeType ? selectedBarcodeType : ``,
            'barcode': data[`barcode`] ? data[`barcode`] || barcodeValue : barcodeValue || ``,
            'hs_code': data[`hs_code`] ? data[`hs_code`] || hsCodeValue : hsCodeValue || ``,
            "price": priceObject,
            "pageDimension": pageDimension,
            "pageCaseDimension": pageCaseDimension,
            "pageInnerDimension": pageInnerDimension,
            "productDetailObject": productDetailObject,
            "productIngredients": ingredientsValues || '',
            "countriesSoldList": tempCountryList.join(),
            "p_description": productDescription
        }

        setIsLoading(true);
        productUpdate(productObject)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response?.data?.message);
                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(true);
                        }
                    }, 300);

                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(false);
                        }
                    }, 300);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setTimeout(() => {
                    if (onSectionApiCallSuccess) {
                        onSectionApiCallSuccess(false);
                    }
                }, 300);
                console.log("We have error at productUpdate", error);
            })
    }


    const onProductPackagingAvailableChange = (event) => {
        setProductpackagingAvailabel(event.target.value);
    };
    const onProductShelflifeChange = (event) => {
        setProductShelflifeavailable(event.target.value);
    };
    const onProductnutritionalAvailableChange = (event) => {
        setProductNutritionalavailable(event.target.value);
    };
    const onProductingredientsAvailableChange = (event) => {
        setProductIngredientsavailable(event.target.value);
    };

    const onproductcasepackAvailableChange = (event) => {
        setProductcasepackAvailable(event.target.value);
    };

    const onproductinnerpackAvailableChange = (event) => {
        setProductinnerpackAvailable(event.target.value);
    };
    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onProductNameChange = (value) => {

        if (value === ``) {
            setIsProductNameHasError(true);
            setProductNameErrorMessage(`Product Name is required`);
        } else if (value.length < 3) {
            setIsProductNameHasError(true);
            setProductNameErrorMessage(`Too short - should be at least 3 characters`);
        } else if (value.length > 40) {
            setIsProductNameHasError(true);
            setProductNameErrorMessage(`Maximum allowed characters is 40`);
        } else {
            setIsProductNameHasError(false);
            setProductNameErrorMessage(``);
        }
        setProductName(value);
    }

    const onItemCodeChange = (value) => {
        if (value === ``) {
            setIsItemCodeHasError(true);
            setItemCodeErrorMessage(`Seller SKU ID is required`);
        } else if (value.length < 3) {
            setIsItemCodeHasError(true);
            setItemCodeErrorMessage(`Too short - should be at least 3 characters`);
        } else if (value.length > 100) {
            setIsItemCodeHasError(true);
            setItemCodeErrorMessage(`Maximum allowed characters is 100`);
        } else {
            setIsItemCodeHasError(false);
            setItemCodeErrorMessage(``);
        }

        if (itemCodeChange) itemCodeChange(value);
        setItemCodeValue(value);
    }

    const onIngredientClick = (event) => {

        if (event.key === "Enter") {
            let targetvalume = event.target.value
            setIngredientsValues(prevState => [...prevState, { name: targetvalume.trim() }]);
            document.getElementById(`ingredients`).value = ``;
            event.preventDefault();
        }
    }

    const removeChip = (index, data, setCallback, type) => {
        let tempArray = data;
        tempArray = tempArray.filter((data, i) => {
            return i !== index;
        })
        setCallback(tempArray);
    }

    const showUploadImage = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (event) => {

            resolve(event.target.result);
        }
        reader.onerror = (error) => {
            reject(error);
        }
    });

    const onNutritionImageUpload = async (file) => {
        setNutritionuploadImage(file);
        setNutritionImage(await showUploadImage(file));
    }
    const removeImage = () => {
        setNutritionImage(``);
        if (document.getElementById(`nutritionimage`)) {
            document.getElementById(`nutritionimage`).value = ``;
        }
    }

    return (
        <Fragment>
            <form className={`product-details-wrapper form-input-control`} style={{ position: "relative" }} onSubmit={handleSubmit(onSubmit)}>
                <div className={`heading`}>
                    Product Detail
                </div>
                <div className={`fields-container`}>

                    <Box className={`form-control margin-bottom-28`}>
                        {isLoading && <Loader />}
                        {isAlertMessageShow &&
                            <AlertMessage
                                message={alertMessage}
                                alertType={alertMessageType}
                                isAlertShow={isAlertMessageShow}
                                onClose={onAlertClose}
                            />
                        }

                        <Typography className={`label`}>Product Name</Typography>
                        <TextField
                            type={"text"}
                            id="productName"
                            name="productName"
                            className={`input-wrap`}
                            placeholder={`Enter Product Name`}
                            margin="dense"
                            spellCheck={false}
                            error={isProductNameHasError ? true : false}
                            value={productName}
                            inputProps={{
                                onInput: (e) => {
                                    onProductNameChange(e.target.value);
                                },
                                maxLength: 40
                            }}
                        />
                        {
                            productNameErrorMessage &&
                            <p className={`field-err-msg`}>{productNameErrorMessage}</p>
                        }
                    </Box>
                    <Box className={`form-control margin-bottom-28`}>
                        <div className={`itemcode-wrap`}>
                            <Typography className={`label`}>Seller SKU ID<span className="asterisks">*</span></Typography>
                            <TextField
                                type={"text"}
                                id="Itemcode"
                                name="Itemcode"
                                className={`input-wrap itemcode-input`}
                                placeholder={`Enter Itemcode`}
                                margin="dense"
                                spellCheck={false}
                                error={isItemCodeHasError ? true : false}
                                value={itemCodeValue}
                                inputProps={{
                                    onInput: (e) => {
                                        onItemCodeChange(e.target.value);
                                    },
                                    maxLength: 100
                                }}
                            />
                            {
                                itemCodeErrorMessage &&
                                <p className={`field-err-msg`}>{itemCodeErrorMessage}</p>
                            }

                        </div>
                    </Box>
                    <Box className={`form-control margin-bottom-28 barcode-itemcode-wrapper`}>
                        <div>
                            <Typography className={`label`}>Product barcode</Typography>
                            <div className={`barcode-wrap custom-black-select-option`}>

                                <div className={`barcode-dropdown`}>
                                    <select id="barcode-select" onChange={(event) => { if (selectedBarcodeChange) { selectedBarcodeChange(event.target.value) }; setSelectedBarcodeType(event.target.value) }}>
                                        {
                                            barcodeList && barcodeList.length > 0 &&
                                            barcodeList.map((list, index) => {
                                                return (
                                                    <option
                                                        value={list[`barcodetype_id`]}
                                                        key={index}
                                                        selected={selectedBarcodeType === list[`barcodetype_id`] ? 'selected' : ''}
                                                    >
                                                        {list[`name`]}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <TextField
                                        type={"text"}
                                        id="barcode"
                                        name="barcode"
                                        className={`input-wrap barcode-input`}
                                        placeholder={`Enter Barcode`}
                                        margin="dense"
                                        spellCheck={false}
                                        {...register(`barcode`)}
                                        error={errors.barcode ? true : false}
                                        value={barcodeValue}
                                        inputProps={{
                                            onInput: (e) => {
                                                if (barcodeChange) barcodeChange(e.target.value)
                                                setBarcodeValue(e.target.value)
                                            }
                                        }}
                                    />

                                </div>
                            </div>
                            <p className={`field-err-msg`}>{errors.barcode?.message}</p>
                        </div>
                    </Box>
                    <Box className={`form-control margin-bottom-28 barcode-itemcode-wrapper`}>
                        <div>
                            <Typography className={`label`}>HS code</Typography>
                            <div className={``}>
                                <div>
                                    <TextField
                                        type={"text"}
                                        id="hs_code"
                                        name="hs_code"
                                        className={`input-wrap barcode-input`}
                                        placeholder={`Enter HS Code`}
                                        margin="dense"
                                        spellCheck={false}
                                        {...register(`hs_code`)}
                                        error={errors.hs_code ? true : false}
                                        value={hsCodeValue}
                                        inputProps={{
                                            onInput: (e) => {
                                                if (hscodeChange) hscodeChange(e.target.value)
                                                setHsCodeValue(e.target.value)
                                            }
                                        }}
                                    />

                                </div>
                            </div>
                            <p className={`field-err-msg`}>{errors.hs_code?.message}</p>
                        </div>
                    </Box>
                    <Box className={`form-control margin-bottom-28`}>
                        <div>
                            <Typography className={`label`}>Product description</Typography>
                            <TextEditor
                                value={productDetails?.p_description}
                                onEditorChange={(value) => setProductDescription(value)}
                            />
                        </div>
                    </Box>
                </div>
                <div className={`fields-container`}>
                    <Box className={`form-control`}>
                        <Typography className={`label margin-btm-15 headline`}>Product Pricing</Typography>
                    </Box>
                    <Box className={`form-control margin-bottom-28 price-msrp-wrapper`}>
                        <div>
                            <Typography className={`label information-icon`}>
                                B2B Price Range
                                <span className="custom-tt-2" tooltip="The wholesale price range to your B2B buyers"><InformationIcon /> </span>
                            </Typography>
                            <div className={`price-wrap custom-black-select-option for-two-field`}>
                                <div>
                                    <select id="price-select" onChange={(event) => { if (priceCurrentChange) { priceCurrentChange(event.target.value) }; setSelectedPriceCurrency(event.target.value) }}>
                                        {
                                            currencyList && currencyList.length > 0 &&
                                            currencyList.map((list, index) => {
                                                return (
                                                    <option
                                                        value={list[`currency_id`]}
                                                        key={index}
                                                        selected={selectedPriceCurrency === list[`currency_id`] ? 'selected' : ''}
                                                    >
                                                        {list[`currency`]}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <TextField
                                        type={"number"}
                                        id="price"
                                        name="price"
                                        className={`input-wrap price-input`}
                                        placeholder={`Min`}
                                        margin="dense"
                                        spellCheck={false}
                                        {...register(`price`)}
                                        error={errors.price ? true : false}
                                        value={priceValue}
                                        inputProps={{
                                            onInput: (e) => {
                                                priceChange(e.target.value);
                                                setPriceValue(e.target.value)
                                            },
                                            min: 0,
                                            step: "any",
                                        }}
                                    />

                                </div>

                                <div>
                                    <TextField
                                        type={"number"}
                                        id="item"
                                        name="item"
                                        className={`input-wrap item-input`}
                                        placeholder={`Max`}
                                        margin="dense"
                                        spellCheck={false}
                                        {...register(`item`)}
                                        error={errors.item ? true : false}
                                        value={itemValue}
                                        inputProps={{
                                            onInput: (e) => {
                                                if (itemChange) itemChange(e.target.value);
                                                setItemValue(e.target.value)
                                            },
                                            min: 0,
                                            step: "any",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={`field-err-msg w-100`}>
                                <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.price?.message}</p>
                                <p className={`field-err-msg position-relative `}>{errors.item?.message}</p>
                            </div>
                        </div>
                        <div>
                            <Typography className={`label information-icon`}>
                                Retail Price
                                <span className="custom-tt-2" tooltip="Suggested maximum selling price to end-consumers"><InformationIcon /> </span>
                            </Typography>
                            <div className={`MSRP-wrap custom-black-select-option`}>
                                <div>
                                    <select id="MSRP-select" onChange={(event) => { if (msrpTypeChange) { msrpTypeChange(event.target.value) }; setSelectedMSRPType(event.target.value) }}>
                                        {
                                            currencyList && currencyList.length > 0 &&
                                            currencyList.map((list, index) => {
                                                return (
                                                    <option
                                                        value={list[`currency_id`]}
                                                        key={index}
                                                        selected={selectedMSRPType === list[`currency_id`] ? 'selected' : ''}
                                                    >
                                                        {list[`currency`]}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <TextField
                                        type={"text"}
                                        id="MSRP"
                                        name="MSRP"
                                        className={`input-wrap MSRP-input`}
                                        placeholder={`Enter Retail Price`}
                                        margin="dense"
                                        spellCheck={false}
                                        {...register(`MSRP`)}
                                        error={errors.MSRP ? true : false}
                                        value={msrpValue}
                                        inputProps={{
                                            onInput: (e) => {
                                                if (msrpChange) msrpChange(e.target.value);
                                                setMsrpValue(e.target.value)
                                            },
                                            min: 0,
                                            step: "any",
                                        }}
                                    />

                                </div>
                            </div>
                            <p className={`field-err-msg`}>{errors.MSRP?.message}</p>
                        </div>
                    </Box>
                    <Box className={`form-control margin-bottom-28 product-percase-wrapper`}>
                        <div>
                            <Typography className={`label information-icon`}>
                                Units per carton/case
                                <span className="custom-tt-2" tooltip="The number of products in a carton/case that will be shipped to wholesalers, distributors or retailers"><InformationIcon /> </span>
                            </Typography>
                            <div className={`productpercase-wrap custom-black-select-option`}>

                                <TextField
                                    type={"text"}
                                    id="p_p_case"
                                    name="p_p_case"
                                    className={`input-wrap ppc-input`}
                                    placeholder={`Units per carton/case`}
                                    margin="dense"
                                    spellCheck={false}
                                    {...register(`p_p_case`)}
                                    error={errors.p_p_case ? true : false}
                                    value={propercase}
                                    inputProps={{
                                        onInput: (e) => {
                                            setPropercase(e.target.value)
                                        },
                                        maxLength: 40
                                    }}
                                />
                                <p className={`field-err-msg`}>{errors.p_p_case?.message}</p>
                            </div>
                        </div>
                        <div>
                            <div className={`case-price`}>
                                <Typography className={`label information-icon`}>
                                    Carton/Case price
                                    <span className="custom-tt-2" tooltip="Automatically calculated by multiplying the B2B price by the number of products in a carton/case"><InformationIcon /> </span>
                                </Typography>
                                <div className={`case-price-value`}>{casePrice}</div>
                            </div>
                        </div>
                    </Box>
                    <Box className={`form-control margin-bottom-28`}>

                        <div className={`itemcode-wrap`}>
                            <Typography className={`label`}>Pricing Comments</Typography>
                            {/* <TextField
                                type={"text"}
                                id="priceComments"
                                name="priceComments"
                                className={`input-wrap itemcode-input`}
                                placeholder={`Enter Price Comments`}
                                multiline
                                minRows={3}
                                maxRows={7}
                                fullWidth
                                margin="dense"
                                spellCheck={false}
                                value={procomments}
                                inputProps={{
                                    onInput: (e) => {
                                        setProcomments(e.target.value)
                                    },
                                    maxLength: 40
                                }}
                            /> */}

                            <TextEditor
                                value={productDetails?.product_price?.[0]?.p_comments || ``}
                                onEditorChange={(value) => setProcomments(value)}
                            />
                        </div>
                    </Box>
                </div>
                <div className={`fields-container`}>
                    <Box className={`form-control margin-bottom-28 budget-wrapper for-yes-no`}>
                        <Typography className={`label margin-btm-15 headline`}>Product Packaging</Typography>
                        <div className={`form-control margin-bottom-28 input-radio`}>
                            <Typography className={`label margin-btm-15`}>Does your product have packaging?</Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }} >
                                <FormControl>
                                    <RadioGroup
                                        name="controlled-radio-buttons-group"
                                        value={productpackagingAvailabel}
                                        onChange={onProductPackagingAvailableChange}
                                        className={`product-availablity-wrapper`}
                                        row
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </FormControl>
                        </div>
                    </Box>
                    {
                        productpackagingAvailabel === `yes` &&
                        <Fragment>
                            <Box className={`form-control margin-bottom-28 product-dimension-wrapper`}>

                                <Typography className={`label margin-bottom-13 information-icon`}>
                                    Product dimensions
                                    <span className="custom-tt-2" tooltip="The dimension of your product as it would be displayed on store shelves"><InformationIcon /> </span>
                                </Typography>
                                <div className={`dimension-wrap custom-black-select-option for-three-field`}>
                                    <div>
                                        <select id="dimension-select" onChange={(event) => { if (dimensionsChange) { dimensionsChange(event.target.value) }; setSelectedDimension(event.target.value) }}>
                                            {
                                                dimensionList && dimensionList.length > 0 &&
                                                dimensionList.map((list, index) => {
                                                    return (
                                                        <option
                                                            value={list[`dimension_id`]}
                                                            key={index}
                                                            selected={selectedDimesnion === list[`dimension_id`] ? 'selected' : ''}
                                                        >
                                                            {list[`name`]}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="pro-dimension">
                                        <TextField
                                            type={"text"}
                                            id="width"
                                            name="width"
                                            className={`input-wrap width-input`}
                                            placeholder={`Width`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`width`)}
                                            error={errors.width ? true : false}
                                            value={productWidth}
                                            inputProps={{
                                                onInput: (e) => {
                                                    if (widthChange) widthChange(e.target.value);
                                                    setProductWidth(e.target.value)
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.width?.message}</p>
                                    </div>
                                    <div>
                                        <TextField
                                            type={"text"}
                                            id="height"
                                            name="height"
                                            className={`input-wrap height-input`}
                                            placeholder={`Height`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`height`)}
                                            error={errors.height ? true : false}
                                            value={productHeight}
                                            inputProps={{
                                                onInput: (e) => {
                                                    if (heightChange) heightChange(e.target.value);
                                                    setProductHeight(e.target.value)
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative `}>{errors.height?.message}</p>
                                    </div>

                                    <div>
                                        <TextField
                                            type={"text"}
                                            id="depth"
                                            name="depth"
                                            className={`input-wrap depth-input`}
                                            placeholder={`Depth`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`depth`)}
                                            error={errors.depth ? true : false}
                                            value={productDepth}
                                            inputProps={{
                                                onInput: (e) => {
                                                    if (depthChange) depthChange(e.target.value);
                                                    setProductDepth(e.target.value)
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.depth?.message}</p>
                                    </div>
                                </div>
                                <div className={`field-err-msg w-100`}>
                                    
                                    
                                    
                                </div>
                            </Box>
                            <Box className={`form-control margin-bottom-28`}>
                                <div className={`product-volume-weight-wrapper`}>
                                    <div className={`product-volume-wrap`}>
                                        <Typography className={`label margin-bottom-13 information-icon`}>
                                            Product volume
                                            <span className="custom-tt-2" tooltip="Calculated by multiplying the product width by depth by height"><InformationIcon /> </span>
                                        </Typography>
                                        <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                            <div>
                                                <select id="volumn-select" onChange={(event) => { if (selectedVolumeChange) { selectedVolumeChange(event.target.value) }; setSelectedVolume(event.target.value) }}>
                                                    {
                                                        volumeList && volumeList.length > 0 &&
                                                        volumeList.map((list, index) => {
                                                            return (
                                                                <option
                                                                    value={list[`volume_id`]}
                                                                    key={index}
                                                                    selected={selectedVolume === list[`volume_id`] ? 'selected' : ''}
                                                                >
                                                                    {list[`name`]}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div>
                                                <TextField
                                                    type={"text"}
                                                    id="volume"
                                                    name="volume"
                                                    className={`input-wrap volume-input`}
                                                    placeholder={`Volume`}
                                                    margin="dense"
                                                    spellCheck={false}
                                                    {...register(`volume`)}
                                                    error={errors.volume ? true : false}
                                                    value={productVolume}
                                                    inputProps={{
                                                        onInput: (e) => {
                                                            if (volumeChange) volumeChange(e.target.value);
                                                            setProductVolume(e.target.value)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className={`field-err-msg w-100`}>
                                            <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.volume?.message}</p>
                                        </div>
                                    </div>
                                    <div className={`product-weight-wrap`}>
                                        <Typography className={`label margin-bottom-13 information-icon`}>
                                            Product weight
                                            <span className="custom-tt-2" tooltip="This is the weight of the product in its packaging"><InformationIcon /> </span>
                                        </Typography>
                                        <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                            <div>
                                                <select id="weight-select" onChange={(event) => { if (selectedWeightChange) { selectedWeightChange(event.target.value) }; setSelectedWeight(event.target.value) }}>
                                                    {
                                                        weightList && weightList.length > 0 &&
                                                        weightList.map((list, index) => {
                                                            return (
                                                                <option
                                                                    value={list[`weight_id`]}
                                                                    key={index}
                                                                    selected={selectedWeight === list[`weight_id`] ? 'selected' : ''}
                                                                >
                                                                    {list[`name`]}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div>
                                                <TextField
                                                    type={"text"}
                                                    id="weight"
                                                    name="weight"
                                                    className={`input-wrap weight-input`}
                                                    placeholder={`Weight`}
                                                    margin="dense"
                                                    spellCheck={false}
                                                    {...register(`weight`)}
                                                    error={errors.weight ? true : false}
                                                    value={productWeight}
                                                    inputProps={{
                                                        onInput: (e) => {
                                                            if (weightChange) weightChange(e.target.value);
                                                            setProductWeight(e.target.value)
                                                        },
                                                        step: "any",
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`field-err-msg w-100`}>
                                    <p className={`field-err-msg position-relative `}>{errors.weight?.message}</p>
                                </div>
                            </Box>

                            <Box className={`form-control margin-bottom-28 budget-wrapper packaging-comments add-comments`}>
                                <div>
                                    <Typography className={`label`}>Additional comments</Typography>
                                    <TextField
                                        type={"text"}
                                        id="packaging_comments"
                                        name="packaging_comments"
                                        className={`input-wrap ppc-input`}
                                        placeholder={`Add any additional comments regarding Packaging`}
                                        tooltip={`Add any additional comments regarding Packaging`}
                                        margin="dense"
                                        spellCheck={false}
                                        {...register(`packaging_comments`)}
                                        error={errors.packaging_comments ? true : false}
                                        value={packagingComment}
                                        inputProps={{
                                            onInput: (e) => {
                                                setPackagingComment(e.target.value)
                                            }
                                        }}
                                    />
                                    <p className={`field-err-msg`}>{errors.packaging_comments?.message}</p>
                                </div>
                            </Box>
                        </Fragment>
                    }


                    <Box className={`form-control margin-bottom-28 budget-wrapper for-yes-no`}>
                        <div className={`form-control margin-bottom-28 input-radio`}>
                            <Typography className={`label margin-btm-15 information-icon tt-for-m`}>
                                Does your product have inner packaging?
                                <span className="custom-tt-2" tooltip="*The smaller packaging unit within a standard shipping unit"><InformationIcon /> </span>
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }} >
                                <FormControl>
                                    <RadioGroup
                                        name="controlled-radio-buttons-group"
                                        value={productinnerpackAvailable}
                                        onChange={onproductinnerpackAvailableChange}
                                        className={`product-availablity-wrapper`}
                                        row
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </FormControl>
                        </div>
                    </Box>
                    {
                        productinnerpackAvailable === `yes` &&
                        <Fragment>
                            <Box className={`form-control margin-bottom-28 product-percase-wrapper`}>
                                <div className={`itemcode-wrap`}>
                                    <Typography className={`label`}>Inner pack barcode</Typography>
                                    <TextField
                                        type={"text"}
                                        id="innerbarcode"
                                        name="innerbarcode"
                                        className={`input-wrap inner-barcode-input`}
                                        placeholder={`Enter Inner Barcode`}
                                        margin="dense"
                                        spellCheck={false}
                                        value={productInnerGtin}
                                        inputProps={{
                                            onInput: (e) => {
                                                setProductInnerGtin(e.target.value);
                                            },
                                            maxLength: 40
                                        }}
                                    />
                                </div>
                                <div className={`itemcode-wrap`}>
                                    <Typography className={`label`}>Units per inner</Typography>
                                    <TextField
                                        type={"text"}
                                        id="units_per_inner"
                                        name="units_per_inner"
                                        className={`input-wrap ppc-input`}
                                        placeholder={`Units per inner`}
                                        margin="dense"
                                        spellCheck={false}
                                        {...register(`units_per_inner`)}
                                        error={errors.units_per_inner ? true : false}
                                        value={unitesperInner}
                                        inputProps={{
                                            onInput: (e) => {
                                                setUnitesperInner(e.target.value)
                                            }
                                        }}
                                    />
                                    <p className={`field-err-msg`}>{errors.units_per_inner?.message}</p>
                                </div>

                            </Box>

                            <Box className={`form-control margin-bottom-28 product-dimension-wrapper`}>

                                <Typography className={`label margin-bottom-13 information-icon`}>
                                    Inner pack dimensions
                                    <span className="custom-tt-2" tooltip="The dimensions of the smaller packaging unit within a standard shipping unit"><InformationIcon /> </span>
                                </Typography>
                                <div className={`dimension-wrap custom-black-select-option for-three-field`}>
                                    <div>
                                        <select id="dimension-select" onChange={(event) => { setSelectedInnerDimension(event.target.value) }}>
                                            {
                                                dimensionList && dimensionList.length > 0 &&
                                                dimensionList.map((list, index) => {
                                                    return (
                                                        <option
                                                            value={list[`dimension_id`]}
                                                            key={index}
                                                            selected={selectedInnerDimesnion === list[`dimension_id`] ? 'selected' : ''}
                                                        >
                                                            {list[`name`]}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="pro-dimension">
                                        <TextField
                                            type={"text"}
                                            id="innerwidth"
                                            name="innerwidth"
                                            className={`input-wrap width-input`}
                                            placeholder={`Width`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`innerwidth`)}
                                            error={errors.innerwidth ? true : false}
                                            value={productInnerWidth}
                                            inputProps={{
                                                onInput: (e) => {
                                                    setProductInnerWidth(e.target.value)
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.innerwidth?.message}</p>
                                    </div>
                                    <div className="">
                                        <TextField
                                            type={"text"}
                                            id="innerheight"
                                            name="innerheight"
                                            className={`input-wrap height-input`}
                                            placeholder={`Height`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`innerheight`)}
                                            error={errors.innerheight ? true : false}
                                            value={productInnerHeight}
                                            inputProps={{
                                                onInput: (e) => {
                                                    setProductInnerHeight(e.target.value)
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative `}>{errors.innerheight?.message}</p>
                                        
                                    </div>
                                    <div className="pro-dimension">
                                        <TextField
                                            type={"text"}
                                            id="innerdepth"
                                            name="innerdepth"
                                            className={`input-wrap depth-input`}
                                            placeholder={`Depth`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`innerdepth`)}
                                            error={errors.innerdepth ? true : false}
                                            value={productInnerDepth}
                                            inputProps={{
                                                onInput: (e) => {
                                                    setProductInnerDepth(e.target.value)
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.innerdepth?.message}</p>
                                    </div>
                                </div>
                            </Box>
                            <Box className={`form-control margin-bottom-28`}>
                                <div className={`product-volume-weight-wrapper`}>
                                    <div className={`product-volume-wrap`}>
                                        <Typography className={`label margin-bottom-13 information-icon`}>
                                            Inner pack volume
                                            <span className="custom-tt-2" tooltip="Calculated by multiplying the product width by depth by height"><InformationIcon /> </span>
                                        </Typography>
                                        <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                            <div>
                                                <select id="volumn-select" onChange={(event) => { setSelectedInnerVolume(event.target.value) }}>
                                                    {
                                                        volumeList && volumeList.length > 0 &&
                                                        volumeList.map((list, index) => {
                                                            return (
                                                                <option
                                                                    value={list[`volume_id`]}
                                                                    key={index}
                                                                    selected={selectedInnerVolume === list[`volume_id`] ? 'selected' : ''}
                                                                >
                                                                    {list[`name`]}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div>
                                                <TextField
                                                    type={"text"}
                                                    id="innervolume"
                                                    name="innervolume"
                                                    className={`input-wrap volume-input`}
                                                    placeholder={`Inner Volume`}
                                                    margin="dense"
                                                    spellCheck={false}
                                                    {...register(`innervolume`)}
                                                    error={errors.innervolume ? true : false}
                                                    value={productInnerVolume}
                                                    inputProps={{
                                                        onInput: (e) => {
                                                            setProductInnerVolume(e.target.value)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className={`field-err-msg w-100`}>
                                            <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.innervolume?.message}</p>
                                        </div>
                                    </div>
                                    <div className={`product-weight-wrap`}>
                                        <Typography className={`label margin-bottom-13`}>Inner pack weight</Typography>
                                        <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                            <div>
                                                <select id="weight-select" onChange={(event) => { setSelectedInnerWeight(event.target.value) }}>
                                                    {
                                                        weightList && weightList.length > 0 &&
                                                        weightList.map((list, index) => {
                                                            return (
                                                                <option
                                                                    value={list[`weight_id`]}
                                                                    key={index}
                                                                    selected={selectedInnerWeight === list[`weight_id`] ? 'selected' : ''}
                                                                >
                                                                    {list[`name`]}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div>
                                                <TextField
                                                    type={"text"}
                                                    id="innerweight"
                                                    name="innerweight"
                                                    className={`input-wrap weight-input`}
                                                    placeholder={`Inner Weight`}
                                                    margin="dense"
                                                    spellCheck={false}
                                                    {...register(`innerweight`)}
                                                    error={errors.innerweight ? true : false}
                                                    value={productInnerWeight}
                                                    inputProps={{
                                                        onInput: (e) => {
                                                            setProductInnerWeight(e.target.value)
                                                        },
                                                        step: "any",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`field-err-msg w-100`}>
                                    <p className={`field-err-msg position-relative `}>{errors.innerweight?.message}</p>
                                </div>
                            </Box>


                            <Box className={`form-control margin-bottom-28 add-comments`}>
                                <div className={`itemcode-wrap`}>
                                    <Typography className={`label`}>Additional comments</Typography>
                                    <TextField
                                        type={"text"}
                                        id="inner_packaging"
                                        name="inner_packaging"
                                        className={`input-wrap gtn14-input`}
                                        placeholder={`Add any additional comments regarding Inner packaging`}
                                        tooltip={`Add any additional comments regarding Inner packaging`}
                                        margin="dense"
                                        {...register(`inner_packaging`)}
                                        error={errors.inner_packaging ? true : false}
                                        spellCheck={false}
                                        value={innerPackagingComments}
                                        inputProps={{
                                            onInput: (e) => {
                                                setInnerPackgaingComments(e.target.value);
                                            }
                                        }}
                                    />
                                </div>
                                <div className={`field-err-msg w-100`}>
                                    <p className={`field-err-msg position-relative `}>{errors.inner_packaging?.message}</p>
                                </div>
                            </Box>
                        </Fragment>
                    }
                    <Box className={`form-control margin-bottom-28 budget-wrapper for-yes-no`}>
                        <div className={`form-control margin-bottom-28 input-radio`}>
                            <Typography className={`label margin-btm-15`}>Does your product have Carton/Case packaging?</Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }} >
                                <FormControl>
                                    <RadioGroup
                                        name="controlled-radio-buttons-group"
                                        value={productcasepackAvailable}
                                        onChange={onproductcasepackAvailableChange}
                                        className={`product-availablity-wrapper`}
                                        row
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </FormControl>
                        </div>
                    </Box>
                    {
                        productcasepackAvailable === `yes` &&
                        <Fragment>
                            <Box className={`form-control margin-bottom-28 product-percase-wrapper`}>
                                <div className={`itemcode-wrap`}>
                                    <Typography className={`label`}>Carton/Case barcode</Typography>
                                    <TextField
                                        type={"text"}
                                        id="gtn14"
                                        name="gtn14"
                                        className={`input-wrap gtn14-input`}
                                        placeholder={`Enter Carton/Case barcode`}
                                        margin="dense"
                                        {...register(`gtn14`)}
                                        spellCheck={false}
                                        value={productCaseGtin}
                                        inputProps={{
                                            onInput: (e) => {
                                                setProductCaseGtin(e.target.value);
                                            },
                                            maxLength: 40
                                        }}
                                    />
                                    <div className={`field-err-msg w-100`}>
                                    <p className={`field-err-msg position-relative `}>{errors.gtn14?.message}</p>
                                </div>
                                </div>
                                
                                <div>
                                <Typography className={`label information-icon`}>
                                    Units per carton/case
                                    <span className="custom-tt-2" tooltip="The number of products in a carton/case that will be shipped to wholesalers, distributors or retailers"><InformationIcon /> </span>
                                </Typography>
                                <div className={`productpercase-wrap custom-black-select-option`}>

                                    <TextField
                                        type={"text"}
                                        id="p_p_case1"
                                        name="p_p_case1"
                                        className={`input-wrap ppc-input`}
                                        placeholder={`Units per carton/case`}
                                        margin="dense"
                                        spellCheck={false}
                                        {...register(`p_p_case1`)}
                                        error={errors.p_p_case1 ? true : false}
                                        value={propercase}
                                        inputProps={{
                                            onInput: (e) => {
                                                setPropercase(e.target.value)
                                            },
                                            maxLength: 40
                                        }}
                                    />
                                    <p className={`field-err-msg`}>{errors.p_p_case1?.message}</p>
                                </div>
                                </div>
                            </Box>

                            <Box className={`form-control margin-bottom-28 product-dimension-wrapper`}>

                                <Typography className={`label margin-bottom-13 information-icon`}>
                                    Carton/Case dimensions
                                    <span className="custom-tt-2" tooltip="The dimensions of a standard shipping unit"><InformationIcon /> </span>
                                </Typography>
                                <div className={`dimension-wrap custom-black-select-option for-three-field`}>
                                    <div>
                                        <select id="dimension-select" onChange={(event) => { setSelectedCaseDimension(event.target.value) }}>
                                            {
                                                dimensionList && dimensionList.length > 0 &&
                                                dimensionList.map((list, index) => {
                                                    return (
                                                        <option
                                                            value={list[`dimension_id`]}
                                                            key={index}
                                                            selected={selectedCaseDimesnion === list[`dimension_id`] ? 'selected' : ''}
                                                        >
                                                            {list[`name`]}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="pro-dimension">
                                        <TextField
                                            type={"text"}
                                            id="casewidth"
                                            name="casewidth"
                                            className={`input-wrap width-input`}
                                            placeholder={`Width`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`casewidth`)}
                                            error={errors.casewidth ? true : false}
                                            value={productCaseWidth}
                                            inputProps={{
                                                onInput: (e) => {
                                                    setProductCaseWidth(e.target.value)
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.casewidth?.message}</p>
                                    </div>
                                    <div>
                                        <TextField
                                            type={"text"}
                                            id="caseheight"
                                            name="caseheight"
                                            className={`input-wrap height-input`}
                                            placeholder={`Height`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`caseheight`)}
                                            error={errors.caseheight ? true : false}
                                            value={productCaseHeight}
                                            inputProps={{
                                                onInput: (e) => {
                                                    setProductCaseHeight(e.target.value)
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative `}>{errors.caseheight?.message}</p>
                                    </div>
                                    <div>
                                        <TextField
                                            type={"text"}
                                            id="casedepth"
                                            name="casedepth"
                                            className={`input-wrap depth-input`}
                                            placeholder={`Depth`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`casedepth`)}
                                            error={errors.depth ? true : false}
                                            value={productCaseDepth}
                                            inputProps={{
                                                onInput: (e) => {
                                                    setProductCaseDepth(e.target.value)
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.casedepth?.message}</p>
                                    </div>
                                </div>
                            </Box>
                            <Box className={`form-control margin-bottom-28`}>
                                <div className={`product-volume-weight-wrapper`}>
                                    <div className={`product-volume-wrap`}>
                                        <Typography className={`label margin-bottom-13 information-icon`}>
                                            Carton/Case volume
                                            <span className="custom-tt-2" tooltip="Calculated by multiplying the carton/case width by depth by height"><InformationIcon /> </span>
                                        </Typography>
                                        <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                            <div>
                                                <select id="volumn-select" onChange={(event) => { setSelectedCaseVolume(event.target.value) }}>
                                                    {
                                                        volumeList && volumeList.length > 0 &&
                                                        volumeList.map((list, index) => {
                                                            return (
                                                                <option
                                                                    value={list[`volume_id`]}
                                                                    key={index}
                                                                    selected={selectedCaseVolume === list[`volume_id`] ? 'selected' : ''}
                                                                >
                                                                    {list[`name`]}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div>
                                                <TextField
                                                    type={"text"}
                                                    id="casevolume"
                                                    name="casevolume"
                                                    className={`input-wrap volume-input`}
                                                    placeholder={`Case Volume`}
                                                    margin="dense"
                                                    spellCheck={false}
                                                    {...register(`casevolume`)}
                                                    error={errors.casevolume ? true : false}
                                                    value={productCaseVolume}
                                                    inputProps={{
                                                        onInput: (e) => {
                                                            setProductCaseVolume(e.target.value)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className={`field-err-msg w-100`}>
                                            <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.casevolume?.message}</p>
                                        </div>
                                    </div>
                                    <div className={`product-weight-wrap`}>
                                        <Typography className={`label margin-bottom-13`}>Carton/Case weight</Typography>
                                        <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                            <div>
                                                <select id="weight-select" onChange={(event) => { setSelectedCaseWeight(event.target.value) }}>
                                                    {
                                                        weightList && weightList.length > 0 &&
                                                        weightList.map((list, index) => {
                                                            return (
                                                                <option
                                                                    value={list[`weight_id`]}
                                                                    key={index}
                                                                    selected={selectedCaseWeight === list[`weight_id`] ? 'selected' : ''}
                                                                >
                                                                    {list[`name`]}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div>
                                                <TextField
                                                    type={"text"}
                                                    id="caseweight"
                                                    name="caseweight"
                                                    className={`input-wrap weight-input`}
                                                    placeholder={`Case Weight`}
                                                    margin="dense"
                                                    spellCheck={false}
                                                    {...register(`caseweight`)}
                                                    error={errors.caseweight ? true : false}
                                                    value={productCaseWeight}
                                                    inputProps={{
                                                        onInput: (e) => {
                                                            setProductCaseWeight(e.target.value)
                                                        },
                                                        step: "any",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`field-err-msg w-100`}>
                                    <p className={`field-err-msg position-relative `}>{errors.caseweight?.message}</p>
                                </div>
                            </Box>

                            <Box className={`form-control margin-bottom-28 product-percase-wrapper`}>
                                <div>
                                    <Typography className={`label information-icon`}>
                                        Pallet TI
                                        <span className="custom-tt-2" tooltip="The number of cartons/cases that fit in one layer of a pallet"><InformationIcon /> </span>
                                    </Typography>
                                    <div className={`productpercase-wrap custom-black-select-option`}>

                                        <TextField
                                            type={"text"}
                                            id="pallett1"
                                            name="pallett1"
                                            className={`input-wrap ppc-input`}
                                            placeholder={`Pallet TI`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`pallett1`)}
                                            error={errors.pallett1 ? true : false}
                                            value={productCasePalletet1}
                                            inputProps={{
                                                onInput: (e) => {
                                                    setProductCasePalletet1(e.target.value)
                                                },
                                                maxLength: 40
                                            }}
                                        />

                                        <p className={`field-err-msg`}>{errors.pallett1?.message}</p>
                                    </div>

                                </div>
                                <div>
                                    <Typography className={`label information-icon`}>
                                        Pallet HI
                                        <span className="custom-tt-2" tooltip="The number of layers you can stack on a pallet"><InformationIcon /> </span>
                                    </Typography>
                                    <div className={`productpercase-wrap custom-black-select-option`}>
                                        <TextField
                                            type={"text"}
                                            id="palletth1"
                                            name="palletth1"
                                            className={`input-wrap ppc-input`}
                                            placeholder={`Pallet HI`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`palletth1`)}
                                            error={errors.palletth1 ? true : false}
                                            value={productCasePalleteh1}
                                            inputProps={{
                                                onInput: (e) => {
                                                    setProductCasePalleteh1(e.target.value)
                                                },
                                                maxLength: 40
                                            }}
                                        />
                                        <p className={`field-err-msg`}>{errors.palletth1?.message}</p>
                                    </div>

                                </div>
                            </Box>
                            <Box className={`form-control margin-bottom-28 product-percase-wrapper`}>
                                <div>
                                    <Typography className={`label information-icon`}>
                                        Pallet Quantity
                                        <span className="custom-tt-2" tooltip="The total number of cartons/cases that can fit on a pallet"><InformationIcon /> </span>
                                    </Typography>
                                    <div className={`productpercase-wrap custom-black-select-option`}>
                                        <TextField
                                            type={"text"}
                                            id="palletquantity"
                                            name="palletquantity"
                                            className={`input-wrap ppc-input`}
                                            placeholder={`Pallet Quantity`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`palletquantity`)}
                                            error={errors.palletquantity ? true : false}
                                            value={productCasePalletequan}
                                            inputProps={{
                                                onInput: (e) => {
                                                    setProductCasePalletequan(e.target.value)
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg`}>{errors.palletquantity?.message}</p>
                                    </div>
                                </div>

                                {
                                    userSettings?.pro_container_20 &&
                                    <div>
                                        <Typography className={`label information-icon`}>
                                            Container Loadability 20 ft (Palletized)
                                        </Typography>
                                        <div className={`productpercase-wrap custom-black-select-option`}>

                                            <TextField
                                                type={"text"}
                                                id="container_loadability_20_ft"
                                                name="container_loadability_20_ft"
                                                className={`input-wrap ppc-input`}
                                                placeholder={`Enter number of cartons`}
                                                margin="dense"
                                                spellCheck={false}
                                                {...register(`container_loadability_20_ft`)}
                                                error={errors.container_loadability_20_ft ? true : false}
                                                value={container_loadability_20_ft}
                                                inputProps={{
                                                    onInput: (e) => {
                                                        if (containerLoadability20FtChange) containerLoadability20FtChange(e.target.value);
                                                        setcontainer_loadability_20_ft(e.target.value)
                                                    },
                                                    maxLength: 40
                                                }}
                                            />
                                            <p className={`field-err-msg`}>{errors.container_loadability_20_ft?.message}</p>
                                        </div>
                                    </div>
                                }


                            </Box>


                            <Box className={`form-control margin-bottom-28 product-percase-wrapper`}>
                                {
                                    userSettings?.pro_container_20_loose &&
                                    <Box>
                                        <div>
                                            <Typography className={`label information-icon`}>
                                                Container Loadability 20 ft (Loose)
                                            </Typography>
                                            <div className={`productpercase-wrap custom-black-select-option`}>
                                                <TextField
                                                    type={"text"}
                                                    id="container_loadability_20_ft_loose"
                                                    name="container_loadability_20_ft_loose"
                                                    className={`input-wrap ppc-input`}
                                                    placeholder={` Container Loadability 20 ft (Loose)`}
                                                    margin="dense"
                                                    spellCheck={false}
                                                    {...register(`container_loadability_20_ft_loose`)}
                                                    error={errors.container_loadability_20_ft_loose ? true : false}
                                                    value={container_loadability_20_ft_loose}
                                                    inputProps={{
                                                        onInput: (e) => {
                                                            if (containerLoadability20FtLooseChange) containerLoadability20FtLooseChange(e.target.value);
                                                            setcontainer_loadability_20_ft_loose(e.target.value)
                                                        },
                                                        maxLength: 40
                                                    }}
                                                />
                                                <p className={`field-err-msg`}>{errors.container_loadability_20_ft_loose?.message}</p>
                                            </div>
                                        </div>
                                    </Box>
                                }
                                {
                                    userSettings?.pro_container_40 &&
                                    <Box>
                                        <div>
                                            <Typography className={`label information-icon`}>
                                            Container Loadability 40 ft (Palletized)
                                            </Typography>
                                            <div className={`productpercase-wrap custom-black-select-option`}>
                                                <TextField
                                                    type={"text"}
                                                    id="container_loadability_40_ft"
                                                    name="container_loadability_40_ft"
                                                    className={`input-wrap ppc-input`}
                                                    placeholder={`Enter number of cartons`}
                                                    margin="dense"
                                                    spellCheck={false}
                                                    {...register(`container_loadability_40_ft`)}
                                                    error={errors.container_loadability_40_ft ? true : false}
                                                    value={container_loadability_40_ft}
                                                    inputProps={{
                                                        onInput: (e) => {
                                                            if (containerLoadability40FtChange) containerLoadability40FtChange(e.target.value);
                                                            setcontainer_loadability_40_ft(e.target.value)
                                                        },
                                                        maxLength: 40
                                                    }}
                                                />
                                                <p className={`field-err-msg`}>{errors.container_loadability_40_ft?.message}</p>
                                            </div>
                                        </div>
                                    </Box>
                                }
                            </Box>
                            <Box className={`form-control margin-bottom-28 product-percase-wrapper`}>
                               
                                {
                                    userSettings?.pro_container_40_loose &&
                                    <Box>
                                        <div>
                                            <Typography className={`label information-icon`}>
                                            Container Loadability 40 ft (Loose)
                                            </Typography>
                                            <div className={`productpercase-wrap custom-black-select-option`}>
                                                <TextField
                                                    type={"text"}
                                                    id="container_loadability_40_ft_loose"
                                                    name="container_loadability_40_ft_loose"
                                                    className={`input-wrap ppc-input`}
                                                    placeholder={`Enter number of cartons`}
                                                    margin="dense"
                                                    spellCheck={false}
                                                    {...register(`container_loadability_40_ft_loose`)}
                                                    error={errors.container_loadability_40_ft_loose ? true : false}
                                                    value={container_loadability_40_ft_loose}
                                                    inputProps={{
                                                        onInput: (e) => {
                                                            if (containerLoadability40FtlooseChange) containerLoadability40FtlooseChange(e.target.value);
                                                            setcontainer_loadability_40_ftloose(e.target.value)
                                                        },
                                                        maxLength: 40
                                                    }}
                                                />
                                                <p className={`field-err-msg`}>{errors.container_loadability_40_ft_loose?.message}</p>
                                            </div>
                                        </div>
                                    </Box>
                                }
                            </Box>
                            <Box className={`form-control margin-bottom-28 product-percase-wrapper`}>
                               
                                {
                                    userSettings?.pro_full_truck_load &&
                                    <Box>
                                        <div>
                                            <Typography className={`label information-icon`}>
                                            Full Truck load (Cases)
                                            </Typography>
                                            <div className={`productpercase-wrap custom-black-select-option`}>
                                                <TextField
                                                    type={"text"}
                                                    id="full_truck_load"
                                                    name="full_truck_load"
                                                    className={`input-wrap ppc-input`}
                                                    placeholder={`Enter number of cartons`}
                                                    margin="dense"
                                                    spellCheck={false}
                                                    {...register(`full_truck_load`)}
                                                    error={errors.full_truck_load ? true : false}
                                                    value={full_truck_load}
                                                    inputProps={{
                                                        onInput: (e) => {
                                                            if (fullTruckLoadChange) fullTruckLoadChange(e.target.value);
                                                            setfull_truck_load(e.target.value)
                                                        },
                                                        maxLength: 40
                                                    }}
                                                />
                                                <p className={`field-err-msg`}>{errors.full_truck_load?.message}</p>
                                            </div>
                                        </div>
                                    </Box>
                                }
                            </Box>


                            <Box className={`form-control margin-bottom-28 add-comments`}>
                                <div className="position-relative">
                                    <Typography className={`label information-icon`}>
                                        Additional comments
                                    </Typography>
                                    <div className={`productpercase-wrap custom-black-select-option`}>
                                        <TextField
                                            type={"text"}
                                            id="carton_case_packaging"
                                            name="carton_case_packaging"
                                            className={`input-wrap ppc-input custom-tt-2`}
                                            placeholder={`Add any additional comments regarding Carton/Case packaging`}
                                            tooltip={`Add any additional comments regarding Carton/Case packaging`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`carton_case_packaging`)}
                                            error={errors.carton_case_packaging ? true : false}
                                            value={cartonPackagingComments}
                                            inputProps={{
                                                onInput: (e) => {
                                                    setCartongPackaginComments(e.target.value)
                                                }
                                            }}
                                        />
                                    </div>
                                    <p className={`field-err-msg`}>{errors.carton_case_packaging?.message}</p>
                                </div>
                            </Box>
                        </Fragment>
                    }
                </div>

                <div className={`fields-container mb-0`}>
                    <Box className={`form-control margin-bottom-28 budget-wrapper for-yes-no`}>
                        <Typography className={`label margin-btm-15 headline`}>Product Shelf Life And Ingredients</Typography>
                        <div className={`form-control margin-bottom-28 input-radio`}>
                            <Typography className={`label margin-btm-15`}>Does your product have a shelf life?</Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }} >
                                <FormControl>
                                    <RadioGroup
                                        name="controlled-radio-buttons-group"
                                        value={productShelflifeavailable}
                                        onChange={onProductShelflifeChange}
                                        className={`product-availablity-wrapper`}
                                        row
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </FormControl>
                        </div>
                    </Box>
                    {
                        productShelflifeavailable === `yes` &&
                        <Fragment>
                            <Box className={`form-control margin-bottom-28`}>
                                <div className={`product-volume-weight-wrapper`}>
                                    <div className={`product-volume-wrap`}>
                                        <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                            <div>
                                                <select id="shelflife-id" onChange={(event) => { setSelectedShelflifeType(event.target.value) }}>
                                                    {
                                                        shelfLifeList && shelfLifeList.length > 0 &&
                                                        shelfLifeList.map((list, index) => {
                                                            return (
                                                                <option
                                                                    value={list[`shelflife_id`]}
                                                                    key={index}
                                                                    selected={selectedShelflifeType === list[`shelflife_id`] ? 'selected' : ''}
                                                                >
                                                                    {list[`name`]}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div>
                                                <TextField
                                                    type={"text"}
                                                    id="shelf_life_value"
                                                    name="shelf_life_value"
                                                    className={`input-wrap volume-input`}
                                                    placeholder={`E.g: "24" or "18-24"`}
                                                    margin="dense"
                                                    spellCheck={false}
                                                    {...register(`shelf_life_value`)}
                                                    error={errors.shelf_life_value ? true : false}
                                                    value={productShelflifevalue}
                                                    inputProps={{
                                                        onInput: (e) => {
                                                            setProductShelflifevalue(e.target.value)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className={`field-err-msg w-100`}>
                                            <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.shelf_life_value?.message}</p>
                                        </div>
                                    </div>

                                </div>

                                <div className={`field-err-msg w-100`}>
                                    <p className={`field-err-msg position-relative `}>{errors.innerweight?.message}</p>
                                </div>
                            </Box>
                        </Fragment>
                    }
                    <Box className={`form-control margin-bottom-28 budget-wrapper for-yes-no`}>
                        <div className={`form-control margin-bottom-28 input-radio`}>
                            <Typography className={`label margin-btm-15`}>Does your product have ingredients?</Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }} >
                                <FormControl>
                                    <RadioGroup
                                        name="controlled-radio-buttons-group"
                                        value={productIngredientsavailable}
                                        onChange={onProductingredientsAvailableChange}
                                        className={`product-availablity-wrapper`}
                                        row
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </FormControl>
                        </div>
                    </Box>
                    {
                        productIngredientsavailable === `yes` &&
                        <Fragment>
                            <Box className={`form-control margin-bottom-28`}>
                                <TextField
                                    type={"text"}
                                    id="ingredients"
                                    name="ingredients"
                                    className={`input-wrap`}
                                    placeholder={`Enter Ingredients Name`}
                                    margin="dense"
                                    spellCheck={false}
                                    // disabled={ingredientsValues.length === 50 ? true : false}
                                    value={ingredientsValues && ingredientsValues.length > 0 ? ingredientsValues[0][`name`] : ingredientsValues}
                                    // error={isIngredientError}
                                    inputProps={{
                                        // onKeyPress: (e) => {
                                        //     if (e.target.value.trim()) onIngredientClick(e);
                                        // },
                                        onChange: (e) => {
                                            setIngredientsValues(e.target.value);
                                            // if (e.target.value) setIngredientErrorCheckValue(true);
                                            // setIngredientErrorValue(ingredientsValues.length > 0 ? false : true);
                                        },

                                        onBlur: (e) => {
                                            setIngredientsValues(e.target.value);
                                        },
                                        maxLength: 1000
                                    }}
                                />

                                <div className={`count-info`}>
                                    Max character limit of 1000
                                </div>
                                {/* <div className={`information-lables`}>
                                    <div className={`count-info`}>
                                        {
                                            ingredientsValues.length === 50
                                                ?
                                                `Ingredient added`
                                                :
                                                `Add up to ${50 - ingredientsValues.length}`}
                                    </div>
                                </div> */}

                                {/* <div className={`chips-list`}>
                                    {
                                        ingredientsValues && ingredientsValues.length > 0 &&
                                        ingredientsValues.map((ing, index) => {
                                            return (
                                                <div key={index} className={`chips`}>
                                                    <div>
                                                        {ing[`name`]}
                                                    </div>
                                                    <div className={`remove`} onClick={() => { removeChip(index, ingredientsValues, setIngredientsValues, `ings`) }}>
                                                        <CloseIcon />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div> */}
                                {/* {
                                isIngredientError &&
                                <p className={`field-err-msg`}>{`Ingredient is required`}</p>
                            } */}

                            </Box>
                        </Fragment>
                    }
                    <Box className={`form-control margin-bottom-28 budget-wrapper for-yes-no`}>
                        <div className={`form-control margin-bottom-28 input-radio`}>
                            <Typography className={`label margin-btm-15`}>Does your product have a nutritional label?</Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }} >
                                <FormControl>
                                    <RadioGroup
                                        name="controlled-radio-buttons-group"
                                        value={productNutritionalavailable}
                                        onChange={onProductnutritionalAvailableChange}
                                        className={`product-availablity-wrapper`}
                                        row
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </FormControl>
                        </div>
                    </Box>
                    {
                        productNutritionalavailable === `yes` &&
                        <Fragment>
                            <Box className={`form-control nutritional-label-section-wrap`}>
                                {

                                    NutritionImage === ``
                                        ?
                                        <div className={`logo-wrap`} onClick={() => { if (document.getElementById("nutritionimage")) document.getElementById("nutritionimage").click() }}>
                                            <div className={`logo flex-column`}>
                                                +Add Nutritional Label
                                                {/* <p className={`modal-img-info`}>Image must be at least 160 x 160 px.</p> */}
                                            </div>
                                            <div className={`edit-icon`}>
                                                <EditImageIcon />
                                            </div>
                                        </div>
                                        :
                                        <Fragment>
                                            <div className={`remove`} onClick={() => { removeImage() }}><CloseIcon /></div>
                                            <div className="preview-img">
                                                <img className={`w-100`} src={NutritionImage} alt="Nutritional label" loading="lazy" />
                                                <div className={`edit-icon`} onClick={() => { if (document.getElementById("nutritionimage")) document.getElementById("nutritionimage").click() }}>
                                                    <EditImageIcon />
                                                </div>
                                            </div>
                                        </Fragment>
                                }
                                <input className={`visibility-hidden width-0 position-absolute`} type='file' name='nutritionimage' id='nutritionimage' onChange={(event) => { onNutritionImageUpload(event.target.files[0]) }} accept="image/x-png,image/gif,image/jpeg" />
                            </Box>
                        </Fragment>
                    }
                </div>
                <div className={`button-wrapper`}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        id="product-detailpage-submit-btn"
                        className={`w-100 btn-primary-after-login visibility-hidden display-none`}
                    >
                        Sign up
                    </Button>
                </div>
            </form>
        </Fragment>

    )
}

export default ProductDetailPage;

