// React related imports
import React, { useState, useEffect, Fragment } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import brandAction from "../../../../store/action/brandAction";
import businsessTypeAction from "../../../../store/action/businsessTypeAction";
import yearOfEstablishAction from "../../../../store/action/yearOfEstablishAction";
import industryListAction from "../../../../store/action/industryListAction";
import interestsListAction from "../../../../store/action/interestsListAction";
import onBoardingAction from "../../../../store/action/onBoardingAction";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//Apis related imports
import getNoOfBrand from "../../../../apis/getNoOfBrand";
import getBusinessType from "../../../../apis/getBusinessType";
import getYearOfEstablish from "../../../../apis/getYearOfEstablish";
import buyerOnboardingFirstStep from "../../../../apis/Buyer/buyerOnboardingFirstStep";
import getIndustryList from "../../../../apis/getIndustryList";
import buyerOnboardingSecondStep from "../../../../apis/Buyer/buyerOnboardingSecondStep";
import getInterestsList from "../../../../apis/getInterestsList";
import onBoardingThirdStep from "../../../../apis/onBoardingThirdStep";
import getBuyerOnBoardingInfo from "../../../../apis/Buyer/getBuyerOnBoardingInfo";
import buyerOnboardingThirdStep from "../../../../apis/Buyer/buyerOnboardingThirdStep";

//Components related imports
import Loader from "../../Loader";
import AlertMessage from "../../AlertMessage";

//Icons related imports
import Greentick from "../../../../icons/svgs/greentick";
import Gridicon from "../../../../icons/svgs/grid";
import { Icons } from "../../../../common/Icons"
import EditImageIcon from "../../../../icons/svgs/editImageIcon";

// React route related imports
import { useNavigate } from "react-router-dom";
import { Icon } from "@mui/material";


const CompanyProfileTab = (props) => {

    const user = props?.usersData?.user || {};
    const theme = useTheme();

    const [profileOnBoarding, setProfileOnBoarding] = useState(props?.userOnBoardingData?.onBoarding || {});

    const [brands, setBrands] = useState(props?.brandData?.brand || []),
        [businessType, setBusinessType] = useState(props?.businessTypeData?.businessType || []),
        [yearOfEstablish, setYearOfEstablish] = useState(props?.yearOfEstablishData?.yearOfEstablish || []),
        [isLoading, setIsLoading] = useState(false),
        [selectedBusinessType, setSelectedBusinessType] = useState(profileOnBoarding?.businesstype ? profileOnBoarding?.businesstype : businessType && businessType.length > 0 ? businessType[0][`businesstype_id`] : ``),
        [selectedYearOfEstablish, setSelectedYearOfEstablish] = useState(profileOnBoarding?.yearofestablish ? profileOnBoarding?.yearofestablish : yearOfEstablish && yearOfEstablish.length > 0 ? yearOfEstablish[0][`yoe_id`] : ``),
        [selectedBrand, setSelectedBrand] = useState(profileOnBoarding?.noe ? profileOnBoarding?.noe : brands && brands.length > 0 ? brands[0][`number_brand_id`] : ``),
        [compnanyWebsite, setCompnanyWebsite] = useState(profileOnBoarding?.website_url || ``),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [businessTypeValue, setBusinessTypeValue] = useState([]),
        [selectedBusinessTypeIds, setSelectedBusinessTypeIds] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sliceLength = props?.appData?.app?.isMobile ? 30 : 50;
    const moduleApis = [];
    const moduleApiIndex = [];

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    const schema = yup
        .object({
            companyName: yup
                .string()
                .trim()
                .required("Company Name is required")
                .max(60, 'Maximum allowed characters is 60'),
            // .matches(/^[ A-Za-z0-9_@./#&+-]*$/, "Only alphanumeric characters are allowed ")
        })
        .required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        setSelectedBusinessType(profileOnBoarding?.businesstype ? profileOnBoarding?.businesstype : businessType && businessType.length > 0 ? businessType[0][`businesstype_id`] : ``);
        setBusinessTypeValue([businessType?.[0]?.name])
        setSelectedBusinessTypeIds([businessType?.[0]?.businesstype_id]);
    }, [businessType])

    useEffect(() => {
        let tempArray = [];
        if (profileOnBoarding?.businesstype && profileOnBoarding?.businesstype.length > 0) {
            profileOnBoarding?.businesstype.map((business, index) => {
                if (businessType && businessType.length > 0) {
                    businessType.map((data, index) => {
                        if (data?.businesstype_id === business?.businesstype) {
                            tempArray.push(data?.name)
                        }
                    })
                }

            })
        }

        if (tempArray && tempArray.length > 0) setBusinessTypeValue(tempArray);
    }, [profileOnBoarding?.businesstype])
    useEffect(() => {
        setSelectedBrand(profileOnBoarding?.noe ? profileOnBoarding?.noe : brands && brands.length > 0 ? brands[0][`number_brand_id`] : ``);
    }, [brands])

    useEffect(() => {
        setSelectedYearOfEstablish(profileOnBoarding?.yearofestablish ? profileOnBoarding?.yearofestablish : yearOfEstablish && yearOfEstablish.length > 0 ? yearOfEstablish[0][`yoe_id`] : ``);
    }, [yearOfEstablish])


    useEffect(() => {

        if (Object.keys(brands).length === 0 || Object.keys(businessType).length === 0 || Object.keys(yearOfEstablish).length === 0) {
            setIsLoading(true);

            moduleApis.push(getNoOfBrand());
            moduleApiIndex.push(`brandIndex`);

            moduleApis.push(getBusinessType(`Other`, `2`));
            moduleApiIndex.push(`businessTypeIndex`);

            moduleApis.push(getYearOfEstablish());
            moduleApiIndex.push(`yearOfEstablish`);

            Promise.all(moduleApis)
                .then((result) => {
                    let brandIndex = moduleApiIndex.indexOf('brandIndex');
                    if (brandIndex !== -1 && result.length) {
                        const dataResult = result[brandIndex] ? result[brandIndex] : {};
                        if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                            setBrands(dataResult.data.data);
                            dispatch(brandAction.setBrand(dataResult.data.data));
                        }
                    }

                    let businessTypeIndex = moduleApiIndex.indexOf('businessTypeIndex');
                    if (businessTypeIndex !== -1 && result.length) {
                        const dataResult = result[businessTypeIndex] ? result[businessTypeIndex] : {};
                        if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                            setBusinessType(dataResult.data.data);
                            dispatch(businsessTypeAction.setBusinessType(dataResult.data.data));
                        }
                    }

                    let yearOfEstablishIndex = moduleApiIndex.indexOf('yearOfEstablish');
                    if (yearOfEstablishIndex !== -1 && result.length) {
                        const dataResult = result[yearOfEstablishIndex] ? result[yearOfEstablishIndex] : {};
                        if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                            setYearOfEstablish(dataResult.data.data);
                            dispatch(yearOfEstablishAction.setYearOfEstablish(dataResult.data.data));
                        }
                    }

                    setIsLoading(false);
                })
        }
    }, []);

    useEffect(() => {
        if (user?.token?.access) {
            getBuyerOnBoardingInfo()
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        setProfileOnBoarding(response.data.data);
                        dispatch(onBoardingAction.setOnBoardingObject(response.data.data))
                    }
                })
                .catch((error) => {
                    console.log("We have error:", error);
                })
        }
    }, [])

    const onSubmit = (data) => {
        let tempArray = [];
        if (businessType && businessType.length > 0) {
            businessType.map((businessType) => {
                businessTypeValue.map((type) => {
                    if (businessType[`name`] === type) {
                        tempArray.push(businessType[`businesstype_id`]);
                    }
                })
            })
        }

        setSelectedBusinessTypeIds(tempArray);
        setIsLoading(true);

        buyerOnboardingFirstStep(1, data[`companyName`], tempArray.toString(), selectedYearOfEstablish, compnanyWebsite)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);

                    // setTimeout(() => {
                    //     navigate(`/myproducts`, { state: { "isCreateBrand": true, } })
                    // }, 500)
                    if (props.onChildSelectIndex) {
                        props.onChildSelectIndex(response.data.step_level);
                    }
                } else {
                    if (response && response.response.data && response.response.data.detail) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.response?.data?.detail);
                        }
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("We have error", error);
            })
    };

    const companyWebsiteRegex = () => {
        if (compnanyWebsite) {
            return /^((http|https):\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{6,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(compnanyWebsite) ? false : true
        }
        return false;
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };


    const handleChange = (event) => {

        const {
            target: { value },
        } = event;
        setBusinessTypeValue(
            typeof value === 'string' ? value.split(',') : value,
        );

    };

    return (
        <Box className={`company-profile-wrap`}>
            {isLoading && <Loader />}

            {isAlertMessageShow && (
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            )}
            <Typography align="center" variant="h1" className={`first-heading`}>
                Set up your company profile
            </Typography>

            <div className={`second-heading-wrap`}>
                <Typography align="center" variant="h2" className={`second-heading`}>
                    This is the most important information to add. It helps maintain credibility when other businesses discover you.
                </Typography>
            </div>

            <form className={`form-input-control on-boarding-form`} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className={`margin-bottom-28`}>
                    <Typography className={`label`}>Company Name</Typography>

                    <TextField
                        id="companyName"
                        name="companyName"
                        placeholder={`Enter name as per trade license`}
                        margin="dense"
                        className={`mt-0`}
                        {...register("companyName")}
                        error={errors.companyName ? true : false}
                        defaultValue={profileOnBoarding?.company_name || ``}
                    />
                    <p className={`field-err-msg`}>{errors?.companyName?.message}</p>
                </div>

                <div className={`margin-bottom-28 business-type-multiselect`}>
                    <Typography className={`label`}>Business Type</Typography>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        {/* <InputLabel id="businessTypeInput">Business Type</InputLabel> */}
                        <Select
                            labelId="businessTypeLabel"
                            id="businessType"
                            multiple
                            value={businessTypeValue}
                            onChange={handleChange}
                            // input={<OutlinedInput label="Business Type" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {businessType && businessType.length > 0 && businessType.map((items, index) => (
                                <MenuItem
                                    key={index}
                                    value={items[`name`]}
                                // style={getStyles(items[`name`], businessTypeValue, theme)}
                                >
                                    <Checkbox checked={businessTypeValue.indexOf(items[`name`]) > -1} />
                                    {/* {items[`name`]} */}
                                    <ListItemText primary={items[`name`]} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* <select id="businessSelect" onChange={(e) => setSelectedBusinessType(e.target.value)}>
                        {
                            businessType && businessType.length > 0 &&
                            businessType.map((type, index) => {
                                return (
                                    <option key={index} value={type[`businesstype_id`]} selected={`${selectedBusinessType === type[`businesstype_id`] ? 'selected' : ''}`}>
                                        {type[`name`]}
                                    </option>
                                )
                            })
                        }
                    </select> */}
                </div>

                <div className={`margin-bottom-28`}>
                    <Typography className={`label`}>Year Established</Typography>
                    <select id="yearSelect" className={`selection-opt-with-calender`} onChange={(e) => setSelectedYearOfEstablish(e.target.value)}>
                        {
                            yearOfEstablish && yearOfEstablish.length > 0 &&
                            yearOfEstablish.map((year, index) => {
                                return (
                                    <option key={index} value={year[`yoe_id`]} selected={`${selectedYearOfEstablish === year[`yoe_id`] ? 'selected' : ''}`}>
                                        {year[`name`]}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>

                <div>
                    <Typography className={`label`}>
                        Company website (Optional)
                    </Typography>

                    <TextField
                        id="compnanyWebsite"
                        name="compnanyWebsite"
                        placeholder={`https://loremipsum.com`}
                        className={`mt-0`}
                        margin="dense"
                        inputProps={{
                            onChange: (e) => { setCompnanyWebsite(e.target.value) }
                        }}
                        error={companyWebsiteRegex()}
                        defaultValue={compnanyWebsite || ``}
                    />

                    <p className={`field-err-msg`}>{companyWebsiteRegex() ? `Invalid format` : ``}</p>
                </div>

                <div className="hr"></div>
                <div className={`button-wrapper flex-end`}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        className={`btn-primary-after-login ${selectedBusinessType && selectedYearOfEstablish && selectedBrand && isValid ? `` : `disabled`}`}
                        disabled={selectedBusinessType && selectedYearOfEstablish && selectedBrand && isValid ? false : true}
                    >
                        Next
                    </Button>
                </div>
            </form>
        </Box>
    );
};

const IndustryTab = (props) => {

    const user = props?.usersData?.user || {};

    const [userIndustry, setProfileOnBoarding] = useState(props?.userOnBoardingData?.onBoarding?.user_industry || []);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [industryList, setIndustryList] = useState(props?.industryListData?.industryList || []);

    const [isIndustrySelected, setIsIndustrySelected] = useState(false);

    const [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);


    useEffect(() => {
        if (Object.keys(industryList).length === 0) {
            setIsLoading(true);
            getIndustryList()
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status) {
                        setIndustryList(response.data.data);
                        dispatch(industryListAction.setIndustryList(response.data.data));
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("we have a error", error);
                })
        }
    }, [])

    useEffect(() => {
        if (industryList && industryList.length > 0 && userIndustry && userIndustry.length > 0) {
            industryList.filter((item) => {
                userIndustry.some((userItem) => {
                    if (item[`industry_id`] === userItem[`industry`]) {
                        item[`isSelected`] = true;
                    }
                })
            })
        }
    }, [industryList])

    useEffect(() => {

        if (industryList && industryList.length > 0) {
            setIsIndustrySelected(industryList.some(function (item) {
                return item[`isSelected`] === true;
            })
            )
        }
    }, [industryList]);


    useEffect(() => {
        if (user?.token?.access) {
            getBuyerOnBoardingInfo()
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        setProfileOnBoarding(response.data.data);
                        dispatch(onBoardingAction.setOnBoardingObject(response.data.data))
                    }
                })
                .catch((error) => {
                    console.log("We have error:", error);
                })
        }
    }, [])
    const selectIndustry = (id) => {
        setIndustryList(
            industryList.map((item) => {
                if (item.industry_id === id) {
                    return { ...item, isSelected: !item[`isSelected`] }
                } else {
                    return { ...item };
                }
            }
            ))
    }

    const onNextButtonClick = () => {
        let selectedIndustryCategoryId = [];
        industryList.map((item) => {
            if (item[`isSelected`]) {
                selectedIndustryCategoryId.push(item[`industry_id`]);
            }
        })

        buyerOnboardingSecondStep(2, selectedIndustryCategoryId)
            .then((response) => {
                if (response && response.data && response.status === 200 && response.data.data) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    // setTimeout(() => {
                    //     navigate(`/myproducts`, { state: { "isCreateBrand": true, } })
                    // }, 500)
                    if (props.onChildSelectIndex) {
                        props.onChildSelectIndex(response.data.step_level);
                    }
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.response?.data?.detail);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("We have error", error);
            })
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    return (
        <Box className={`industry-wrap`}>
            {isLoading && <Loader />}
            {isAlertMessageShow && (
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            )}
            <Typography align="center" variant="h1" className={`first-heading`}>
                Select your industry(s)
            </Typography>

            <div className={`second-heading-wrap`}>
                <Typography align="center" variant="h2" className={`second-heading`}>
                    We’ll use this to connect you with suppliers in the same industry
                </Typography>
            </div>

            <div className={`select-more-wrap`}>
                <div></div>
                <div className={`text`}><Gridicon /> Select one or more</div>
            </div>

            <div className={`industry-list-wrap`}>
                {
                    industryList && industryList.length > 0 &&
                    industryList.map((industry, index) => {
                        return (
                            <div key={index} className={`${industry[`isSelected`] ? 'selected' : 'not-selected on-hover-draw-line'}`} onClick={() => { selectIndustry(industry[`industry_id`]) }}>
                                <span>{industry[`name`]}</span>
                            </div>
                        )
                    })
                }
            </div>

            <div className="hr"></div>

            <div className={`button-wrapper`}>

                {/* <div className={`skip-btn`} onClick={() => { if (props.onChildSelectIndex) props.onChildSelectIndex(3) }}>
                    Skip this step
                </div> */}
                <div>
                    <Button
                        variant="contained"
                        size="large"
                        className={`button-back`}
                        onClick={() => { if (props.onChildSelectIndex) props.onChildSelectIndex(1) }}
                    >
                        Back
                    </Button>

                    <Button
                        variant="contained"
                        size="large"
                        className={`btn-primary-after-login ${isIndustrySelected ? `` : `disabled`}`}
                        disabled={isIndustrySelected ? false : true}
                        onClick={() => { onNextButtonClick() }}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </Box>
    )
};

const CompanyTab = (props) => {

    const user = props?.usersData?.user || {},
        navigate = useNavigate(),
        dispatch = useDispatch();

    const [profileOnBoarding, setProfileOnBoarding] = useState(props?.userOnBoardingData?.onBoarding || {}),
        [userProfileImage, setUserProfileImage] = useState(props?.userOnBoardingData?.onBoarding?.user_profile || ``),
        [userProfileImageUpload, setUserProfileImageUpload] = useState(``),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false);


    const schema = yup
        .object({
            companyName: yup
                .string()
                .trim()
                .required("Company Name is required")
                .max(60, 'Maximum allowed characters is 60'),
            // .matches(/^[ A-Za-z0-9_@./#&+-]*$/, "Only alphanumeric characters are allowed ")
            companyTagline: yup
                .string()
                .trim()
                .notRequired(),
            // .required("Company tagline is required")
            // .max(100, 'Maximum allowed characters is 100'),
            companyDescription: yup
                .string()
                .trim()
                .notRequired()
            // .required("Company description is required")
            // .max(150, 'Maximum allowed characters is 150'),
        })
        .required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        if (user?.token?.access) {
            getBuyerOnBoardingInfo()
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        setProfileOnBoarding(response.data.data);
                        dispatch(onBoardingAction.setOnBoardingObject(response.data.data))
                    }
                })
                .catch((error) => {
                    console.log("We have error:", error);
                })
        }
    }, [])

    const showUploadImage = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (event) => {

            resolve(event.target.result);
        }
        reader.onerror = (error) => {
            reject(error);
        }
    });

    const onUserProfileImageUpload = async (file) => {
        setUserProfileImageUpload(file);
        setUserProfileImage(await showUploadImage(file));
    }

    const onUserProfileRemove = () => {
        setUserProfileImage(``)
    }
    const onSubmit = (data) => {
        setIsLoading(true);
        buyerOnboardingThirdStep(3, data[`companyName`] || ``, data[`companyTagline`] || ``, userProfileImageUpload || {}, data[`companyDescription`] || ``)
            .then((response) => {
                if (response && response.data && response.status === 200 && response.data.data) {

                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        navigate(`/buyer/catalogue`)
                        window.scrollTo(0, 0);
                    }, 500)
                    if (props.onChildSelectIndex) {
                        props.onChildSelectIndex(response.data.step_level);
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.response?.data?.detail);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("We have error", error);
            })

    }
    return (
        <Box className={`company-details-wrapper`}>
            <Typography align="center" variant="h1" className={`first-heading`}>
                You're all set, {profileOnBoarding?.company_name || ``}
            </Typography>

            <div className={`second-heading-wrap`}>
                <Typography align="center" variant="h2" className={`second-heading`}>
                    Let's setup your company profile
                </Typography>
            </div>

            <form className={`form-input-control on-boarding-form`} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className={`margin-bottom-28`}>
                    <Typography className={`label`}>Company Name</Typography>

                    <TextField
                        id="companyName"
                        name="companyName"
                        placeholder={`Enter name as per trade license`}
                        margin="dense"
                        className={`mt-0`}
                        {...register("companyName")}
                        error={errors.companyName ? true : false}
                        defaultValue={profileOnBoarding?.company_name || ``}
                    />
                    <p className={`field-err-msg`}>{errors?.companyName?.message}</p>
                </div>



                <div className={`margin-bottom-28`}>
                    <Typography className={`label`}>Company Logo</Typography>
                    {
                        userProfileImage === ``
                            ?
                            <div className={`logo-wrap`} onClick={() => { if (document.getElementById("userProfile")) document.getElementById("userProfile").click() }}>
                                <div className={`logo flex-column`}>
                                    +Add Company Logo
                                    {/* <p className={`modal-img-info`}>Image must be at least 160 x 160 px.</p> */}
                                </div>
                                <div className={`edit-icon`}>
                                    <EditImageIcon />
                                </div>
                            </div>
                            :
                            <div className={`image-preview-section`}>
                                <div className={`remove`} onClick={() => { onUserProfileRemove() }}><CloseIcon /></div>
                                <div className="preview-img">
                                    <img className={`w-100`} src={userProfileImage} alt="Company Logo" loading="lazy" />
                                    <div className={`edit-icon`} onClick={() => { if (document.getElementById("userProfile")) document.getElementById("userProfile").click() }}>
                                        <EditImageIcon />
                                    </div>
                                </div>
                            </div>
                    }

                    <input className={`visibility-hidden width-0 position-absolute`} type='file' name='userProfile' id='userProfile' onChange={(event) => { onUserProfileImageUpload(event.target.files[0]) }} accept="image/x-png,image/gif,image/jpeg" />
                </div>

                <div className={`margin-bottom-28`}>
                    <Typography className={`label`}>Company tagline (Optional)</Typography>

                    <TextField
                        id="companyTagline"
                        name="companyTagline"
                        placeholder={`Enter tagline`}
                        margin="dense"
                        className={`mt-0`}
                        {...register("companyTagline")}
                        error={errors.companyTagline ? true : false}
                        defaultValue={profileOnBoarding?.company_tagline || ``}
                    />
                    <p className={`field-err-msg`}>{errors?.companyTagline?.message}</p>
                </div>

                <div className={`margin-bottom-28`}>
                    <Typography className={`label`}>Company description (Optional)</Typography>

                    <TextField
                        id="companyDescription"
                        name="companyDescription"
                        placeholder={`Enter description`}
                        margin="dense"
                        className={`mt-0`}
                        {...register("companyDescription")}
                        error={errors.companyDescription ? true : false}
                        defaultValue={profileOnBoarding?.company_description || ``}
                    />
                    <p className={`field-err-msg`}>{errors?.companyDescription?.message}</p>
                </div>

                <div className="hr"></div>

                <div className={`button-wrapper`}>

                    {/* <div className={`skip-btn`} onClick={() => { if (props.onChildSelectIndex) props.onChildSelectIndex(3) }}>
                    Skip this step
                </div> */}
                    <div>
                        <Button
                        variant="contained"
                        size="large"
                        className={`button-back`}
                        onClick={() => { if (props.onChildSelectIndex) props.onChildSelectIndex(2) }}
                    >
                        Back
                    </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            className={`btn-primary-after-login ${isValid && userProfileImage ? `` : `disabled`}`}
                            disabled={isValid && userProfileImage ? false : true}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </form>
        </Box>
    )
}
const OnBoarding = (props) => {
    const tabsArray = ["Company", "Industry", "Profile"];
    const user = props?.usersData?.user || {};
    const [selectedTabIndex, setSelectedTabIndex] = useState(props?.usersData?.user?.step_level || 1);

    const onChildClickSelectIndex = (index) => {
        setSelectedTabIndex(index);
    }

    const showTabBasedOnIndex = (index, props) => {
        switch (index) {
            case 1:
                return <CompanyProfileTab {...props} onChildSelectIndex={onChildClickSelectIndex} />;
                break;

            case 2:
                return <IndustryTab {...props} onChildSelectIndex={onChildClickSelectIndex} />;
                break;

            case 3:
                return <CompanyTab {...props} onChildSelectIndex={onChildClickSelectIndex} />;
                break;

            default:
                return <CompanyProfileTab {...props} onChildSelectIndex={onChildClickSelectIndex} />;
                break;
        }
    };

    return (
        <Box className={`onboarding-component-wrap`}>
            <Box className={`tabs-wrap`}>
                {tabsArray &&
                    tabsArray.length > 0 &&
                    tabsArray.map((tab, index) => {
                        return (
                            <div
                                key={index}
                                className={`${selectedTabIndex == index + 1 ? "selected" : ``} tab-items`}
                            // onClick={(e) => {
                            // setSelectedTabIndex(index + 1);
                            // }}
                            >
                                {`${index + 1}. ${tab}`}
                            </div>
                        );
                    })}
            </Box>

            <Box className={`tabs-container`}>
                {showTabBasedOnIndex(selectedTabIndex, props)}
            </Box>
        </Box>
    );
};

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);
