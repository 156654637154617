import React, { useEffect, useState, Fragment } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField"
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Autocomplete from '@mui/material/Autocomplete';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from '@mui/material/FormControl';

//Helpers function related imports
import debounce from '../../../../../helpers/debounce';
import getDefaultCountry from '../../../../../helpers/getDefaultCountry';
import getDefaultBarcodeType from '../../../../../helpers/getDefaultBarcodeType';
import getDefaultPackVolumeWeight from '../../../../../helpers/getDefaultPackVolumeWeight';
import getDefaultCurrency from '../../../../../helpers/getDefaultCurrency';
import getDefaultVolume from '../../../../../helpers/getDefaultVolume';
import getDefaultWeight from '../../../../../helpers/getDefaultWeight';
import getDefaultDimension from '../../../../../helpers/getDefaultDimension';
import imageResizing from "../../../../../helpers/imageResizing";

//Apis related imports
import getBrandCategoryList from '../../../../../apis/Supplier/getBrandCategoryList';
import addBrandCategory from '../../../../../apis/Supplier/addBrandCategory';
import productUpdate from '../../../../../apis/Supplier/productUpdate';
import getProductBrandList from '../../../../../apis/Supplier/getProductBrandList';
import getProductFlavourList from '../../../../../apis/Supplier/getProductFlavourList';
import addProductBrand from '../../../../../apis/Supplier/addProductBrand';
import addProductFlavour from '../../../../../apis/Supplier/addProductFlavour';

// React route related imports
import { useNavigate } from "react-router-dom";

//Redux related imports
import { useDispatch } from "react-redux";

//Icons related imports
import CameraIcon from '../../../../../icons/svgs/camera';
import InformationIcon from "../../../../../icons/svgs/infromationIcon";

//Components related imports
import Loader from "../../../Loader";
import AlertMessage from "../../../AlertMessage";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const digitsOnly = (value) => /^\d+$/.test(value)
const numericwithhypen = (value) => /^[0-9\-]+$/.test(value)
const numericWithHyphenWithDot = (value) => /^[0-9-.]+$/.test(value);
const schema = yup
    .object({
        barcode: yup.lazy((value) => {
            if (value && document.getElementById(`barcode`)) {
                return yup.string()
                    .trim()
                    .min(3, "Too short - should be at least 3 characters")
                    .max(40, 'Maximum allowed characters is 40')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        hs_code: yup.lazy((value) => {
            if (value && document.getElementById(`hs_code`)) {
                return yup.string()
                    .test('Digits only', 'Enter only number or hyphen (-)', numericWithHyphenWithDot)
                    .typeError('Enter only number or hyphen (-)')
                    .max(18, 'Maximum allowed characters is 18');
            }
            return yup.string()
                .typeError('Enter only number or hyphen (-) or periods (.)')
                .notRequired();
        }),        
        price: yup.lazy((value) => {
            if (value && document.getElementById(`price`)) {
                return yup.number().positive("Min price should be above 0")
                // .typeError('you must specify a number')
                // .min(1, "Too short - should be at least 1 characters")
            }

            return yup.string().typeError(`Min price should be above 0`).notRequired()
        }),
        width: yup.lazy((value) => {
            if (value && document.getElementById(`width`)) {
                return yup.number()
                    .typeError('you must specify a number')
                    .min(1, "Too short - should be at least 1 characters")
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        height: yup.lazy((value) => {
            if (value && document.getElementById(`height`)) {
                return yup.number()
                    .typeError('you must specify a number')
                    .min(1, "Too short - should be at least 1 characters")
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        depth: yup.lazy((value) => {
            if (value && document.getElementById(`depth`)) {
                return yup.number()
                    .typeError('you must specify a number')
                    .min(1, "Too short - should be at least 1 characters")
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        volume: yup.lazy((value) => {
            if (value && document.getElementById(`volume`)) {
                return yup.number()
                    .typeError('you must specify a number')
                    .min(1, "Too short - should be at least 1 characters")
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        weight: yup.lazy((value) => {
            if (value && document.getElementById(`weight`)) {
                return yup.number()
                    .typeError('you must specify a number')
                // .min(1, "Too short - should be at least 1 characters")
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        item: yup.lazy((value) => {
            if (value && document.getElementById(`item`)) {
                return yup.number().positive("Max price should be above 0")
                //.typeError('you must specify a number')
                // .min(1, "Too short - should be at least 1 characters")
            }

            return yup.string().typeError(`Max price should be above 0`).notRequired()
        }),

        MSRP: yup.lazy((value) => {
            if (value && document.getElementById(`MSRP`)) {
                return yup.number()
                    .typeError('you must specify a number')
                // .min(1, "Too short - should be at least 1 characters")
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        packageSize1: yup.lazy((value) => {
            if (value && document.getElementById(`label-1`)) {
                return yup.number()
                    .typeError('you must specify a number')
                    .min(1, "Too short - should be at least 1 characters")
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        packageSize2: yup.lazy((value) => {
            if (value && document.getElementById(`label-2`)) {
                return yup.number()
                    .typeError('you must specify a number')
                    .min(1, "Too short - should be at least 1 characters")
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        packageSize3: yup.lazy((value) => {
            if (value && document.getElementById(`label-3`)) {
                return yup.number()
                    .typeError('you must specify a number')
                    .min(1, "Too short - should be at least 1 characters")
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        container_loadability_20_ft: yup.lazy((value) => {
            if (value && document.getElementById(`container_loadability_20_ft`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        container_loadability_40_ft: yup.lazy((value) => {
            if (value && document.getElementById(`container_loadability_40_ft`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        container_loadability_40_ft_loose: yup.lazy((value) => {
            if (value && document.getElementById(`container_loadability_40_ft_loose`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        full_truck_load: yup.lazy((value) => {
            if (value && document.getElementById(`full_truck_load`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        container_loadability_20_ft_loose: yup.lazy((value) => {
            if (value && document.getElementById(`container_loadability_20_ft_loose`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        p_p_case: yup.lazy((value) => {
            if (value && document.getElementById(`p_p_case`)) {
                return yup.string().test('Digits only', 'you must specify a number', digitsOnly)
                    .typeError('you must specify a number')
            }
            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
        palletquantity: yup.lazy((value) => {
            if (value && document.getElementById(`palletquantity`)) {
                return yup.number()
                    .typeError('you must specify a number')
            }

            return yup.string().typeError(`you must specify a number`).notRequired()
        }),
    })
    .required();

const AutoComplete = (props) => {
    const { productDetails, type = `category`, searchValue, data } = props;

    const [hideAddOption, setHideAddOption] = useState(false);

    useEffect(() => {
        let isExist = data.some(function (el) {
            return el?.name.toLowerCase() === searchValue.toLowerCase()
        });

        if (isExist) {
            setHideAddOption(true);
        } else {
            setHideAddOption(false);
        }
    }, [data])
    const onAddCategoryClick = () => {

        if (type === `productBrand`) {
            addProductBrand(productDetails?.brand, searchValue)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        if (props.onCategorySelect) {
                            props.onCategorySelect(response.data.data, false);
                        }
                    }

                })
                .catch((error) => {
                    console.log("we have error at onAddCategoryClick", error);
                })
        }
        else if (type === `productFlavour`) {
            addProductFlavour(searchValue)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        if (props.onCategorySelect) {
                            props.onCategorySelect(response.data.data, false);
                        }
                    }

                })
                .catch((error) => {
                    console.log("we have error at onAddCategoryClick", error);
                })
        } else {
            addBrandCategory(productDetails?.brand, searchValue)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        if (props.onCategorySelect) {
                            props.onCategorySelect(response.data.data, false);
                        }
                    }

                })
                .catch((error) => {
                    console.log("we have error at onAddCategoryClick", error);
                })
        }
    }

    const onCategoryClick = (category) => {
        if (props.onCategorySelect) {
            props.onCategorySelect(category, false);
        }
    }

    return (
        <div className={`autocomplete-wrapper`}>
            <div className={`separator`}></div>

            <div className={`autocomplete`} id="autocomplete">
                {
                    data && data.length > 0 &&
                    data.map((category, index) => {
                        return (
                            <div key={index} onClick={() => { onCategoryClick(category) }}>
                                {category[`name`]}
                            </div>
                        )
                    })
                }

                {
                    
                    !hideAddOption &&
                    <div className={`add-category-wrap`} onClick={() => { onAddCategoryClick() }}>
                        
                        {/* <AddCircleOutlineIcon /> {` ${type === `category` ? 'Add category' : 'Add brand'} ${searchValue}`} */}
                        <AddCircleOutlineIcon /> 
                        {` ${
                        type === `category` 
                            ? 'Add category' 
                            : type === "productFlavour" 
                            ? "Add flavour"
                            : 'Add brand'} ${searchValue}`
                        }
                    </div>
                }

            </div>

        </div>
    )
}


const ImageUploadSection = (props) => {
    const { isImageUploadSliderShow, onClose, onSave } = props;

    const [uploadImages, setUploadImagesValue] = useState(props.images || []),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);

    let dropArea;
    let imageVideoWrapper;
    useEffect(() => {
        if (isImageUploadSliderShow) {
            document.body.classList.add('hide-scroll');
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            document.body.classList.remove('hide-scroll');
        }

        dropArea = document.getElementsByClassName('image-upload-wrapper')[0];
        imageVideoWrapper = document.getElementsByClassName('image-upload-sidenav')[0];

        dropArea.addEventListener('dragenter', highlightSection, false)
        dropArea.addEventListener('dragover', highlightSection, false)
        dropArea.addEventListener('dragleave', handleDrop, false)
        dropArea.addEventListener('drop', handleDrop, false)
        return () => {
            document.body.classList.remove('hide-scroll');
            //new code added for remove eventlistener
             // Remove event listeners
            dropArea.removeEventListener('dragenter', highlightSection, false);
            dropArea.removeEventListener('dragover', highlightSection, false);
            dropArea.removeEventListener('dragleave', handleDrop, false);
            dropArea.removeEventListener('drop', handleDrop, false);
        }
    }, [isImageUploadSliderShow])

    useEffect(() => {
        setUploadImagesValue(props.images);
    }, [props.images])


    const removeImage = (index) => {
        let tempArray = uploadImages;

        tempArray = tempArray.filter((file, i) => {
            return i !== index;
        })
        setUploadImagesValue(tempArray)
    }

    const uploadImgOnChange = async (files) => {
        let tempArray = [];
        
        if (uploadImages.length + files.length > 5) {
            // alert('Upload upto 3 images');
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`Upload upto 5 images`);
        }
        else {
            console.log('files'+files)
            for (let k = 0; k < files.length; k++) {
                if ([`image/jpeg`, `image/png`, `image/gif`].indexOf(files[k][`type`]) === -1) {
                    
                    setAlternateMessageShow(true);
                    setAlertMessageType("information");
                    setAlertMessage(`File extension not supported!`);
                    return;
                } else {
                    console.log(files[k])
                    const reader = new FileReader();
                    reader.readAsDataURL(files[k]);
                    reader.onload = (i) => {
                        setUploadImagesValue((uploadImages) => [...uploadImages, {
                            "file": files[k],
                            "image": i.target.result
                        }]);
                    };
                }
            }
        }
    }


    const highlightSection = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementsByClassName(`drop-your-image-wrap`)[0].classList.add('highlight-upload-section');
        // imageVideoWrapper.classList.add('border-none');
    }

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('handledrop'+e.dataTransfer.files)
        document.getElementsByClassName(`drop-your-image-wrap`)[0].classList.remove('highlight-upload-section');
        // imageVideoWrapper.classList.remove('border-none');
        uploadImgOnChange(e.dataTransfer.files);
    }

    const onImageSave = () => {
        if (onSave) onSave(uploadImages);
    }

    const onSliderClose = () => {
        if (uploadImages) {
            onImageSave()
        } else {
            setUploadImagesValue([]);
        }
        onClose(false);
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };
    return (
        <Box className={`image-upload-wrapper ${isImageUploadSliderShow ? 'show-overlay' : ''}`}>

            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`image-upload-sidenav scroll-bar-theme z-9999 ${isImageUploadSliderShow ? 'show' : 'hide'}`}>
                <input type='file' className={`visibility-hidden`} name='imageupload' id='imageupload' onChange={(event) => { uploadImgOnChange(event.target.files) }} accept="image/x-png,image/gif,image/jpeg" multiple />
                <div className={`image-upload`}>
                    <div className={`header`}>
                        <div className={`text`}>
                            Upload Image
                        </div>
                        <div className={`close-icon`} onClick={() => { if (onClose) { onSliderClose() } }}>
                            <CloseIcon />
                        </div>
                    </div>
                    <div className={`divider`}></div>

                    <div className={`drop-your-image-wrap`}>
                        <div className={`drop-image`} onClick={() => { document.getElementById('imageupload').click() }}>
                            <CameraIcon />
                        </div>
                        <div className={`text`}>
                            Drop your image(s) here
                        </div>
                    </div>

                    {
                        uploadImages && uploadImages.length > 0
                            ?
                            <div className={`image-upload-success`}>
                                {
                                    uploadImages && uploadImages.length > 0 &&
                                    uploadImages.map((image, index) => {
                                        return (
                                            <div className={`image-info-wrap`} key={index}>
                                                <div className={`image-info`}>
                                                    {
                                                        (image?.file?.name || image?.name) &&
                                                        <div className={`name`}>
                                                            {image?.file?.name || image?.name}
                                                        </div>
                                                    }
                                                    {/* {
                                                        image?.file?.size &&
                                                        <div className={`size`}>
                                                            {`(${image?.file?.size} KB)`}
                                                        </div>
                                                    } */}

                                                </div>

                                                <div className={`close-icon`} onClick={() => { removeImage(index) }}>
                                                    <CloseIcon />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className={`product-upload-information-wrapper`}>
                                <div className={`product-image`}>
                                    <div className={`text`}>Product image</div>
                                    <div className={`description`}>The product image is your first impression to buyers and one of the most important elements of your listing.</div>
                                </div>

                                <div className={`image-requirement`}>
                                    <div className={`text`}>Image requirements</div>
                                    <div className={`description`}>
                                        <ul>
                                            <li>Image must be atleast 600 x 600 px</li>
                                            <li>Use a white background</li>
                                            <li>Show the entire product</li>
                                            <li>Your product should be the focus of the image</li>
                                            <li>Avoid words, logos and watermarks on the image</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                    }

                    <div className={`divider`}></div>
                    <div className={`button-wrapper`}>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            id="submit-btn"
                            className={`w-100 btn-primary-after-login ${uploadImages.length > 0 ? '' : 'disabled'}`}
                            onClick={() => { onImageSave() }}
                            disabled={uploadImages.length > 0 ? false : true}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </Box>
    )
}
const ProductPreview = (props) => {
    console.log(props?.userSettings)
    const {
        itemCodeChange,
        barcodeChange,
        hscodeChange,
        innerPackChange,
        outerPackChange,
        packageWeightChange,
        measTypeChange,
        priceCurrentChange,
        priceChange,
        itemChange,
        msrpTypeChange,
        msrpChange,
        widthChange,
        heightChange,
        depthChange,
        dimensionsChange,
        volumeChange,
        selectedVolumeChange,
        weightChange,
        selectedWeightChange,
        selectedBarcodeChange,
        uspsChange,
        categoryChange,
        brandChange,
        flavourChange,
        uploadImagesChange,
        countryChange,
        onSectionApiCallSuccess,
        containerLoadability20FtChange,
        containerLoadability40FtChange,
        containerLoadability40FtlooseChange,
        containerLoadability20FtLooseChange,
        fullTruckLoadChange,
        casePalaleteQuantityChange,
        unitPerCasechange
    } = props;

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const [barcodeList, setBarcodeList] = useState(props?.barcodeList || []),
        [MEASList, setMEASList] = useState(props?.MEASList || []),
        [countryList, setCountryList] = useState(props?.countryList || []),
        [currencyList, setCurrencyList] = useState(props?.currencyList || []),
        [dimensionList, setDimensionList] = useState(props?.dimensionList || []),
        [volumeList, setvolumeList] = useState(props?.volumeList || []),
        [weightList, setWeightList] = useState(props?.weightList || []),
        [productDetails, setProductDetails] = useState(props?.productDetails || {}),
        [isLoading, setIsLoading] = useState(false),
        [uploadImages, setUploadImagesValue] = useState(productDetails?.product_img || []),
        [primaryImageValue, setPrimaryImageValue] = useState(''),
        [uspsValues, setUspsValuesValue] = useState(productDetails?.product_usps || []),
        [categoryValues, setCategoryValue] = useState([]),
        [isUSPsError, setUSPsErrorValue] = useState(false),
        [isUSPsErrorCheck, setUSPsErrorCheckValue] = useState(false),
        [isCategoryError, SetCategoryErrorValue] = useState(false),
        [isCategoryErrorCheck, setCategoryErrorCheckValue] = useState(false),
        [selectedBarcodeType, setSelectedBarcodeType] = useState(productDetails?.barcodetype || getDefaultBarcodeType() || ``),
        [selectedPriceCurrency, setSelectedPriceCurrency] = useState(productDetails?.product_price?.[0]?.s_price_currency || getDefaultCurrency() || ``),
        [selectedMSRPType, setSelectedMSRPType] = useState(productDetails?.product_price?.[0]?.m_price_currency || getDefaultCurrency() || ``),
        // [selectedCountry, setSelectedCountry] = useState(productDetails?.country || getDefaultCountry() || ``),
        // [selectedCountry, setSelectedCountry] = useState(productDetails?.country || ``),
        [selectedCountry, setSelectedCountry] = useState(productDetails?.country || "none"),
        [selectedMEASType, setSelectedMEASType] = useState(productDetails?.ps_meas || getDefaultPackVolumeWeight() || ``),
        [isImageUploadSliderShow, setImageUploadSliderShow] = useState(false),
        [barcodeValue, setBarcodeValue] = useState(productDetails?.barcode || ``),
        [hsCodeValue, setHsCodeValue] = useState(productDetails?.hs_code || ``),
        [itemCodeValue, setItemCodeValue] = useState(productDetails?.sku_id || ``),
        [priceValue, setPriceValue] = useState(parseInt(productDetails?.product_price?.[0]?.s_price_from) || ``),
        [itemValue, setItemValue] = useState(parseInt(productDetails?.product_price?.[0]?.s_price_to) || ``),
        [companyName, setCompanyName] = useState(``),
        [packageSizeFirstValue, setPackageSizeFirstValue] = useState(parseInt(productDetails?.ps_unit) || ``),
        [packageSizeSecondValue, setPackageSizeSecondValue] = useState(parseInt(productDetails?.ps_quantity) || ``),
        [packageSizeThirdValue, setPackageSizeThirdValue] = useState(parseInt(productDetails?.ps_meas_value) || ``),
        [msrpValue, setMsrpValue] = useState(parseInt(productDetails?.product_price?.[0]?.m_price) || ``),
        [isAutoCompleteOpen, setAutoCompleteFlag] = useState(false),
        [categoryTextBoxValue, setCategoryTextBoxValue] = useState(``),
        [categoryArray, setCategoryArray] = useState([]),
        [selectedBrandCategoryId, setSelectedBrandCategoryId] = useState(productDetails?.brandcategory || ``),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [productName, setProductName] = useState(productDetails?.name || ``),
        [removedImageIds, setRemovedImageIds] = useState([]),

        [brandTextBoxValue, setBrandTextBoxValue] = useState(``),
        [isProductBrandAutoComplete, setIsProductBrandAutoComplete] = useState(false),
        [productBrandArray, setProductBrandArray] = useState([]),
        [productBrandValues, setProductBrandValue] = useState([]),
        [selectedProductBrandId, setSelectedProductBrandCategoryId] = useState(productDetails?.productbrand || ``),
        [isProductBrandError, SetProductBrandErrorValue] = useState(false),
        [isProductBrandErrorCheck, setProductBrandErrorCheckValue] = useState(false),

        [flavourTextBoxValue, setFlavourTextBoxValue] = useState(``),
        [isProductFlavourAutoComplete, setIsProductFlavourAutoComplete] = useState(false),
        [productFlavourArray, setProductFlavourArray] = useState([]),
        [productFlavourVariantValues, setproductFlavourVariantValues] = useState([]),
        [selectedProductFlavourId, setSelectedProductFlavourId] = useState(productDetails?.product_flavour || ``),       
        [isProductFlavourError, SetProductFlavourErrorValue] = useState(false),
        [isProductFlavourErrorCheck, setProductFlavourErrorCheckValue] = useState(false),

        [selectedDimesnion, setSelectedDimension] = useState(productDetails?.product_packaging?.[0]?.dimensiontype || getDefaultDimension() || ``),
        [selectedVolume, setSelectedVolume] = useState(productDetails?.product_packaging?.[0]?.volumetype || getDefaultVolume() || ``),
        [selectedWeight, setSelectedWeight] = useState(productDetails?.product_packaging?.[0]?.weighttype || getDefaultWeight() || ``),
        [productWidth, setProductWidth] = useState(productDetails?.product_packaging?.[0]?.pro_width || ``),
        [productHeight, setProductHeight] = useState(productDetails?.product_packaging?.[0]?.pro_height || ``),
        [productDepth, setProductDepth] = useState(productDetails?.product_packaging?.[0]?.pro_depth || ``),
        [productVolume, setProductVolume] = useState(productDetails?.product_packaging?.[0]?.pro_volume || ``),
        [productWeight, setProductWeight] = useState(productDetails?.product_packaging?.[0]?.pro_weight || ``),
        [isProductNameHasError, setIsProductNameHasError] = useState(false),
        [productNameErrorMessage, setProductNameErrorMessage] = useState(``),
        [isItemCodeHasError, setIsItemCodeHasError] = useState(false),
        [itemCodeErrorMessage, setItemCodeErrorMessage] = useState(``),
        [userSettings, setUserSettings] = useState(props?.userSettings || {}),
        [container_loadability_20_ft, setcontainer_loadability_20_ft] = useState(productDetails?.product_case_package?.[0]?.container_loadability_20_ft || ``),
        [container_loadability_40_ft, setcontainer_loadability_40_ft] = useState(productDetails?.product_case_package?.[0]?.container_loadability_40_ft || ``),
        [container_loadability_40_ft_loose, setcontainer_loadability_40_ftloose] = useState(productDetails?.product_case_package?.[0]?.container_load_40_ft_loose || ``),
        [container_loadability_20_ft_loose, setcontainer_loadability_20_ft_loose] = useState(productDetails?.product_case_package?.[0]?.container_load_20_ft_loose || ``),
        [full_truck_load, setfull_truck_load] = useState(productDetails?.product_case_package?.[0]?.full_truck_load || ``),
        [productCasePalletequan, setProductCasePalletequan] = useState(productDetails?.product_case_package?.[0]?.case_pallete_quantity || ``),
        [propercase, setPropercase] = useState(parseFloat(productDetails?.product_price?.[0]?.p_p_case) || ``);
    const dispatch = useDispatch(), navigate = useNavigate();

    useEffect(() => {

        let productDetails = props?.productDetails || {};
        setProductDetails(productDetails);
        setProductName(productDetails?.name);
        setPriceValue(parseFloat(productDetails?.product_price?.[0]?.s_price_from));
        setItemValue(parseFloat(productDetails?.product_price?.[0]?.s_price_to));
        setSelectedPriceCurrency(productDetails?.product_price?.[0]?.s_price_currency || getDefaultCurrency() || ``);
        setMsrpValue(parseFloat(productDetails?.product_price?.[0]?.m_price) || ``);
        setSelectedMSRPType(productDetails?.product_price?.[0]?.m_price_currency || getDefaultCurrency() || ``);
        setSelectedBarcodeType(productDetails?.barcodetype || getDefaultBarcodeType() || ``);
        setSelectedMEASType(productDetails?.ps_meas || getDefaultPackVolumeWeight() || ``);
        setBarcodeValue(productDetails?.barcode || ``);
        setHsCodeValue(productDetails?.hs_code || ``);
        setItemCodeValue(productDetails?.sku_id || ``);
        // setSelectedCountry(productDetails?.country || getDefaultCountry() || ``);
        // setSelectedCountry(productDetails?.country || ``);
        setSelectedCountry(productDetails?.country || "none");
        setPackageSizeFirstValue(parseFloat(productDetails?.ps_unit) || ``);
        setPackageSizeSecondValue(parseFloat(productDetails?.ps_quantity) || ``);
        setPackageSizeThirdValue(parseFloat(productDetails?.ps_meas_value) || ``);
        setSelectedBrandCategoryId(productDetails?.brandcategory || ``);
        setSelectedProductBrandCategoryId(productDetails?.productbrand || ``);
        setProductWidth(productDetails?.product_packaging?.[0]?.pro_width || ``);
        setProductHeight(productDetails?.product_packaging?.[0]?.pro_height || ``);
        setProductDepth(productDetails?.product_packaging?.[0]?.pro_depth || ``);
        setProductVolume(productDetails?.product_packaging?.[0]?.pro_volume || ``);
        setProductWeight(productDetails?.product_packaging?.[0]?.pro_weight || ``);
        setSelectedDimension(productDetails?.product_packaging?.[0]?.dimensiontype || getDefaultDimension() || ``);
        setSelectedVolume(productDetails?.product_packaging?.[0]?.volumetype || getDefaultVolume() || ``);
        setSelectedWeight(productDetails?.product_packaging?.[0]?.weighttype || getDefaultWeight() || ``);
        setcontainer_loadability_20_ft(productDetails?.product_case_package?.[0]?.container_loadability_20_ft || ``);
        setcontainer_loadability_40_ft(productDetails?.product_case_package?.[0]?.container_loadability_40_ft || ``);
        setcontainer_loadability_40_ftloose(productDetails?.product_case_package?.[0]?.container_load_40_ft_loose || ``);
        setcontainer_loadability_20_ft_loose(productDetails?.product_case_package?.[0]?.container_load_20_ft_loose || ``);
        setfull_truck_load(productDetails?.product_case_package?.[0]?.full_truck_load || ``);
        setProductCasePalletequan(productDetails?.product_case_package?.[0]?.case_pallete_quantity || ``);
        setPropercase(parseInt(productDetails?.product_price?.[0]?.p_p_case) || ``);

        if (productDetails?.brandcategoryname) {
            let tempArray = [{
                "name": productDetails?.brandcategoryname
            }]

            setCategoryValue(tempArray);
        }

        if (productDetails?.productbrandname) {
            let tempArray = [{
                "name": productDetails?.productbrandname
            }]

            setProductBrandValue(tempArray);
        }

        if (productDetails?.productflavourname) {
            let tempArray = [{
                "name": productDetails?.productflavourname
            }]

            setproductFlavourVariantValues(tempArray);
        }



        if (productDetails?.product_usps && productDetails?.product_usps.length > 0) {
            let tempArray = [];
            productDetails?.product_usps.map((usps) => {
                tempArray.push({
                    "name": usps?.name
                })
            })

            setUspsValuesValue(tempArray)
        }

        if (productDetails?.product_img && productDetails?.product_img.length > 0) {
            let tempArray = [];
            productDetails?.product_img.map((img, index) => {
                tempArray.push({
                    "productimg_id": img[`productimg_id`],
                    "image": img[`image`],
                    "name": img[`name`],
                })

                if (img[`default`]) {
                    setPrimaryImageValue(index);
                }
            })
            setUploadImagesValue(tempArray);
        }

    }, [props?.productDetails]);

    // useEffect(() => {
    //     window.addEventListener("mousedown", (event) => {
    //         if (isAutoCompleteOpen || isProductBrandAutoComplete) {
    //             if ([`category`, `autocomplete`, `productBrand`].indexOf(event?.target?.id) === -1) {
    //                 if (isAutoCompleteOpen) {
    //                     setTimeout(() => {
    //                         setAutoCompleteFlag(false);
    //                     }, 150);

    //                 }
    //                 if (isProductBrandAutoComplete) {
    //                     setTimeout(() => {
    //                         setIsProductBrandAutoComplete(false);
    //                     }, 150);
    //                 }

    //             } else {
    //                 window.removeEventListener("mousemove", {}, true);
    //             }
    //         } else if (!(isAutoCompleteOpen && isProductBrandAutoComplete)) {
    //             window.removeEventListener("mousemove", {}, true);
    //         }
    //     });
    //     return () => {
    //         window.removeEventListener("mousemove", {}, true);
    //     }
    // }, [isAutoCompleteOpen, isProductBrandAutoComplete])


    useEffect(() => {
        setUserSettings(props?.userSettings);
    }, [props?.userSettings])

    const onPrimaryImageChange = (event) => {
        setPrimaryImageValue(event.target.value);
    };


    useEffect(() => {
        setBarcodeList(props?.barcodeList || []);
    }, [props?.barcodeList])

    useEffect(() => {
        setMEASList(props?.MEASList || []);
    }, [props?.MEASList]);

    useEffect(() => {
        setCountryList(props?.countryList || []);
    }, [props?.countryList]);

    useEffect(() => {
        setCurrencyList(props?.currencyList || []);
    }, [props?.currencyList]);

    useEffect(() => {
        setDimensionList(props?.dimensionList || []);
    }, [props?.dimensionList]);

    useEffect(() => {
        setvolumeList(props?.volumeList || []);
    }, [props?.volumeList]);

    useEffect(() => {
        setWeightList(props?.weightList || []);
    }, [props?.weightList])

    useEffect(() => {
        // setUSPsErrorValue(uspsValues.length > 0 ? false : true);
        if (uspsChange) uspsChange(uspsValues);
    }, [uspsValues]);

    useEffect(() => {
        // SetCategoryErrorValue(categoryValues.length > 0 ? false : true);
        if (categoryChange) categoryChange(categoryValues);
    }, [categoryValues])

    useEffect(() => {
        // SetProductBrandErrorValue(productBrandValues?.length > 0 ? false : true);
        if (brandChange) brandChange(productBrandValues);
    }, [productBrandValues])

    useEffect(() => {
        // SetProductBrandErrorValue(productBrandValues?.length > 0 ? false : true);
        if (flavourChange) flavourChange(productFlavourVariantValues);
    }, [productFlavourVariantValues])
    

    useEffect(() => {
        if (uploadImagesChange) uploadImagesChange(uploadImages);
    }, [uploadImages])

    const onUSPsClick = (event) => {
        if (event.key === "Enter") {
            setUspsValuesValue(prevState => [...prevState, { name: event.target.value }]);
            // setUspsValuesValue([...uspsValues, event.target.value]);
            document.getElementById(`usps`).value = ``;
            event.preventDefault();
        }
    }

    const removeChip = (index, data, setCallback, type) => {
        
        let tempArray = data;
        // console.log('removeChip',data)
        tempArray = tempArray.filter((data, i) => {
            return i !== index;
        })

        setCallback(tempArray);

        if (type === `category` && tempArray.length === 0) {
            SetCategoryErrorValue(true);
        } else if (type === `brand` && tempArray.length === 0) {
            SetProductBrandErrorValue(true);
        }
        else if (type === `flavour` && tempArray.length === 0) {
            // SetProductFlavourErrorValue(true);
            setSelectedProductFlavourId(``);
        }
    }

    const onCategoryClick = (event) => {
        if (event.key === "Enter") {
            // setCategoryValue([...categoryValues, event.target.value]);
            // document.getElementById(`category`).value = ``;
            // event.preventDefault();
        }
    }

    const onImagesSave = (images) => {
        setUploadImagesValue(images);
        setImageUploadSliderShow(false);
        if (images && images.length > 0) {
            setPrimaryImageValue(0)
        }
    }


    const onSubmit = (data) => {

        let tempCountryList = [];
        if (!productName) {
            setIsProductNameHasError(true);
            setProductNameErrorMessage(`Product Name is required`);
            return;
        }

        if (categoryValues && categoryValues.length === 0) {
            SetCategoryErrorValue(true);
            document.getElementById("category").scrollIntoView({
                behavior: 'smooth'
            });
            return;
        }

        if (productBrandValues && productBrandValues.length === 0) {
            SetProductBrandErrorValue(true);
            document.getElementById("productBrand").scrollIntoView({
                behavior: 'smooth'
            });
            return;
        }

        // if (productFlavourVariantValues && productFlavourVariantValues.length === 0) {
        //     SetProductFlavourErrorValue(true);
        //     document.getElementById("productFlavourVariant").scrollIntoView({
        //         behavior: 'smooth'
        //     });
        //     return;
        // }

        if (!itemCodeValue) {
            setIsItemCodeHasError(true);
            setItemCodeErrorMessage(`Seller SKU ID is required`);
            document.getElementById("Itemcode").scrollIntoView({
                behavior: 'smooth'
            });
            return;
        }
        let tempArray = [];
        uspsValues && uspsValues.length > 0 &&
            uspsValues.map((data) => {
                tempArray.push(data[`name`]);
            })

        let tempImageArray = [];
        if (data[`price`] && data[`item`]) {

            if (data[`price`] >= data[`item`]) {
                setAlternateMessageShow(true);
                setAlertMessageType("information");
                setAlertMessage(`Maximum price should be greater than minimum price`);
                return;
            }
        }

        let priceObject = {
            "s_price_currency_id": userSettings?.pro_priceitem ? selectedPriceCurrency : ``,
            "s_price_from": userSettings?.pro_priceitem ? data[`price`] || priceValue : priceValue || ``,
            "s_price_to": userSettings?.pro_priceitem ? data[`item`] || itemValue : itemValue || ``,
            "m_price_currency_id": userSettings?.pro_msrp ? selectedMSRPType : ``,
            "m_price": userSettings?.pro_msrp ? data[`MSRP`] || msrpValue : msrpValue || ``,
            "p_p_case": propercase?  propercase : propercase || ``,
        }

        if (uploadImages && uploadImages.length > 0) {
            uploadImages.map((image) => {
                if (image[`file`]) {
                    tempImageArray.push(image[`file`]);
                }
            })
        }

        // if (uspsValues && uspsValues.length === 0) {
        //     setUSPsErrorValue(true);
        //     return;
        // }

        // if (uploadImages.length === 0) {
        //     setAlternateMessageShow(true);
        //     setAlertMessageType("information");
        //     setAlertMessage(`One product image is required`);
        //     // alert(`One product image is required`);
        //     return;
        // }

        // if (primaryImageValue === ``) {
        //     setAlternateMessageShow(true);
        //     setAlertMessageType("information");
        //     setAlertMessage(`Please select a default image`);
        //     // alert(`Please select a default image`);
        //     setIsLoading(false);
        //     return;
        // }

        if (userSettings?.pro_msrp && userSettings?.pro_priceitem) {
            if (priceObject[`m_price`] && priceObject[`s_price_from`] && (priceObject[`m_price`] < priceObject[`s_price_from`] || priceObject[`m_price`] < priceObject[`s_price_to`])) {
                // alert(`MSRP price must be greater than price or item`);
                setAlternateMessageShow(true);
                setAlertMessageType("information");
                setAlertMessage(`Retail Price has to be greater than maximum price range`);
                return;
            }

            if (priceObject[`m_price`] && priceObject[`s_price_from`] && (priceObject[`m_price`] == priceObject[`s_price_from`] || priceObject[`m_price`] == priceObject[`s_price_to`])) {
                setAlternateMessageShow(true);
                setAlertMessageType("information");
                setAlertMessage(`Retail Price cannot to be equal maximum price range`);
                return;
            }
        }

        if (productDetails?.products_sold_countries && productDetails?.products_sold_countries.length > 0) {
            productDetails?.products_sold_countries.map((soldCountry) => {
                tempCountryList.push(soldCountry?.country);
            })
        }

        const pageDimension = {
            "dimensiontype_id": userSettings?.pro_dimensions && selectedDimesnion ? selectedDimesnion : '',
            "pro_width": userSettings?.pro_dimensions && productWidth ? productWidth : '',
            "pro_height": userSettings?.pro_dimensions && productHeight ? productHeight : '',
            "pro_depth": userSettings?.pro_dimensions && productDepth ? productDepth : '',
            "volumetype_id": userSettings?.pro_volume && selectedVolume ? selectedVolume : '',
            "pro_volume": userSettings?.pro_volume && productVolume ? productVolume : '',
            "weighttype_id": userSettings?.pro_weight && selectedWeight ? selectedWeight : '',
            "pro_weight": userSettings?.pro_weight && productWeight ? productWeight : '',
        }

        const containerList = {
            "container_loadability_20_ft": container_loadability_20_ft,
            "container_loadability_40_ft": container_loadability_40_ft,
            "container_load_40_ft_loose": container_loadability_40_ft_loose,
            "container_load_20_ft_loose" : container_loadability_20_ft_loose,
            "full_truck_load":full_truck_load,
        }

        const pageCaseDimension = {
            "case_pallete_quantity": productCasePalletequan ? productCasePalletequan : ''
        }
        // console.log(selectedCountry,'selectedCountry')
        const productObject = {
            'productId': productDetails?.products_id,
            'name': productName,
            'sku_id': itemCodeValue,
            'barcodetype': userSettings?.pro_barcode ? selectedBarcodeType : ``,
            'barcode': userSettings?.pro_barcode ? data[`barcode`] || barcodeValue : barcodeValue || ``,
            'hs_code': userSettings?.pro_hs_code ? data[`hs_code`] || hsCodeValue : hsCodeValue || ``,
            "image_set_primary": parseInt(primaryImageValue) + 1,
            "galaryImages": tempImageArray,
            "country": selectedCountry,
            "usps": userSettings?.pro_usps && tempArray.length > 0 ? tempArray.join() : '',
            "brandcategory": selectedBrandCategoryId,
            "price": priceObject,
            "ps_unit": userSettings?.pro_pack_size ? data[`packageSize1`] || packageSizeFirstValue : packageSizeFirstValue || ``,
            "ps_quantity": userSettings?.pro_pack_size ? data[`packageSize2`] || packageSizeSecondValue : packageSizeSecondValue || ``,
            "ps_meas": userSettings?.pro_pack_size ? selectedMEASType : ``,
            "ps_meas_value": userSettings?.pro_pack_size ? data[`packageSize3`] || packageSizeThirdValue : packageSizeThirdValue || ``,
            "galleryDelete": removedImageIds.join(),
            "productbrand": selectedProductBrandId,
            "product_flavour": selectedProductFlavourId,
            "pageDimension": pageDimension,
            "pageCaseDimension": pageCaseDimension,
            "countriesSoldList": tempCountryList.join(),
            "containerlist": containerList,
            "productIngredients" : productDetails?.products_ingredients?.[0]?.name || ``
        }
        // console.log(productObject,'productObject')

        setIsLoading(true);
        productUpdate(productObject)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response?.data?.message);
                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(true)
                        }
                    }, 300);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");

                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(false)
                        }
                    }, 300);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setTimeout(() => {
                    if (onSectionApiCallSuccess) {
                        onSectionApiCallSuccess(false)
                    }
                }, 300);
                console.log("We have error at productUpdate", error);
            })
    }

    const onCategorySearch = debounce(() => brandCategoryAutoComplete(), 500)

    const brandCategoryAutoComplete = () => {

        let categoryName;
        if (document.getElementById(`category`)) {
            categoryName = document.getElementById(`category`).value;
        }

        if (!categoryName) {
            setAutoCompleteFlag(false);
            return;
        }

        setCategoryTextBoxValue(categoryName);

        getBrandCategoryList(productDetails?.brand, categoryTextBoxValue)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setAutoCompleteFlag(true);
                    setCategoryArray(response.data.data)
                } else {
                    setAutoCompleteFlag(false);
                }
            })
            .catch((error) => {
                setAutoCompleteFlag(false);
                console.log("we have an error at brandCategoryAutoComplete", error);
            })
    }

    const onCategorySelectAutoComplete = (category, flag) => {
        setCategoryValue([...categoryValues, category[`name`]]);
        setSelectedBrandCategoryId(category[`brandcat_id`]);
        setAutoCompleteFlag(flag);
        document.getElementById(`category`).value = ``;
        SetCategoryErrorValue(false);
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const handleChange = (event) => {
        var targetvalue = event.target.value;
        // if(event.target.value!="none")
        // {
        //     targetvalue= event.target.value
           
        // }
        // else{
        //     targetvalue= "";
        // }
        
        if (countryChange) countryChange(targetvalue);
        setSelectedCountry(targetvalue);
    };

    const flagemojiToPNG = (flag, code) => {
        var countryCode = Array.from(flag, (codeUnit) => codeUnit.codePointAt()).map(char => String.fromCharCode(char - 127397).toLowerCase()).join('')
        return `https://flagcdn.com/24x18/${countryCode}.png`;
    }

    const removeImage = (index, imageId) => {
        let tempArray = uploadImages;

        tempArray = tempArray.filter((file, i) => {
            return i !== index;
        })
        if (imageId) {
            setRemovedImageIds([...removedImageIds, imageId]);
        }

        if (tempArray.length == 0) {
            setPrimaryImageValue(``);
        } else {
            setPrimaryImageValue(0);
        }
        setUploadImagesValue(tempArray);
    }

    const onProductBrandSearch = debounce(() => productBrandAutoComplete(), 500)
    const onProductFlavourSearch = debounce(() => productFlavourAutoComplete(), 500)
    

    const productBrandAutoComplete = () => {

        let productBrandName;
        if (document.getElementById(`productBrand`)) {
            productBrandName = document.getElementById(`productBrand`).value;
        }

        if (!productBrandName) {
            setIsProductBrandAutoComplete(false);
            return;
        }
        setBrandTextBoxValue(productBrandName);
        getProductBrandList(productDetails?.brand, productBrandName)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setIsProductBrandAutoComplete(true);
                    setProductBrandArray(response.data.data)
                } else {
                    setIsProductBrandAutoComplete(false);
                }
            })
            .catch((error) => {
                setIsProductBrandAutoComplete(false);
                console.log("we have an error at productBrandAutoComplete", error);
            })
    }

    
    const productFlavourAutoComplete = () => {

        let productFlavourName;
        if (document.getElementById(`productFlavourVariant`)) {
            productFlavourName = document.getElementById(`productFlavourVariant`).value;
        }

        if (!productFlavourName) {
            setIsProductFlavourAutoComplete(false);
            return;
        }
        setFlavourTextBoxValue(productFlavourName);
        getProductFlavourList(productFlavourName)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setIsProductFlavourAutoComplete(true);
                    setProductFlavourArray(response.data.data)
                } else {
                    setIsProductFlavourAutoComplete(false);
                }
            })
            .catch((error) => {
                setIsProductFlavourAutoComplete(false);
                console.log("we have an error at setIsProductFlavourAutoComplete", error);
            })
    }

    const onProductBrandSelectAutoComplete = (productBrand, flag) => {
        setProductBrandValue([...productBrandValues, productBrand[`name`]]);
        setSelectedProductBrandCategoryId(productBrand[`product_brand_id`]);
        setIsProductBrandAutoComplete(flag);
        SetProductBrandErrorValue(flag);
        document.getElementById(`productBrand`).value = ``;
        SetProductBrandErrorValue(false);
    }

    const onProductFlavourSelectAutoComplete = (productFlavour, flag) => {
        // console.log('flavourcomplete',productBrand,flag)
        setproductFlavourVariantValues([...productFlavourVariantValues, productFlavour[`name`]]);
        setSelectedProductFlavourId(productFlavour[`flavour_id`]);
        setIsProductFlavourAutoComplete(flag);
        // SetProductFlavourErrorValue(flag);
        document.getElementById(`productFlavourVariant`).value = ``;
        // SetProductFlavourErrorValue(false);
    }

    const onProductNameChange = (value) => {

        if (value === ``) {
            setIsProductNameHasError(true);
            setProductNameErrorMessage(`Product Name is required`);
        } else if (value.length < 3) {
            setIsProductNameHasError(true);
            setProductNameErrorMessage(`Too short - should be at least 3 characters`);
        } else if (value.length > 70) {
            setIsProductNameHasError(true);
            setProductNameErrorMessage(`Maximum allowed characters is 70`);
        } else {
            setIsProductNameHasError(false);
            setProductNameErrorMessage(``);
        }
        setProductName(value);
    }

    const onItemCodeChange = (value) => {
        if (value === ``) {
            setIsItemCodeHasError(true);
            setItemCodeErrorMessage(`Seller SKU ID is required`);
        } else if (value.length < 3) {
            setIsItemCodeHasError(true);
            setItemCodeErrorMessage(`Too short - should be at least 3 characters`);
        } else if (value.length > 100) {
            setIsItemCodeHasError(true);
            setItemCodeErrorMessage(`Maximum allowed characters is 100`);
        } else {
            setIsItemCodeHasError(false);
            setItemCodeErrorMessage(``);
        }

        if (itemCodeChange) itemCodeChange(value);
        setItemCodeValue(value);
    }

    return (
        <Fragment>
            <form className={`product-preview-wrapper form-input-control`} style={{ position: "relative" }} onSubmit={handleSubmit(onSubmit)}>
                <div className={`heading`}>
                    Product Preview
                </div>
                <Box className={`form-control margin-bottom-28`}>
                    {isLoading && <Loader />}

                    {isAlertMessageShow &&
                        <AlertMessage
                            message={alertMessage}
                            alertType={alertMessageType}
                            isAlertShow={isAlertMessageShow}
                            onClose={onAlertClose}
                        />
                    }
                    <Typography className={`label`}>Product Name<span className="asterisks">*</span></Typography>
                    <TextField
                        type={"text"}
                        id="productName"
                        name="productName"
                        className={`input-wrap`}
                        placeholder={`Enter Product Name`}
                        margin="dense"
                        spellCheck={false}
                        error={isProductNameHasError ? true : false}
                        value={productName}
                        inputProps={{
                            onInput: (e) => {
                                onProductNameChange(e.target.value);
                            },
                            maxLength: 70
                        }}
                    />
                    {
                        productNameErrorMessage &&
                        <p className={`field-err-msg`}>{productNameErrorMessage}</p>
                    }

                </Box>
                <Box className={`form-control margin-bottom-28`}>
                    <div className={`itemcode-wrap`}>
                        <Typography className={`label`}>Seller SKU ID<span className="asterisks">*</span></Typography>
                        <TextField
                            type={"text"}
                            id="Itemcode"
                            name="Itemcode"
                            className={`input-wrap itemcode-input`}
                            placeholder={`Enter Itemcode`}
                            margin="dense"
                            spellCheck={false}
                            error={isItemCodeHasError ? true : false}
                            value={itemCodeValue}
                            inputProps={{
                                onInput: (e) => {
                                    onItemCodeChange(e.target.value);
                                },
                                maxLength: 100
                            }}
                        />
                        {
                            itemCodeErrorMessage &&
                            <p className={`field-err-msg`}>{itemCodeErrorMessage}</p>
                        }

                    </div>
                </Box>
                <Box className={`form-control margin-bottom-28`}>
                    <Typography className={`label`}>Images</Typography>

                    <div className={`add-image-wrapper div-scroll-wrap scroll-bar-theme`}>
                        {
                            uploadImages && uploadImages.length > 0 &&
                            uploadImages.map((image, index) => {
                                return (
                                    <div key={index} className={`added-image-wrap`}>
                                        <div className={`default-radio`}>
                                            <Radio
                                                checked={parseInt(primaryImageValue) === index}
                                                onChange={onPrimaryImageChange}
                                                value={index}
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': index }}
                                            />
                                            {
                                                parseInt(primaryImageValue) === index &&
                                                <span className={`primary`}>Primary</span>
                                            }
                                        </div>
                                        <div className={`add-image image`}>
                                            <img src={image[`image`]} />
                                        </div>
                                        <div className={`remove`} onClick={() => { removeImage(index, image?.productimg_id || ``) }}><CloseIcon /></div>
                                    </div>
                                )
                            })
                        }

                        {
                            uploadImages && uploadImages.length !== 5 &&
                            // <div className={`add-image-wrap`} onClick={() => { document.getElementById(`imageupload`).click() }}>
                            <div className={`add-image-wrap `} onClick={() => { setImageUploadSliderShow(true); }}>
                                + Add Image
                                <p className={`img-info`}>Image must be at least 600 x 600 px.</p>
                            </div>
                        }


                        {/* <input className={`visibility-hidden`} type='file' name='imageupload' id='imageupload' onChange={(event) => { onImageUpload(event.target.files) }} accept="image/x-png,image/gif,image/jpeg" multiple /> */}
                    </div>
                </Box>

                {
                    userSettings?.pro_usps &&
                    <Box className={`form-control margin-bottom-28`} style={{ marginTop: "45px" }}>
                        <Typography className={`label`}>Unique selling propositions (USPs)</Typography>
                        <TextField
                            type={"text"}
                            id="usps"
                            name="usps"
                            autoComplete="off"
                            className={`input-wrap`}
                            placeholder={`Enter USPs Name`}
                            margin="dense"
                            spellCheck={false}
                            disabled={uspsValues.length === 3 ? true : false}
                            // error={isUSPsError}
                            inputProps={{
                                onKeyPress: (e) => {
                                    if (e.target.value.trim()) onUSPsClick(e);
                                },
                                onChange: (e) => {
                                    if (e.target.value) setUSPsErrorCheckValue(true);
                                    setUSPsErrorValue(uspsValues.length > 0 ? false : true);
                                },
                                maxLength: 25
                            }}
                        />

                        <div className={`information-lables`}>
                            <div className={`count-info`}>
                                {
                                    uspsValues.length === 3
                                        ?
                                        `USPs added`
                                        :
                                        `Add up to ${3 - uspsValues.length}`}
                            </div>
                            <div className={`link-info skip-btn`}>
                                {/* What makes a good USP? */}
                            </div>
                        </div>

                        <div className={`chips-list`}>
                            {
                                uspsValues && uspsValues.length > 0 &&
                                uspsValues.map((usps, index) => {
                                    return (
                                        <div key={index} className={`chips`}>
                                            <div>
                                                {usps[`name`]}
                                            </div>

                                            {/* <div data-title={usps[`name`]}>
                                    <div className={`ellipsis`}>{usps[`name`]}</div>
                                </div> */}

                                            <div className={`remove`} onClick={() => { removeChip(index, uspsValues, setUspsValuesValue, `usps`) }}>
                                                <CloseIcon />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* {
                            isUSPsError &&
                            <p className={`field-err-msg`}>{`USPs is required`}</p>
                        } */}

                    </Box>
                }

                <Box className={`form-control margin-bottom-28 category-input-wrap`}>
                    <Typography className={`label`}>Select category<span className="asterisks">*</span></Typography>
                    <TextField
                        type={"text"}
                        id="category"
                        name="category"
                        className={`input-wrap category-input ${isAutoCompleteOpen ? 'open-autocomplete' : ''}`}
                        placeholder={`Enter Category Name`}
                        margin="dense"
                        autoComplete="off"
                        spellCheck={false}
                        disabled={categoryValues.length === 1 ? true : false}
                        error={isCategoryError}
                        inputProps={{
                            onInput: (e) => {
                                if (e.target.value === ``) {
                                    setAutoCompleteFlag(false);
                                } else {
                                    setCategoryTextBoxValue(e.target.value)
                                    onCategoryClick(e);
                                    if (e.target.value.trim()) onCategorySearch();
                                }
                            },
                            maxLength: 40,
                            onChange: (e) => {
                                if (e.target.value) setCategoryErrorCheckValue(true)
                                SetCategoryErrorValue(categoryValues.length > 0 ? false : true)
                            }
                        }}
                    />

                    {
                        isAutoCompleteOpen &&
                        <AutoComplete data={categoryArray} searchValue={categoryTextBoxValue} productDetails={productDetails} onCategorySelect={onCategorySelectAutoComplete} />
                    }
                    <div className={`information-lables`}>
                        <div className={`count-info`}>
                            {
                                categoryValues.length === 1
                                    ?
                                    `Category added`
                                    :
                                    `Add up to ${1 - categoryValues.length}`}
                        </div>
                    </div>

                    <div className={`chips-list`}>
                        {
                            categoryValues && categoryValues.length > 0 &&
                            categoryValues.map((category, index) => {
                                return (
                                    <div key={index} className={`chips`}>
                                        <div>
                                            {category[`name`] || category}
                                        </div>

                                        <div className={`remove`} onClick={() => { removeChip(index, categoryValues, setCategoryValue, `category`) }}>
                                            <CloseIcon />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {
                        isCategoryError &&
                        <p className={`field-err-msg`}>{`Category is required`}</p>
                    }
                </Box>

                <Box className={`form-control margin-bottom-28 select-brand`}>
                    <Typography className={`label`}>Select brand<span className="asterisks">*</span></Typography>
                    <TextField
                        type={"text"}
                        id="productBrand"
                        name="productBrand"
                        autoComplete="off"
                        className={`input-wrap`}
                        placeholder={`Enter Brand Name`}
                        margin="dense"
                        spellCheck={false}
                        disabled={productBrandValues.length === 1 ? true : false}
                        error={isProductBrandError}
                        inputProps={{
                            onInput: (e) => {
                                if (e.target.value === ``) {
                                    setIsProductBrandAutoComplete(false);
                                } else {
                                    setBrandTextBoxValue(e.target.value)
                                    onCategoryClick(e);
                                    if (e.target.value.trim()) onProductBrandSearch();
                                }
                            },
                            maxLength: 40,
                            onChange: (e) => {
                                if (e.target.value) setProductBrandErrorCheckValue(true)
                                SetProductBrandErrorValue(productBrandArray.length > 0 ? false : true)
                            }
                        }}
                    />

                    {
                        isProductBrandAutoComplete &&
                        <AutoComplete
                            data={productBrandArray}
                            searchValue={brandTextBoxValue}
                            productDetails={productDetails}
                            onCategorySelect={onProductBrandSelectAutoComplete}
                            type={`productBrand`}
                        />
                    }
                    <div className={`information-lables`}>
                        <div className={`count-info`}>
                            {
                                productBrandValues.length === 1
                                    ?
                                    `Product Brand added`
                                    :
                                    `Add up to ${1 - productBrandValues.length}`}
                        </div>
                    </div>

                    <div className={`chips-list`}>
                        {
                            productBrandValues && productBrandValues.length > 0 &&
                            productBrandValues.map((category, index) => {
                                return (
                                    <div key={index} className={`chips`}>
                                        <div>
                                            {category[`name`] || category}
                                        </div>

                                        <div className={`remove`} onClick={() => { removeChip(index, productBrandValues, setProductBrandValue, `brand`) }}>
                                            <CloseIcon />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {
                        isProductBrandError &&
                        <p className={`field-err-msg`}>{`Product brand is required`}</p>
                    }
                </Box>
                
                {
                userSettings?.pro_flavour &&
                <Box className={`form-control margin-bottom-28 select-brand`}>
                    <Typography className={`label`}>Select Variant/Flavour</Typography>
                    <TextField
                        type={"text"}
                        id="productFlavourVariant"
                        name="productFlavourVariant"
                        autoComplete="off"
                        className={`input-wrap`}
                        placeholder={`Enter Variant/Flavour`}
                        margin="dense"
                        spellCheck={false}
                        disabled={productFlavourVariantValues.length === 1 ? true : false}
                        error={isProductFlavourError}
                        inputProps={{
                            onInput: (e) => {
                                if (e.target.value === ``) {
                                    setIsProductFlavourAutoComplete(false);
                                } else {
                                    setFlavourTextBoxValue(e.target.value)
                                    onCategoryClick(e);
                                    if (e.target.value.trim()) onProductFlavourSearch();
                                }
                            },
                            maxLength: 40,
                            onChange: (e) => {
                                if (e.target.value) setProductFlavourErrorCheckValue(true)
                                // SetProductFlavourErrorValue(productBrandArray.length > 0 ? false : true)
                            }
                        }}
                    />

                    {
                        isProductFlavourAutoComplete &&
                        <AutoComplete
                            data={productFlavourArray}
                            searchValue={flavourTextBoxValue}
                            productDetails={productDetails}
                            onCategorySelect={onProductFlavourSelectAutoComplete}
                            type={`productFlavour`}
                        />
                    }
                    <div className={`information-lables`}>
                        <div className={`count-info`}>
                            {
                                productFlavourVariantValues.length === 1
                                    ?
                                    `Product Variant/Flavour added`
                                    :
                                    `Add up to ${1 - productFlavourVariantValues.length}`}
                        </div>
                    </div>

                    <div className={`chips-list`}>
                        {
                            productFlavourVariantValues && productFlavourVariantValues.length > 0 &&
                            productFlavourVariantValues.map((category, index) => {
                                return (
                                    <div key={index} className={`chips`}>
                                        <div>
                                            {category[`name`] || category}
                                        </div>

                                        <div className={`remove`} onClick={() => { removeChip(index, productFlavourVariantValues, setproductFlavourVariantValues, `flavour`) }}>
                                            <CloseIcon />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {/* {
                        isProductFlavourError &&
                        <p className={`field-err-msg`}>{`Product Variant/Flavour is required`}</p>
                    } */}
                </Box>
                }
                {
                    userSettings?.pro_pack_size &&
                    <Box className={`form-control margin-bottom-28 packsize-wrapper`}>
                        <Typography className={`label`}>Case Configuration/Pack Size</Typography>

                        <div className={`dimension-wrap packsize-wrap custom-black-select-option for-three-field`}>

                            
                                {/* <Typography className={`label`}>label 3</Typography> */}
                                
                                    <div className="packsize-wrap-items">
                                        <select id="measlist-select" onChange={(event) => { if (measTypeChange) { measTypeChange(event.target.value) }; setSelectedMEASType(event.target.value) }} >
                                            {
                                                MEASList && MEASList.length > 0 &&
                                                MEASList.map((list, index) => {
                                                    return (
                                                        <option value={list[`pack_vol_wht_id`]} key={index} selected={selectedMEASType === list[`pack_vol_wht_id`] ? 'selected' : ''}>
                                                            {list[`name`]}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <TextField
                                            type={"text"}
                                            id="label-3"
                                            name="label-3"
                                            className={`input-wrap measlist-input`}
                                            placeholder={`Weight / Volume`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`packageSize3`)}
                                            error={errors.packageSize3 ? true : false}
                                            value={packageSizeThirdValue}
                                            inputProps={{
                                                onInput: (e) => {
                                                    if (packageWeightChange) packageWeightChange(e.target.value);
                                                    setPackageSizeThirdValue(e.target.value);
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.packageSize3?.message}</p>
                                    </div>
                                

                                    <div className={`inner-pack-wrap`}>
                                        {/* <Typography className={`label`}>label 1</Typography> */}
                                        <TextField
                                            type={"text"}
                                            id="label-1"
                                            name="label-1"
                                            className={`input-wrap`}
                                            placeholder={`Inner pack`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`packageSize1`)}
                                            error={errors.packageSize1 ? true : false}
                                            value={packageSizeFirstValue}
                                            inputProps={{
                                                onInput: (e) => {
                                                    if (innerPackChange) innerPackChange(e.target.value);
                                                    setPackageSizeFirstValue(e.target.value);
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.packageSize1?.message}</p>
                                    </div>

                                    <div className={`outer-pack-wrap`}>
                                        {/* <Typography className={`label`}>label 2</Typography> */}
                                        <TextField
                                            type={"text"}
                                            id="label-2"
                                            name="label-2"
                                            className={`input-wrap`}
                                            placeholder={`Outer pack`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`packageSize2`)}
                                            error={errors.packageSize2 ? true : false}
                                            value={packageSizeSecondValue}
                                            inputProps={{
                                                onInput: (e) => {
                                                    if (outerPackChange) outerPackChange(e.target.value);
                                                    setPackageSizeSecondValue(e.target.value);
                                                }
                                            }}
                                        />
                                        <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.packageSize2?.message}</p>
                                    </div>
                        </div>
                    </Box>
                }

                {
                    (userSettings?.pro_barcode || userSettings?.pro_hs_code) &&
                    <Box className={`form-control margin-bottom-28 barcode-itemcode-wrapper`}>
                        
                        {
                            userSettings?.pro_barcode &&
                            <div>
                            <Typography className={`label`}>Product barcode</Typography>
                            <div className={`barcode-wrap custom-black-select-option`}>

                                <div className={`barcode-dropdown`}>
                                    <select id="barcode-select" onChange={(event) => { if (selectedBarcodeChange) { selectedBarcodeChange(event.target.value) }; setSelectedBarcodeType(event.target.value) }}>
                                        {
                                            barcodeList && barcodeList.length > 0 &&
                                            barcodeList.map((list, index) => {
                                                return (
                                                    <option
                                                        value={list[`barcodetype_id`]}
                                                        key={index}
                                                        selected={selectedBarcodeType === list[`barcodetype_id`] ? 'selected' : ''}
                                                    >
                                                        {list[`name`]}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <TextField
                                        type={"text"}
                                        id="barcode"
                                        name="barcode"
                                        className={`input-wrap barcode-input`}
                                        placeholder={`Enter Barcode`}
                                        margin="dense"
                                        spellCheck={false}
                                        {...register(`barcode`)}
                                        error={errors.barcode ? true : false}
                                        value={barcodeValue}
                                        inputProps={{
                                            onInput: (e) => {
                                                if (barcodeChange) barcodeChange(e.target.value)
                                                setBarcodeValue(e.target.value)
                                            }
                                        }}
                                    />

                                </div>
                            </div>
                            <p className={`field-err-msg`}>{errors.barcode?.message}</p>
                           </div>
                        }
                        {
                            userSettings?.pro_hs_code &&
                        <div>
                            <Typography className={`label`}>HS code</Typography>
                            <div className={``}>
                                <div>
                                    <TextField
                                        type={"text"}
                                        id="hs_code"
                                        name="hs_code"
                                        className={`input-wrap barcode-input`}
                                        placeholder={`Enter HS Code`}
                                        margin="dense"
                                        spellCheck={false}
                                        {...register(`hs_code`)}
                                        error={errors.hs_code ? true : false}
                                        value={hsCodeValue}
                                        inputProps={{
                                            onInput: (e) => {
                                                if (hscodeChange) hscodeChange(e.target.value)
                                                setHsCodeValue(e.target.value)
                                            }
                                        }}
                                    />

                                </div>
                            </div>
                            <p className={`field-err-msg`}>{errors.hs_code?.message}</p>
                        </div>
                        }
                    </Box>
                }

                {
                    (userSettings?.pro_priceitem || userSettings?.pro_msrp) &&
                    <Box className={`form-control margin-bottom-28 price-msrp-wrapper`}>
                        {
                            userSettings?.pro_priceitem &&
                            <div>
                                <Typography className={`label information-icon`}>
                                    B2B Price Range
                                    <span className="custom-tt-2" tooltip="The wholesale price range to your B2B buyers"><InformationIcon /> </span>
                                </Typography>
                                <div className={`price-wrap custom-black-select-option for-two-field`}>
                                    <div>
                                        <select id="price-select" onChange={(event) => { if (priceCurrentChange) { priceCurrentChange(event.target.value) }; setSelectedPriceCurrency(event.target.value) }}>
                                            {
                                                currencyList && currencyList.length > 0 &&
                                                currencyList.map((list, index) => {
                                                    return (
                                                        <option
                                                            value={list[`currency_id`]}
                                                            key={index}
                                                            selected={selectedPriceCurrency === list[`currency_id`] ? 'selected' : ''}
                                                        >
                                                            {list[`currency`]}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <TextField
                                            type={"number"}
                                            id="price"
                                            name="price"
                                            className={`input-wrap price-input`}
                                            placeholder={`Min`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`price`)}
                                            error={errors.price ? true : false}
                                            value={priceValue}
                                            inputProps={{
                                                onInput: (e) => {
                                                    priceChange(e.target.value);
                                                    setPriceValue(e.target.value)
                                                },
                                                min: 0,
                                                step: "any",
                                            }}
                                        />

                                    </div>

                                    <div>
                                        <TextField
                                            type={"number"}
                                            id="item"
                                            name="item"
                                            className={`input-wrap item-input`}
                                            placeholder={`Max`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`item`)}
                                            error={errors.item ? true : false}
                                            value={itemValue}
                                            inputProps={{
                                                onInput: (e) => {
                                                    if (itemChange) itemChange(e.target.value);
                                                    setItemValue(e.target.value)
                                                },
                                                min: 0,
                                                step: "any",
                                            }}
                                        />

                                    </div>

                                </div>
                                <div className={`field-err-msg w-100`}>
                                    <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.price?.message}</p>
                                    <p className={`field-err-msg position-relative `}>{errors.item?.message}</p>
                                </div>
                            </div>

                        }

                        {
                            userSettings?.pro_msrp &&
                            <div>
                                <Typography className={`label information-icon`}>
                                    Retail Price
                                    <span className="custom-tt-2" tooltip="Suggested maximum selling price to end-consumers"><InformationIcon /> </span>
                                </Typography>
                                <div className={`MSRP-wrap custom-black-select-option`}>
                                    <div>
                                        <select id="MSRP-select" onChange={(event) => { if (msrpTypeChange) { msrpTypeChange(event.target.value) }; setSelectedMSRPType(event.target.value) }}>
                                            {
                                                currencyList && currencyList.length > 0 &&
                                                currencyList.map((list, index) => {
                                                    return (
                                                        <option
                                                            value={list[`currency_id`]}
                                                            key={index}
                                                            selected={selectedMSRPType === list[`currency_id`] ? 'selected' : ''}
                                                        >
                                                            {list[`currency`]}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <TextField
                                            type={"text"}
                                            id="MSRP"
                                            name="MSRP"
                                            className={`input-wrap MSRP-input`}
                                            placeholder={`Enter Retail Price`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`MSRP`)}
                                            error={errors.MSRP ? true : false}
                                            value={msrpValue}
                                            inputProps={{
                                                onInput: (e) => {
                                                    if (msrpChange) msrpChange(e.target.value);
                                                    setMsrpValue(e.target.value)
                                                },
                                                min: 0,
                                                step: "any",
                                            }}
                                        />

                                    </div>
                                </div>
                                <p className={`field-err-msg`}>{errors.MSRP?.message}</p>
                            </div>
                        }
                    </Box>
                }

                {
                    userSettings?.pro_dimensions &&
                    <Box className={`form-control margin-bottom-28 product-dimension-wrapper`}>
                        <Typography className={`label information-icon`}>
                            Product dimensions
                            <span className="custom-tt-2" tooltip="The dimension of your product as it would be displayed on store shelves"><InformationIcon /> </span>
                        </Typography>
                        <div className={`dimension-wrap custom-black-select-option for-three-field`}>
                            <div>
                                <select id="dimension-select" onChange={(event) => { if (dimensionsChange) { dimensionsChange(event.target.value) }; setSelectedDimension(event.target.value) }}>
                                    {
                                        dimensionList && dimensionList.length > 0 &&
                                        dimensionList.map((list, index) => {
                                            return (
                                                <option
                                                    value={list[`dimension_id`]}
                                                    key={index}
                                                    selected={selectedDimesnion === list[`dimension_id`] ? 'selected' : ''}
                                                >
                                                    {list[`name`]}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="pro-dimension">
                                <TextField
                                    type={"text"}
                                    id="width"
                                    name="width"
                                    className={`input-wrap width-input`}
                                    placeholder={`Width`}
                                    margin="dense"
                                    spellCheck={false}
                                    {...register(`width`)}
                                    error={errors.width ? true : false}
                                    value={productWidth}
                                    inputProps={{
                                        onInput: (e) => {
                                            if (widthChange) widthChange(e.target.value);
                                            setProductWidth(e.target.value)
                                        }
                                    }}
                                />
                                <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.width?.message}</p>
                            </div>

                            <div>
                                <TextField
                                    type={"text"}
                                    id="height"
                                    name="height"
                                    className={`input-wrap height-input`}
                                    placeholder={`Height`}
                                    margin="dense"
                                    spellCheck={false}
                                    {...register(`height`)}
                                    error={errors.height ? true : false}
                                    value={productHeight}
                                    inputProps={{
                                        onInput: (e) => {
                                            if (heightChange) heightChange(e.target.value);
                                            setProductHeight(e.target.value)
                                        }
                                    }}
                                />
                                <p className={`field-err-msg position-relative `}>{errors.height?.message}</p>
                            </div>

                            <div>
                                <TextField
                                    type={"text"}
                                    id="depth"
                                    name="depth"
                                    className={`input-wrap depth-input`}
                                    placeholder={`Depth`}
                                    margin="dense"
                                    spellCheck={false}
                                    {...register(`depth`)}
                                    error={errors.depth ? true : false}
                                    value={productDepth}
                                    inputProps={{
                                        onInput: (e) => {
                                            if (depthChange) depthChange(e.target.value);
                                            setProductDepth(e.target.value)
                                        }
                                    }}
                                />
                                <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.depth?.message}</p>
                            </div>
                        </div>
                    </Box>
                }

                <Box className={`form-control margin-bottom-28`}>

                    {
                        (userSettings?.pro_volume || userSettings?.pro_weight) &&
                        <div className={`product-volume-weight-wrapper`}>
                            {
                                userSettings?.pro_volume &&
                                <div className={`product-volume-wrap`}>
                                    <Typography className={`label information-icon`}>
                                        Product volume
                                        <span className="custom-tt-2" tooltip="Calculated by multiplying the product width by depth by height"><InformationIcon /> </span>
                                    </Typography>
                                    <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                        <div>
                                            <select id="volumn-select" onChange={(event) => { if (selectedVolumeChange) { selectedVolumeChange(event.target.value) }; setSelectedVolume(event.target.value) }}>
                                                {
                                                    volumeList && volumeList.length > 0 &&
                                                    volumeList.map((list, index) => {
                                                        return (
                                                            <option
                                                                value={list[`volume_id`]}
                                                                key={index}
                                                                selected={selectedVolume === list[`volume_id`] ? 'selected' : ''}
                                                            >
                                                                {list[`name`]}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div>
                                            <TextField
                                                type={"text"}
                                                id="volume"
                                                name="volume"
                                                className={`input-wrap volume-input`}
                                                placeholder={`Volume`}
                                                margin="dense"
                                                spellCheck={false}
                                                {...register(`volume`)}
                                                error={errors.volume ? true : false}
                                                value={productVolume}
                                                inputProps={{
                                                    onInput: (e) => {
                                                        if (volumeChange) volumeChange(e.target.value);
                                                        setProductVolume(e.target.value)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className={`field-err-msg w-100`}>
                                        <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.volume?.message}</p>
                                    </div>
                                </div>
                            }

                            {
                                userSettings?.pro_weight &&
                                <div className={`product-weight-wrap`}>
                                    <Typography className={`label information-icon`}>
                                        Product weight
                                        <span className="custom-tt-2" tooltip="This is the weight of the product in its packaging"><InformationIcon /> </span>
                                    </Typography>
                                    <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                        <div>
                                            <select id="weight-select" onChange={(event) => { if (selectedWeightChange) { selectedWeightChange(event.target.value) }; setSelectedWeight(event.target.value) }}>
                                                {
                                                    weightList && weightList.length > 0 &&
                                                    weightList.map((list, index) => {
                                                        return (
                                                            <option
                                                                value={list[`weight_id`]}
                                                                key={index}
                                                                selected={selectedWeight === list[`weight_id`] ? 'selected' : ''}
                                                            >
                                                                {list[`name`]}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div>
                                            <TextField
                                                type={"text"}
                                                id="weight"
                                                name="weight"
                                                className={`input-wrap weight-input`}
                                                placeholder={`Weight`}
                                                margin="dense"
                                                spellCheck={false}
                                                {...register(`weight`)}
                                                error={errors.weight ? true : false}
                                                value={productWeight}
                                                inputProps={{
                                                    onInput: (e) => {
                                                        if (weightChange) weightChange(e.target.value);
                                                        setProductWeight(e.target.value)
                                                    },
                                                    step: "any",
                                                }}
                                            />

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    <div className={`field-err-msg w-100`}>
                        <p className={`field-err-msg position-relative `}>{errors.weight?.message}</p>
                    </div>
                </Box>

                <Box className={`form-control margin-bottom-28`}>
                    {
                        (userSettings?.pro_container_20 || userSettings?.pro_container_20_loose || userSettings?.pro_container_40 || userSettings?.pro_container_40_loose || userSettings?.pro_case_pallete_quantity || userSettings?.pro_case_per_unit || userSettings?.pro_full_truck_load) &&
                        <div className={`product-container-loadability-wrapper`}>
                            {
                                userSettings?.pro_container_20 &&
                                <div className={`product-container-loadability-wrap margin-bottom-28`}>
                                    <Typography className={`label information-icon`}>
                                        Container Loadability 20 ft (Palletized)

                                    </Typography>
                                    <div className={`volumn-wrap custom-black-select-option for-one-field`}>

                                        <TextField
                                            type={"text"}
                                            id="container_loadability_20_ft"
                                            name="container_loadability_20_ft"
                                            className={`input-wrap ppc-input`}
                                            placeholder={`Enter number of cartons`}
                                            margin="dense"
                                            spellCheck={false}
                                            {...register(`container_loadability_20_ft`)}
                                            error={errors.container_loadability_20_ft ? true : false}
                                            value={container_loadability_20_ft}
                                            inputProps={{
                                                onInput: (e) => {
                                                    if (containerLoadability20FtChange) containerLoadability20FtChange(e.target.value)
                                                    setcontainer_loadability_20_ft(e.target.value)
                                                },
                                                maxLength: 40
                                            }}
                                        />
                                        <div className={`field-err-msg`}>
                                            <p className={`field-err-msg position-relative margin-bottom-0`}>{errors.container_loadability_20_ft?.message}</p>
                                        </div>
                                    </div>


                                </div>
                            }

                            {
                                userSettings?.pro_container_20_loose &&
                                <div className={`product-weight-wrap margin-bottom-28`}>
                                    <Typography className={`label information-icon`}>
                                        Container Loadability 20 ft (Loose)
                                    </Typography>
                                    <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                        <div>
                                            <TextField
                                                type={"text"}
                                                id="container_loadability_20_ft_loose"
                                                name="container_loadability_20_ft_loose"
                                                className={`input-wrap ppc-input`}
                                                placeholder={`Container Loadability 20 ft (Loose)`}
                                                margin="dense"
                                                spellCheck={false}
                                                {...register(`container_loadability_20_ft_loose`)}
                                                error={errors.container_loadability_20_ft_loose ? true : false}
                                                value={container_loadability_20_ft_loose}
                                                inputProps={{
                                                    onInput: (e) => {
                                                        if (containerLoadability20FtLooseChange) containerLoadability20FtLooseChange(e.target.value)
                                                        setcontainer_loadability_20_ft_loose(e.target.value)
                                                    },
                                                    maxLength: 40
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className={`field-err-msg`}>
                                        <p className={`field-err-msg position-relative `}>{errors.container_loadability_20_ft_loose?.message}</p>
                                    </div>
                                </div>
                            }
                            {
                                userSettings?.pro_container_40 &&
                                <div className={`product-weight-wrap`}>
                                    <Typography className={`label information-icon`}>
                                    Container Loadability 40 ft (Palletized)
                                    </Typography>
                                    <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                        <div>
                                            <TextField
                                                type={"text"}
                                                id="container_loadability_40_ft"
                                                name="container_loadability_40_ft"
                                                className={`input-wrap ppc-input`}
                                                placeholder={`Enter number of cartons`}
                                                margin="dense"
                                                spellCheck={false}
                                                {...register(`container_loadability_40_ft`)}
                                                error={errors.container_loadability_40_ft ? true : false}
                                                value={container_loadability_40_ft}
                                                inputProps={{
                                                    onInput: (e) => {
                                                        if (containerLoadability40FtChange) containerLoadability40FtChange(e.target.value)
                                                        setcontainer_loadability_40_ft(e.target.value)
                                                    },
                                                    maxLength: 40
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className={`field-err-msg`}>
                                        <p className={`field-err-msg position-relative `}>{errors.container_loadability_40_ft?.message}</p>
                                    </div>
                                </div>
                            }
                            {
                                userSettings?.pro_container_40_loose &&
                                <div className={`product-weight-wrap`}>
                                    <Typography className={`label information-icon`}>
                                    Container Loadability 40 ft (Loose)
                                    </Typography>
                                    <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                        <div>
                                            <TextField
                                                type={"text"}
                                                id="container_loadability_40_ft_loose"
                                                name="container_loadability_40_ft_loose"
                                                className={`input-wrap ppc-input`}
                                                placeholder={`Enter number of cartons`}
                                                margin="dense"
                                                spellCheck={false}
                                                {...register(`container_loadability_40_ft_loose`)}
                                                error={errors.container_loadability_40_ft_loose ? true : false}
                                                value={container_loadability_40_ft_loose}
                                                inputProps={{
                                                    onInput: (e) => {
                                                        if (containerLoadability40FtlooseChange) containerLoadability40FtlooseChange(e.target.value)
                                                        setcontainer_loadability_40_ftloose(e.target.value)
                                                    },
                                                    maxLength: 40
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className={`field-err-msg`}>
                                        <p className={`field-err-msg position-relative `}>{errors.container_loadability_40_ft_loose?.message}</p>
                                    </div>
                                </div>
                            }
                            {
                                userSettings?.pro_full_truck_load &&
                                <div className={`product-weight-wrap`}>
                                    <Typography className={`label information-icon`}>
                                    Full Truck load (Cases)
                                    </Typography>
                                    <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                        <div>
                                            <TextField
                                                type={"text"}
                                                id="full_truck_load"
                                                name="full_truck_load"
                                                className={`input-wrap ppc-input`}
                                                placeholder={`Enter number of truck load`}
                                                margin="dense"
                                                spellCheck={false}
                                                {...register(`full_truck_load`)}
                                                error={errors.full_truck_load ? true : false}
                                                value={full_truck_load}
                                                inputProps={{
                                                    onInput: (e) => {
                                                        if (fullTruckLoadChange) fullTruckLoadChange(e.target.value)
                                                        setfull_truck_load(e.target.value)
                                                    },
                                                    maxLength: 40
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className={`field-err-msg`}>
                                        <p className={`field-err-msg position-relative `}>{errors.full_truck_load?.message}</p>
                                    </div>
                                </div>
                            }
                            {
                                userSettings?.pro_case_per_unit &&
                                <div className={`product-weight-wrap`}>
                                    <Typography className={`label information-icon`}>
                                    Units per carton/case
                                    </Typography>
                                    <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                        <div>
                                            <TextField
                                                type={"text"}
                                                id="p_p_case"
                                                name="p_p_case"
                                                className={`input-wrap ppc-input`}
                                                placeholder={`Units per carton/case`}
                                                margin="dense"
                                                spellCheck={false}
                                                {...register(`p_p_case`)}
                                                error={errors.p_p_case ? true : false}
                                                value={propercase}
                                                inputProps={{
                                                    onInput: (e) => {
                                                        if (unitPerCasechange) unitPerCasechange(e.target.value)
                                                        setPropercase(e.target.value)
                                                    },
                                                    maxLength: 40
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={`field-err-msg`}>
                                        <p className={`field-err-msg position-relative `}>{errors.p_p_case?.message}</p>
                                    </div>
                                </div>
                            }
                            {
                                userSettings?.pro_case_pallete_quantity &&
                                <div className={`product-weight-wrap`}>
                                    <Typography className={`label information-icon`}>
                                    Pallet Quantity
                                    </Typography>
                                    <div className={`volumn-wrap custom-black-select-option for-one-field`}>
                                        <div>
                                            <TextField
                                                type={"text"}
                                                id="palletquantity"
                                                name="palletquantity"
                                                className={`input-wrap ppc-input`}
                                                placeholder={`Pallet Quantity`}
                                                margin="dense"
                                                spellCheck={false}
                                                {...register(`palletquantity`)}
                                                error={errors.palletquantity ? true : false}
                                                value={productCasePalletequan}
                                                inputProps={{
                                                    onInput: (e) => {
                                                        if (casePalaleteQuantityChange) casePalaleteQuantityChange(e.target.value)
                                                        setProductCasePalletequan(e.target.value)
                                                    },
                                                    maxLength: 40
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={`field-err-msg`}>
                                        <p className={`field-err-msg position-relative `}>{errors.palletquantity?.message}</p>
                                    </div>
                                </div>
                            }
                        </div>

                    }

                </Box>
                <Box className={`form-control margin-bottom-28 country-wrapper`}>

                    <Typography className={`label margin-btm-15`}>Country of Origin</Typography>
                    {/* <select className={`selection-opt-with-flag`}
                id="country-select"
                onChange={(event) => { setSelectedCountry(event.target.value) }}
            >
                {
                    countryList && countryList.length > 0 &&
                    countryList.map((country, index) => {
                        return (
                            <option
                                value={country[`country_id`]}
                                key={index}
                                selected={selectedCountry === country[`country_id`] ? 'selected' : ''}
                            >
                                {country[`name`]}
                            </option>
                        )
                    })
                }
            </select> */}

                    <FormControl sx={{ minWidth: 200 }}>
                        <Select
                            labelId="country-code-select-label"
                            id="country-select"
                            value={selectedCountry}

                            label="Country  "

                            onChange={handleChange}
                        >
                            <MenuItem value="none">
                                Select country of origin
                            </MenuItem>
                            {countryList &&
                                countryList.length > 0 &&
                                countryList.map((country, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={country[`country_id`]}

                                        >
                                            {
                                                <div className="flag-wrapper flex flex-items-center">
                                                    {<img src={flagemojiToPNG(country[`emoji`])} style={{ "marginRight": "5px", "height": "20px", "width": "20px", "objectFit": "contain" }} loading="lazy" />}
                                                    {country[`name`]}
                                                </div>


                                            }
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Box>

                <div className={`button-wrapper`}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        id="product-preview-submit-btn"
                        className={`w-100 btn-primary-after-login visibility-hidden display-none`}
                    >
                        Sign up
                    </Button>
                </div>
            </form>
            <ImageUploadSection
                isImageUploadSliderShow={isImageUploadSliderShow}
                onClose={(value) => { setImageUploadSliderShow(value) }}
                onSave={(images) => { onImagesSave(images) }}
                images={uploadImages}
            />
        </Fragment>

    )
}

export default ProductPreview;

