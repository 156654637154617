import React, { useEffect, useState, Fragment, useRef } from "react";

//React route dom related imports
import { useParams, useNavigate } from "react-router-dom";

//Apis related imports
import getWebSiteProductDetails from "../../../../apis/websitePreview/getWebSiteProductDetails";
import websiteDetailsPreviewEvent from "../../../../apis/websitePreview/websiteDetailsPreviewEvent";
import catalogueViewDetailsDurationEvent from "../../../../apis/websitePreview/catalogueViewDetailsDurationEvent";
import getWebsiteBrand from '../../../../apis/websitePreview/getWebsiteBrand';

// mui or UI related imports
import Box from "@mui/material/Box";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

//Icon related imports
import ShareIcon from "../../../../icons/svgs/shareIcon";

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import appObjectAction from "../../../../store/action/appObjectAction";

//Other related imports
import Slider from "../../Supplier/Slider";
import Loader from "../../Loader";
import PopupImageViewer from "../../Supplier/PopupImageViewer";

//Helpers related imports
import getDefaultCurrency from "../../../../helpers/getDefaultCurrency";
import InformationIcon from "../../../../icons/svgs/infromationIcon";
import readCookie from '../../../../helpers/readCookie';
import imageResizing from "../../../../helpers/imageResizing";
import numberWithCommas from "../../../../helpers/numberWithCommas";

//Other imports
import { useIdleTimer } from 'react-idle-timer'
import VisibilitySensor from 'react-visibility-sensor';
import ReactImageMagnify from 'react-image-magnify';

const HeaderSection = (props) => {
    const { productDetails, catalogueId, pageType, onBackClick, productPageSetting } = props;

    const navigate = useNavigate(), dispatch = useDispatch();

    const onBackRedirection = () => {
        if (pageType === `websiteCatalogueProductPreview`) {
            dispatch(appObjectAction.setAppObject({
                app: {
                    "isEmailVerificationShowCatalogue": false,
                }
            }))
            navigate(`/webcatalogue/${catalogueId}`)
        } else {
            if (onBackClick) onBackClick(true);
        }
    }
    return (
        <div className={`header-section-wrapper`}>
            <div className={`header-wrap`}>
                <div className={`back-btm-wrap`} onClick={() => { onBackRedirection() }}>
                    <KeyboardBackspaceIcon />
                    Back to products
                </div>
            </div>

            <div className={`header-wrap`}>


                <div className={`brand-image`}>
                    <img src={productDetails?.brandImage} alt="brand image" loading="lazy" />
                </div>

                <div className={`tagline-wrapper`}>
                    <div className={`name`}>
                        <div className={`text`}>
                            {productDetails?.brandName}
                        </div>
                    </div>

                    <div className={`tagline`}>
                        {productDetails?.brandTagline || ``}
                    </div>
                </div>
            </div>

            {/* <div className={`share-wrapper visibility-hidden`}>
                <div className={`share-icon`}>
                    <ShareIcon />
                </div>
                <div className={`text`}>
                    share
                </div>
            </div> */}
        </div>
    )
}

const PreviewExitSction = (props) => {
    const { onPreviewClose, onBackClick } = props;

    const onExitPreviewClick = () => {
        if (onPreviewClose) onPreviewClose(false);
        if (onBackClick) onBackClick();
    }

    return (
        <Box className={`exit-preview-wrapper details`}>
            <div className={`text-wrap`}>
                <div className={`heading only-desktop`}>Brand Catalogue Preview: </div>
                <div className={`description`}>This is what buyers see when they view your catalogue.</div>
            </div>

            <div className={`exit-text`} onClick={() => { onExitPreviewClick() }}>
                Exit preview
            </div>
        </Box>
    )
}

const OverViewSection = (props) => {

    const { catalogueId, updateProductDetail } = props;
    const [productDetail, setProductDetail] = useState(props?.productDetails || {}),
        [productImages, setProductImages] = useState(productDetail?.product_img || []),
        [variants, setVariants] = useState(productDetail?.product_variant || []),
        [variantsDropDownValue, setVariantsDropDownValue] = useState([]),
        [selectedVariant, setSelectedVariant] = useState(productDetail?.products_id || ``),
        [productName, setProductName] = useState(productDetail?.name || ``),
        [productBrandName, setProductBrandName] = useState(productDetail?.productbrandname || ``),
        [productUnit, setProductUnit] = useState(productDetail?.ps_unit || ``),
        [productQuantity, setProductQuantity] = useState(productDetail?.ps_quantity || ``),
        [productMeas_value, setProductMeas_value] = useState(productDetail?.ps_meas_value || ``),
        [productMeasName, setProductMeasName] = useState(productDetail?.ps_measName || ``),
        [msrpValue, setMsrpValue] = useState(productDetail?.product_price?.[0]?.m_price || 0),
        [msrpCurrency, setMsrpCurrency] = useState(productDetail?.product_price?.[0]?.m_price_currencyName || ``),
        [msrpCurrencySymbol, setMsrpCurrencySymbol] = useState(productDetail?.product_price?.[0]?.m_price_symbol || ``),
        [productFromPrice, setProductFromPrice] = useState(productDetail?.product_price?.[0]?.s_price_from || 0),
        [productToPrice, setProductToPrice] = useState(productDetail?.product_price?.[0]?.s_price_to || 0),
        [productCurrency, setProductCurrency] = useState(productDetail?.product_price?.[0]?.s_price_currencyName || ``),
        [productCurrencySymbol, setProductCurrencySymbol] = useState(productDetail?.product_price?.[0]?.s_price_symbol || ``),
        [skuId, setSkuId] = useState(productDetail?.sku_id || ``),
        [barcode, setBarcode] = useState(productDetail?.barcode || ''),
        [hs_code, seths_code] = useState(productDetail?.hs_code || ''),
        [flavour, setflavour] = useState(productDetail?.productflavourname || ''),
        [isLoading, setIsLoading] = useState(false),
        [margin, setMargin] = useState(productDetail?.margin || ''),
        [isVariantDropDownShow, setIsVariantDropDownShow] = useState(productDetail?.parent === null ? variants && variants.length > 0 ? true : false : true),
        [productPackaging, setProductPackaging] = useState(productDetail?.product_packaging?.[0] || {}),
        [priceswitch, setPriceswitch] = useState(productDetail?.is_price || true),
        [productSetting, setProductSetting] = useState(props?.productSettings || {}),
        [defaultImage, setDefaultImage] = useState(``),
        [activeSlide, setActiveSlide] = useState(0),
        [currentSlide, setCurrentSlide] = useState(0),
        [isSlideChangeOnImage, setIsSlideChangeOnImage] = useState(false),
        [isVariantChange, setVariantChange] = useState(false),
        [magnifierImage, setMagnifierImage] = useState(``),
        [isBrandSliderShow, setIsBrandSliderShow] = useState(true);

    useEffect(() => {
        if (!isVariantChange) {
            updateProductDetailState(props?.productDetails || {}, true);

            if (props?.productDetails?.product_img && props?.productDetails?.product_img.length > 0) {
                props?.productDetails?.product_img.map((img, index) => {
                    if (img[`default`]) {
                        setDefaultImage(index);
                        setCurrentSlide(index);
                        setMagnifierImage(img[`image`]);
                        setActiveSlide(index);
                    }
                })
            }
            setVariantChange(false);
        }

    }, [props?.productDetails])

    useEffect(() => {
        if (props?.productDetails?.products_detail?.[0]?.nutritioanl_file) {
            let tempArray = productImages;

            tempArray.push({
                "default": false,
                "image": props?.productDetails?.products_detail?.[0]?.nutritioanl_file,
                "name": ``
            })

            setProductImages(tempArray);
        }

    }, [productDetail])
    useEffect(() => {
        setProductSetting(props?.productSettings)
    }, [props?.productSettings]);

    useEffect(() => {

        if (isSlideChangeOnImage) {
            if (currentSlide < activeSlide) {
                if (document.getElementsByClassName(`btn-prev`)[0]) {
                    document.getElementsByClassName(`btn-prev`)[0].click()
                }
            }
            else if (currentSlide > activeSlide) {
                if (document.getElementsByClassName(`btn-next`)[0]) {
                    document.getElementsByClassName(`btn-next`)[0].click();
                }
            }

            setIsSlideChangeOnImage(false);
        }

        if (document.getElementsByClassName(`selected-image`)[0]) {
            document.getElementsByClassName(`selected-image`)[0].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }, [activeSlide, currentSlide, isSlideChangeOnImage]);

    useEffect(() => {

        if (isVariantChange) {
            if (props?.productDetails?.product_img && props?.productDetails?.product_img.length > 0) {
                props?.productDetails?.product_img.map((img, index) => {
                    if (img[`default`]) {
                        setDefaultImage(index);
                        setCurrentSlide(index);
                        setMagnifierImage(img[`image`]);
                        setActiveSlide(index);
                    }
                })
            }
        }
    }, [props?.productDetails, isVariantChange])

    useEffect(() => {
        setIsBrandSliderShow(productImages && productImages.length > 3 ? true : false)
    }, [productImages])

    const onVariantDropDownChange = (productId) => {
        setIsLoading(true);
        setSelectedVariant(productId);
        getWebSiteProductDetails(catalogueId, productId)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setIsLoading(false);
                    setVariantChange(true);
                    updateProductDetailState(response?.data?.data?.[0] || {}, false);
                    if (updateProductDetail) {
                        updateProductDetail(response?.data?.data?.[0])
                    }
                } else {
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log("We have error at getWebSiteProductDetails", err);
            })
    }

    const updateProductDetailState = (productDetails, isUpdateDropDownVariant = true) => {

        if (isUpdateDropDownVariant) {
            let tempArray = [
                {
                    "name": productDetails?.name,
                    "productId": productDetails?.products_id
                }
            ];

            productDetails?.product_variant?.length > 0 &&
                productDetails?.product_variant.map((data) => {
                    tempArray.push(
                        {
                            "name": data?.name,
                            "productId": data?.products_id
                        }
                    )
                })
            setVariantsDropDownValue(tempArray);
        }

        setProductDetail(productDetails || {});
        setProductImages(productDetails?.product_img || []);
        setVariants(productDetails?.product_variant || []);
        setSelectedVariant(productDetails?.products_id || ``);
        setProductName(productDetails?.name || ``);
        setProductBrandName(productDetails?.productbrandname || ``);
        setProductUnit(productDetails?.ps_unit || ``);
        setProductQuantity(productDetails?.ps_quantity || ``);
        setProductMeas_value(productDetails?.ps_meas_value || ``);
        setProductMeasName(productDetails?.ps_measName || ``);
        setMsrpValue(productDetails?.product_price?.[0]?.m_price || 0);
        setMsrpCurrency(productDetails?.product_price?.[0]?.m_price_currencyName || ``);
        setMsrpCurrencySymbol(productDetails?.product_price?.[0]?.m_price_symbol || ``);
        setProductFromPrice(productDetails?.product_price?.[0]?.s_price_from || 0);
        setProductToPrice(productDetails?.product_price?.[0]?.s_price_to || 0);
        setProductCurrency(productDetails?.product_price?.[0]?.s_price_currencyName || ``);
        setProductCurrencySymbol(productDetails?.product_price?.[0]?.s_price_symbol || ``)
        setSkuId(productDetails?.sku_id || ``);
        setBarcode(productDetails?.barcode || ``);
        seths_code(productDetails?.hs_code || ``);
        setflavour(productDetails?.productflavourname || ``);
        setMargin(productDetails?.margin || ``);
        setPriceswitch(productDetails?.is_price || true);
        setIsVariantDropDownShow(productDetails?.parent === null ? productDetails?.product_variant && productDetails?.product_variant.length > 0 ? true : false : true);
        setProductPackaging(productDetails?.product_packaging?.[0] || {});
        setProductSetting(props?.productSettings || {});
    }

    const productViewImageChange = (index, image) => {
        setIsSlideChangeOnImage(true);
        setCurrentSlide(index);
        setActiveSlide(index);
        setMagnifierImage(image?.image);
    }

    const flagemojiToPNG = (flag, code) => {
        var countryCode = Array.from(flag, (codeUnit) => codeUnit.codePointAt()).map(char => String.fromCharCode(char - 127397).toLowerCase()).join('')
        return `https://flagcdn.com/24x18/${countryCode}.png`;
    }

    // Read More Content Popup
    const [popup, setPop] = useState(false)
    const handleClickOpen = () => {
        setPop(!popup);
        document.body.style.overflow = 'hidden'; // BG scroll hide
    }
    const closeReadMorePopup = () => {
        setPop(false)
        document.body.style.overflow = 'unset'; // BG scroll show
    }
    // Read More Content Popup

    const onSliderArrow = (side) => {
        const brandList = document.getElementsByClassName('image-show-slider')[0];
        let slideValue = 180;

        if (side === "left") {
            brandList.scrollLeft -= slideValue;
        }
        else if (side === "right") {
            brandList.scrollLeft += slideValue;
        }
    }

    return (
        <div className={`overview-section-wrapper`} id="overview">
            {isLoading && <Loader />}
            <div className={`product-image-wrapper`}>
                {
                    productImages && productImages?.length > 0
                        ?
                        <Fragment>
                            {/* <div className={`image-slider`}>
                                {
                                    productImages && productImages?.length > 0 && defaultImage !== `` &&
                                    <Slider
                                        autoRotate={false}
                                        showNextPrevBtn={true}
                                        rotationTiming={5000}
                                        showDisk={false}
                                        backArrowHtml={
                                            <div className={`prev-arrow`}>
                                                <WestIcon />
                                            </div>
                                        }
                                        nextArrowHtml={
                                            <div className={`next-arrow`}>
                                                <EastIcon />
                                            </div>
                                        }
                                        defaultActiveSlide={defaultImage || 0}
                                        onSlideChange={(slide) => setActiveSlide(slide)}
                                    >
                                        {
                                            productImages && productImages?.length > 0 &&
                                            productImages.map((image, index) => {
                                                return (
                                                    <div className={`image-wrap`} key={index}>
                                                        <img src={image?.image} alt={`product image`} loading="lazy" />
                                                    </div>

                                                )
                                            })
                                        }
                                    </Slider>
                                }
                            </div> */}

                            {
                                magnifierImage ?
                                    <div className={`image-slider`}>
                                        <div className={`image-wrap`}>
                                            {/* <img src={magnifierImage} alt={`product image`} loading="lazy" /> */}
                                            {/* Large image : // src: imageResizing(magnifierImage, 500, 500, `cover`), */}
                                            <ReactImageMagnify {...{
                                                smallImage: {
                                                    alt: 'product image',
                                                    isFluidWidth: true,
                                                    src: imageResizing(magnifierImage, 500, 500, `contain`, "#ffffff"),
                                                    width: 150,
                                                    height: 300,
                                                },
                                                largeImage: {
                                                    src: imageResizing(magnifierImage, 500, 500, `contain`, "#ffffff"),
                                                    width: 900,
                                                    height: 1200,
                                                  },
                                                  enlargedImageContainerStyle: {
                                                    zIndex: "1500",
                                                  },
                                                  enlargedImageContainerDimensions: {
                                                    width: "100%",
                                                    height: "100%",
                                                  },
                                            }} />
                                        </div>
                                    </div>
                                    :
                                    <div className={`no-product-image-wrapper`}>
                                        No Product Image Found
                                    </div>
                            }
                            <div className={`image-show-slider-wrapper`}>
                                {
                                    isBrandSliderShow &&
                                    <div className={`not-mobile prev-btn`} onClick={() => onSliderArrow("left")}>
                                        <ArrowBackIosIcon />
                                    </div>
                                }
                                <div className={`image-show-slider ${productImages && productImages?.length === 1 ? 'center' : ''}`}>

                                    {
                                        productImages && productImages?.length > 0 &&
                                        productImages.map((image, index) => {
                                            return (
                                                <div className={`image-wrap ${index === activeSlide ? 'selected-image' : ''}`} key={index}>
                                                    <img
                                                        src={image?.image}
                                                        alt={`product image`}
                                                        loading="lazy"
                                                        onClick={() => { productViewImageChange(index, image); }}
                                                        // onMouseEnter={() => { productViewImageChange(index, image); }}
                                                    />
                                                </div>

                                            )
                                        })
                                    }

                                </div>
                                {
                                    isBrandSliderShow &&
                                    <div className={`not-mobile next-btn`} onClick={() => onSliderArrow("right")}>
                                        <ArrowForwardIosIcon />
                                    </div>
                                }
                            </div>
                        </Fragment>
                        :
                        <div className={`no-product-image-wrapper`}>
                            No Product Image Found
                        </div>
                }
            </div>

            <div className={`product-info-wrapper`}>

                {
                    variants && variants.length > 0 &&
                    <div className={`variant-count`}>
                        {`${variants.length} variants`}
                    </div>
                }
                {
                    isVariantDropDownShow &&
                    <div className={`variant-dropdown`}>
                        {
                            variantsDropDownValue && variantsDropDownValue?.length > 0 &&
                            <select onChange={(event) => { onVariantDropDownChange(event.target.value) }}>
                                {
                                    variantsDropDownValue.map((variant, index) => {
                                        return <option
                                            value={variant?.productId}
                                            key={index}
                                            disabled={`${selectedVariant === variant?.productId ? 'disabled' : ''}`}
                                            selected={`${selectedVariant === variant?.productId ? 'selected' : ''}`}
                                        >
                                            {variant.name}
                                        </option>;
                                    })
                                }
                            </select>
                        }
                    </div>
                }


                <div className={`product-brand`} style={{ marginBottom: "5px" }}>
                    {productBrandName}
                </div>
                <div className={`product-name`}>
                    {productName}
                </div>
                {
                    productDetail?.p_description && productDetail?.p_description?.replace('<p></p>', '').length !== 0 ?
                        <div className={`product-price-wrapper`}>
                            <div>
                                <div>
                                    <span className="ellipsis-for-2-line">
                                        {
                                            productDetail?.p_description && productDetail?.p_description?.replace('<p></p>', '').length !== 0
                                                ?
                                                <div dangerouslySetInnerHTML={{ __html: productDetail?.p_description }}></div>
                                                :
                                                <></>
                                        }
                                    </span>
                                    {
                                        productDetail?.p_description?.replace('<p></p>', '').length >= 100
                                            ?
                                            <span className="btn-read-more-content skip-btn" onClick={handleClickOpen}>Read More...</span>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                        : <></>
                }

                {
                    productDetail?.product_usps && productDetail?.product_usps.length > 0 && productSetting?.pro_usps &&
                    <Fragment>
                        <div className={`product-price-wrapper`}>
                            <div>
                                <div className={`value chips-list`}>
                                    {
                                        productDetail?.product_usps.map((usps, index) => {
                                            return (
                                                <div key={index} className={`chips`}>

                                                    <div>
                                                        {usps?.name}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className={`label margin-top-15`}>
                                    USP'S
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
                {
                    (productDetail?.is_price === true) && (((productSetting?.pro_msrp) && (msrpValue)) || ((productSetting?.pro_priceitem) && (productFromPrice || productToPrice))) ?
                        <Fragment>
                            <div className={`product-price-wrapper`}>
                                {
                                    (productSetting?.pro_msrp) && (msrpValue) ?
                                        <Fragment>
                                            <div>
                                                <div className={`value`}>
                                                    {`${msrpCurrencySymbol}`}{`${numberWithCommas(msrpValue)} ${msrpCurrency}`}
                                                </div>

                                                <div className={`label`}>
                                                    Retail Price
                                                </div>
                                            </div>
                                        </Fragment>
                                        :
                                        <></>
                                }
                                {
                                    (productSetting?.pro_priceitem) && (productFromPrice || productToPrice) ?
                                        <Fragment>
                                            <div>
                                                <div className={`value ${productCurrency == `AED` ? 'arabic' : ''}`} >

                                                    {
                                                        productCurrency === `AED` ?
                                                            `${productCurrency} ${numberWithCommas(productToPrice)} - ${productCurrencySymbol} ${numberWithCommas(productFromPrice)}` :
                                                            `${productCurrencySymbol} ${numberWithCommas(productFromPrice)} - ${numberWithCommas(productToPrice)} ${productCurrency}`
                                                    }



                                                </div>

                                                <div className={`label`}>
                                                    Cost
                                                </div>
                                            </div>
                                        </Fragment> : <></>
                                }</div></Fragment> : <></>
                }
                <div className={`product-selling-item-wrapper`}>
                    <div>
                        <div className={`value`}>
                            {/* {`${skuId}`} */}
                            <span>{skuId}</span>
                        </div>

                        <div className={`label`}>
                            Seller SKU ID
                        </div>
                    </div>
                </div>

                {
                    ((productDetail?.products_detail?.[0]?.shelf_life && productDetail?.products_detail?.[0]?.shelf_life_value) || productDetail?.is_price === true) && (productSetting?.pro_msrp && productSetting?.pro_priceitem && margin) ?
                        <div className={`product-selling-item-wrapper`}>


                            {
                                productDetail?.products_detail?.[0]?.shelf_life && productDetail?.products_detail?.[0]?.shelf_life_value &&
                                <Fragment>
                                    <div>
                                        <div className={`value`}>
                                            {
                                                productDetail?.products_detail?.[0]?.shelf_life_typename ? `${productDetail?.products_detail?.[0]?.shelf_life_value} ${productDetail?.products_detail?.[0]?.shelf_life_typename || `NA`}` : ``
                                            }
                                        </div>
                                        <div className={`label`}>

                                            Shelf Life
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            {/* {
                        productSetting?.pro_barcode && barcode ?
                            <Fragment>
                                <div>
                                    <div className={`value`}>
                                        {barcode}
                                    </div>

                                    <div className={`label`}>
                                        Product Barcode
                                    </div>
                                </div></Fragment> : <></>
                    } */}
                            {
                                (productDetail?.is_price === true) && (productSetting?.pro_msrp && productSetting?.pro_priceitem && margin) ?
                                    <Fragment>
                                        {
                                            productSetting?.pro_msrp && productSetting?.pro_priceitem && margin &&
                                            <div>
                                                <div className={`value margin-value`}>
                                                    {`${margin}`}
                                                </div>

                                                <div className={`label`}>
                                                    Gross Margin
                                                </div>
                                            </div>
                                        }
                                    </Fragment> : <></>
                            }
                        </div>
                        :
                        ``

                }

                {
                    ((productSetting?.pro_dimensions) && (productPackaging?.pro_width || productPackaging?.pro_height || productPackaging?.pro_depth)) || (productSetting?.pro_volume && productPackaging?.pro_volume) || (productSetting?.pro_weight && productPackaging?.pro_weight) ?
                        <Fragment>
                            <div className={`product-selling-item-wrapper`}>
                                {
                                    (productSetting?.pro_dimensions) && (productPackaging?.pro_width || productPackaging?.pro_height || productPackaging?.pro_depth) ?
                                        <Fragment>
                                            <div>
                                                <div className={`value`}>
                                                    {
                                                        productPackaging?.pro_width ?
                                                            `${productPackaging?.pro_width} ${productPackaging?.pro_height ? 'x ' + productPackaging?.pro_height : ``} ${productPackaging?.pro_depth ? 'x ' + productPackaging?.pro_depth : ``} ${productPackaging?.dimensiontypename}`
                                                            :
                                                            productPackaging?.pro_height ?
                                                                `${productPackaging?.pro_height} ${productPackaging?.pro_depth ? 'x ' + productPackaging?.pro_depth : ``} ${productPackaging?.dimensiontypename}`
                                                                : productPackaging?.pro_depth ?
                                                                    `${productPackaging?.pro_depth} ${productPackaging?.dimensiontypename}`
                                                                    :
                                                                    ``
                                                    }
                                                </div>
                                                <div className={`label`}>
                                                    Product dimensions ({
                                                        productPackaging?.pro_width ?
                                                            `w${productPackaging?.pro_height ? 'xh' : ``}${productPackaging?.pro_depth ? 'xd' : ``}`
                                                            :
                                                            productPackaging?.pro_height ?
                                                                `h${productPackaging?.pro_depth ? 'xd' : ``}`
                                                                : productPackaging?.pro_depth ?
                                                                    `d`
                                                                    :
                                                                    ``
                                                    })
                                                </div>
                                            </div>
                                        </Fragment> : <></>
                                }
                                {
                                    productSetting?.pro_volume && productPackaging?.pro_volume &&
                                    <div>
                                        <div className={`value`}>
                                            {`${productPackaging?.pro_volume || 'NA'} ${productPackaging?.volumetypename || 'NA'}`}
                                        </div>

                                        <div className={`label`}>
                                            Product volume
                                        </div>
                                    </div>
                                }

                                {
                                    productSetting?.pro_weight && productPackaging?.pro_weight &&
                                    <div>
                                        <div className={`value`}>
                                            {`${productPackaging?.pro_weight} ${productPackaging?.weighttypename || 'NA'}`}
                                        </div>

                                        <div className={`label`}>
                                            Product weight
                                        </div>
                                    </div>
                                }

                            </div>

                        </Fragment> : <></>
                }
                {
                    productSetting?.pro_hs_code && hs_code &&
                    <Fragment>
                        <div className={`product-selling-item-wrapper bor-none`}>
                            <div>
                                <div className={`value margin-value`}>
                                    {
                                        `${hs_code || `NA`}` 
                                    }
                                </div>

                                <div className={`label`}>
                                HS Code
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
                {
                    (productDetail?.product_price?.[0]?.p_p_case) &&
                    <Fragment>
                        <div className={`product-selling-item-wrapper bor-none`}>
                            <div>
                                <div className={`value margin-value`}>
                                    {
                                        `${productDetail?.product_price?.[0]?.p_p_case || `NA`}` 
                                    }
                                </div>
                                <div className={`label`}>
                                    Units per carton/case
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }

               {
                    productSetting?.pro_flavour &&
                    <Fragment>
                        <div className={`product-selling-item-wrapper bor-none`}>
                            <div>
                                <div className={`value margin-value`}>
                                    {
                                        `${flavour || `NA`}` 
                                    }
                                </div>

                                <div className={`label`}>
                                Variant/Flavour
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
                <div className={`product-selling-item-wrapper bor-none`}>
                    {
                        (productSetting?.pro_pack_size) && (productUnit || productQuantity || productMeas_value) ?
                            <Fragment>
                                <div>
                                    <div className={`value`}>
                                        {
                                            `${productMeas_value || 'NA'} ${productMeasName || 'NA'} ${productUnit ? 'x' + productUnit : ''} ${productQuantity ? 'x' + productQuantity : ''}`
                                        }
                                    </div>
                                    <div className={`label`}>
                                         Case Configuration/Pack Size
                                    </div>
                                </div>
                            </Fragment> : <></>
                    }
                    
                    {
                        productDetail?.country_image &&
                        <div>
                            <div className={`value`}>
                                {productDetail?.country_name}  <span className="d-flag"><img src={flagemojiToPNG(productDetail?.country_image)} alt="flag image" loading='lazy' /></span>
                            </div>
                            <div className={`label`}>
                                Country of Origin
                            </div>
                        </div>
                    }

                </div>
            </div>
            <div>
                {
                    popup ?
                        <div class="image-viewer-wrapper MuiBox-root css-0 ">
                            <div class="image-view-popup">
                                <div class="close-icon" onClick={closeReadMorePopup}><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" width="24" height="24" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></div>
                                <div class="title text-left w-full modal-title">Description</div>
                                <div className="scroll-con content scroll-bar-theme">
                                    {
                                        productDetail?.p_description
                                            ?
                                            <div dangerouslySetInnerHTML={{ __html: productDetail?.p_description }}></div>
                                            :
                                            `NA`
                                    }
                                </div>
                            </div>
                        </div>
                        : ""
                }
            </div>
        </div>
    )
}

const ProductDetail = (props) => {

    const { isMobile } = props;

    const [productDetails, setProductDetail] = useState(props?.productDetails || {}),
        [msrpValue, setMsrpValue] = useState(productDetails?.product_price?.[0]?.m_price || 0),
        [productFromPrice, setProductFromPrice] = useState(productDetails?.product_price?.[0]?.s_price_from || 0),
        [productToPrice, setProductToPrice] = useState(productDetails?.product_price?.[0]?.s_price_to || 0),
        [productSetting, setProductSetting] = useState(props?.productSettings || {}),
        [barcode, setBarcode] = useState(productDetails?.barcode || ''),
        [hs_code, seths_code] = useState(productDetails?.hs_code || ''),
        [flavour, setflavour] = useState(productDetails?.productflavourname || '');

    useEffect(() => {
        setProductDetail(props?.productDetails || {});
        setMsrpValue(props?.productDetails?.product_price?.[0]?.m_price || 0);
        setProductFromPrice(props?.productDetails?.product_price?.[0]?.s_price_from || 0);
        setProductToPrice(props?.productDetails?.product_price?.[0]?.s_price_to || 0);
        setBarcode(productDetails?.barcode || ``);
        seths_code(productDetails?.hs_code || ``);
        setflavour(productDetails?.productflavourname || ``);
    }, [props?.productDetails])

    useEffect(() => {
        setProductSetting(props?.productSettings || {});
    }, [props?.productSettings])
    // Read More Content Popup
    const [nutriPopup, setNutriPopup] = useState(false)
    const handleClickOpen = () => {
        setNutriPopup(!nutriPopup);
        document.body.style.overflow = 'hidden'; // BG scroll hide
    }
    const closeImagePopup = () => {
        setNutriPopup(false)
        document.body.style.overflow = 'unset'; // BG scroll show
    }
    // Read More Content Popup

    return (
        <div className={`product-details-wrapper`} id="product-detail">

            {/* Re-structuring pls don't delete this commented code */}
            <div className={`box`}>
                <div className={`box-title`}>
                    Product Details
                </div>
                {
                    ((productSetting?.pro_priceitem) && (productDetails?.product_price?.[0]?.s_price_from || productDetails?.product_price?.[0]?.s_price_to) || ((productSetting?.pro_msrp) && (productDetails?.product_price?.[0]?.m_price)) || ((productDetails?.product_price?.[0]?.case_price) && (productSetting?.pro_priceitem) && (productDetails?.product_price?.[0]?.s_price_from || productDetails?.product_price?.[0]?.s_price_to)))
                        ?
                        <div className={`border-btm`}></div>
                        :
                        ``
                }
                <div className={`box-containr`}>
                    {/* {
                        productDetails?.product_packaging?.[0]?.product_packaging &&
                        <Fragment> */}  
                    {
                        productDetails?.product_price?.[0]?.s_price_to || productDetails?.product_price?.[0]?.p_p_case || productDetails?.product_price?.[0]?.m_price || productDetails?.product_price?.[0]?.s_price_from || productDetails?.product_price?.[0]?.p_comments?.replace('<p></p>\n', '').length !== 0 || productDetails?.p_description?.replace('<p></p>', '').length !== 0 || productDetails?.product_price?.[0]?.case_price ?
                            <Fragment>

                                {
                                    productDetails?.is_price === true && ((productSetting?.pro_priceitem) && (productDetails?.product_price?.[0]?.s_price_from || productDetails?.product_price?.[0]?.s_price_to) || ((productSetting?.pro_msrp) && (productDetails?.product_price?.[0]?.m_price)) || ((productDetails?.product_price?.[0]?.case_price) && (productSetting?.pro_priceitem) && (productDetails?.product_price?.[0]?.s_price_from || productDetails?.product_price?.[0]?.s_price_to)))
                                        ?
                                        <div className={`box-inner-details`}>
                                            <span className={`box-inner-title`}>
                                                Product Pricing
                                            </span>

                                            <div className="box-inner-sub-details border-bottom">
                                                <div className={`box-column-wrap`}>
                                                    <div className={`box-col-one`}>
                                                        {
                                                            productDetails?.is_price === true ?
                                                                <Fragment>

                                                                    {
                                                                        (productSetting?.pro_priceitem) && (productDetails?.product_price?.[0]?.s_price_from || productDetails?.product_price?.[0]?.s_price_to) &&
                                                                        <div className="box-row-wrap">
                                                                            <div className={`box-inner-column-33p b-title`}>
                                                                                B2B Price Range
                                                                            </div>
                                                                            <div className={`box-inner-column-66p b-details`}>
                                                                                {
                                                                                    `${productDetails?.product_price?.[0]?.s_price_currencyName || `NA`} ${numberWithCommas(productDetails?.product_price?.[0]?.s_price_from) || `NA`} - ${productDetails?.product_price?.[0]?.s_price_currencyName || `NA`} ${numberWithCommas(productDetails?.product_price?.[0]?.s_price_to) || `NA`}`
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {
                                                                        (productSetting?.pro_msrp) && (productDetails?.product_price?.[0]?.m_price) &&
                                                                        <div className="box-row-wrap">
                                                                            <div className={`box-inner-column-33p b-title`}>
                                                                                Retail Price
                                                                            </div>
                                                                            <div className={`box-inner-column-66p b-details`}>
                                                                                {
                                                                                    `${productDetails?.product_price?.[0]?.m_price_currencyName || `NA`} ${numberWithCommas(productDetails?.product_price?.[0]?.m_price) || `NA`}`
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </Fragment>
                                                                :
                                                                <></>
                                                        }

                                                        {
                                                            productDetails?.is_price === true ?
                                                                <Fragment>
                                                                    
                                                                    {
                                                                        (productDetails?.product_price?.[0]?.case_price) && (productSetting?.pro_priceitem) && (productDetails?.product_price?.[0]?.s_price_from || productDetails?.product_price?.[0]?.s_price_to) &&
                                                                        <div className="box-row-wrap">
                                                                            <div className={`box-inner-column-33p b-title`}>
                                                                                Case price
                                                                            </div>
                                                                            <div className={`box-inner-column-66p b-details`}>
                                                                                {
                                                                                    `${productDetails?.product_price?.[0]?.case_price}`
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </Fragment>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    productDetails?.product_price?.[0]?.p_comments && productDetails?.product_price?.[0]?.p_comments?.replace('<p></p>', '').length !== 0 && productDetails?.product_price?.[0]?.p_comments?.replace('<p></p>\n', '').length !== 0 ?
                                                        <div className={`box-column-wrap`}>
                                                            <div className={`box-inner-full-column-wrap`}>

                                                                <div className="box-row-wrap">
                                                                    <div className={`box-inner-full-column-25P b-title`}>
                                                                        Pricing Comments
                                                                    </div>
                                                                    <div className={`box-inner-full-column-75P b-details`}>
                                                                        {
                                                                            productDetails?.product_price?.[0]?.p_comments
                                                                                ?
                                                                                <div dangerouslySetInnerHTML={{ __html: productDetails?.product_price?.[0]?.p_comments }}></div>
                                                                                :
                                                                                `NA`
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : <></>
                                                }


                                            </div>
                                        </div>
                                        :
                                        ``
                                }
                            </Fragment>
                            :
                            <></>
                    }

                    {
                        productDetails?.product_packaging?.[0]?.product_packaging || (productDetails?.product_packaging?.[0]?.pro_width || productDetails?.product_packaging?.[0]?.pro_height || productDetails?.product_packaging?.[0]?.pro_depth || productDetails?.product_packaging?.[0]?.pro_volume || productDetails?.product_packaging?.[0]?.pro_weight || productSetting?.pro_barcode && barcode || productSetting?.pro_hs_code && hs_code) ?
                            <Fragment>
                                <div className={`box-inner-details`}>
                                    <span className={`box-inner-title`}>
                                        Product Packaging
                                    </span>
                                    <div className="box-inner-sub-details border-bottom">

                                        <div className={`box-column-wrap`}>
                                            <div className={`box-col-one`}>
                                                {
                                                    productSetting?.pro_barcode && barcode &&
                                                    <Fragment>

                                                        <div className="box-row-wrap">
                                                            <div className={`box-inner-column-33p b-title`}>
                                                                Product Barcode
                                                            </div>
                                                            <div className={`box-inner-column-66p b-details`}>
                                                                {
                                                                    `${barcode}`
                                                                }
                                                            </div>
                                                        </div>

                                                    </Fragment>
                                                }
                                                {
                                                    productSetting?.pro_hs_code && hs_code &&
                                                    <Fragment>

                                                        <div className="box-row-wrap">
                                                            <div className={`box-inner-column-33p b-title`}>
                                                                HS Code
                                                            </div>
                                                            <div className={`box-inner-column-66p b-details`}>
                                                                {
                                                                    `${hs_code}`
                                                                }
                                                            </div>
                                                        </div>

                                                    </Fragment>
                                                }
                                            </div>
                                        </div>
                                        <div className={`box-column-wrap`}>
                                            <div className={`box-col-one`}>
                                                {
                                                    productDetails?.product_packaging?.[0]?.pro_width || productDetails?.product_packaging?.[0]?.pro_height || productDetails?.product_packaging?.[0]?.pro_depth ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title`}>
                                                                    Product dimensions ({
                                                                        productDetails?.product_packaging?.[0]?.pro_width ?
                                                                            `w${productDetails?.product_packaging?.[0]?.pro_height ? 'xh' : ``}${productDetails?.product_packaging?.[0]?.pro_depth ? 'xd' : ``}`
                                                                            :
                                                                            productDetails?.product_packaging?.[0]?.pro_height ?
                                                                                `h${productDetails?.product_packaging?.[0]?.pro_depth ? 'xd' : ``}`
                                                                                : productDetails?.product_packaging?.[0]?.pro_depth ?
                                                                                    `d`
                                                                                    :
                                                                                    ``
                                                                    })
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        productDetails?.product_packaging?.[0]?.pro_width ?
                                                                            `${productDetails?.product_packaging?.[0]?.pro_width} ${productDetails?.product_packaging?.[0]?.pro_height ? 'x ' + productDetails?.product_packaging?.[0]?.pro_height : ``} ${productDetails?.product_packaging?.[0]?.pro_depth ? 'x ' + productDetails?.product_packaging?.[0]?.pro_depth : ``} ${productDetails?.product_packaging?.[0]?.dimensiontypename}`
                                                                            :
                                                                            productDetails?.product_packaging?.[0]?.pro_height ?
                                                                                `${productDetails?.product_packaging?.[0]?.pro_height} ${productDetails?.product_packaging?.[0]?.pro_depth ? 'x ' + productDetails?.product_packaging?.[0]?.pro_depth : ``} ${productDetails?.product_packaging?.[0]?.dimensiontypename}`
                                                                                : productDetails?.product_packaging?.[0]?.pro_depth ?
                                                                                    `${productDetails?.product_packaging?.[0]?.pro_depth} ${productDetails?.product_packaging?.[0]?.dimensiontypename}`
                                                                                    :
                                                                                    ``
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_packaging?.[0]?.pro_volume ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title`}>
                                                                    Product volume
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        `${productDetails?.product_packaging?.[0]?.pro_volume || `NA`} ${productDetails?.product_packaging?.[0]?.volumetypename || `NA`}`
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_packaging?.[0]?.pro_weight ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title`}>
                                                                    Product weight
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        `${productDetails?.product_packaging?.[0]?.pro_weight || `NA`} ${productDetails?.product_packaging?.[0]?.weighttypename || `NA`}`
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>}

                                                {
                                                    productDetails?.product_packaging?.[0]?.pack_description ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title`}>
                                                                    Additional comments
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        `${productDetails?.product_packaging?.[0]?.pack_description || `NA`}`
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                            :
                            <></>
                    }


                    {
                        productDetails?.product_inner_package?.[0]?.product_inner_pack && (productDetails?.product_inner_package?.[0]?.units_per_inner || productDetails?.product_inner_package?.[0]?.pro_inner_barcode || productDetails?.product_inner_package?.[0]?.inner_width || productDetails?.product_inner_package?.[0]?.inner_height || productDetails?.product_inner_package?.[0]?.inner_depth || productDetails?.product_inner_package?.[0]?.inner_volume || productDetails?.product_inner_package?.[0]?.inner_weight) ?
                            <Fragment>
                                <div className={`box-inner-details`}>
                                    <span className={`box-inner-title`}>
                                        Inner Packaging
                                    </span>
                                    <div className="box-inner-sub-details border-bottom">
                                        <div className={`box-column-wrap`}>
                                            <div className={`box-col-one`}>
                                                {
                                                    productDetails?.product_inner_package?.[0]?.units_per_inner ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title`}>
                                                                    Units per inner
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {productDetails?.product_inner_package?.[0]?.units_per_inner}
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_inner_package?.[0]?.pro_inner_barcode ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title`}>
                                                                    Inner pack barcode
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {productDetails?.product_inner_package?.[0]?.pro_inner_barcode}
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_inner_package?.[0]?.inner_width || productDetails?.product_inner_package?.[0]?.inner_height || productDetails?.product_inner_package?.[0]?.inner_depth
                                                        ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title`}>
                                                                    Inner pack dimensions ({
                                                                        productDetails?.product_inner_package?.[0]?.inner_width ?
                                                                            `w${productDetails?.product_inner_package?.[0]?.inner_height ? 'xh' : ``}${productDetails?.product_inner_package?.[0]?.inner_depth ? 'xd' : ``}`
                                                                            :
                                                                            productDetails?.product_inner_package?.[0]?.inner_height ?
                                                                                `h${productDetails?.product_inner_package?.[0]?.inner_depth ? 'xd' : ``}`
                                                                                : productDetails?.product_inner_package?.[0]?.inner_depth ?
                                                                                    `d`
                                                                                    :
                                                                                    ``
                                                                    })
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        productDetails?.product_inner_package?.[0]?.inner_width ?
                                                                            `${productDetails?.product_inner_package?.[0]?.inner_width} ${productDetails?.product_inner_package?.[0]?.inner_height ? 'x ' + productDetails?.product_inner_package?.[0]?.inner_height : ``} ${productDetails?.product_inner_package?.[0]?.inner_depth ? 'x ' + productDetails?.product_inner_package?.[0]?.inner_depth : ``} ${productDetails?.product_inner_package?.[0]?.dimensiontypename}`
                                                                            :
                                                                            productDetails?.product_inner_package?.[0]?.inner_height ?
                                                                                `${productDetails?.product_inner_package?.[0]?.inner_height} ${productDetails?.product_inner_package?.[0]?.inner_depth ? 'x ' + productDetails?.product_inner_package?.[0]?.inner_depth : ``} ${productDetails?.product_inner_package?.[0]?.dimensiontypename}`
                                                                                : productDetails?.product_inner_package?.[0]?.inner_depth ?
                                                                                    `${productDetails?.product_inner_package?.[0]?.inner_depth} ${productDetails?.product_inner_package?.[0]?.dimensiontypename}`
                                                                                    :
                                                                                    ``
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_inner_package?.[0]?.inner_volume ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title`}>
                                                                    Inner pack volume
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        `${productDetails?.product_inner_package?.[0]?.inner_volume} ${productDetails?.product_inner_package?.[0]?.volumetypename || `NA`}`
                                                                    }
                                                                </div>
                                                            </div></Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_inner_package?.[0]?.inner_weight ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title`}>
                                                                    Inner pack weight
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        `${productDetails?.product_inner_package?.[0]?.inner_weight} ${productDetails?.product_inner_package?.[0]?.weighttypename || `NA`}`
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }

                                                {
                                                    productDetails?.product_case_package?.[0]?.case_description ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title`}>
                                                                    Additional comments
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        `${productDetails?.product_case_package?.[0]?.case_description || `NA`}`
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment> : <></>
                    }
                    {
                        productDetails?.product_case_package?.[0]?.product_case_pack && (productDetails?.product_price?.[0]?.p_p_case || productDetails?.product_case_package?.[0]?.product_gtin || productDetails?.product_case_package?.[0]?.case_width || productDetails?.product_case_package?.[0]?.case_height || productDetails?.product_case_package?.[0]?.case_depth || productDetails?.product_case_package?.[0]?.case_volume || productDetails?.product_case_package?.[0]?.case_weight || productDetails?.product_case_package?.[0]?.case_pallete_t1 || productDetails?.product_case_package?.[0]?.case_pallete_h1 || productDetails?.product_case_package?.[0]?.case_pallete_quantity || productDetails?.product_case_package?.[0]?.container_loadability_20_ft || productDetails?.product_case_package?.[0]?.container_load_20_ft_loose || productDetails?.product_case_package?.[0]?.container_loadability_40_ft || productDetails?.product_case_package?.[0]?.full_truck_load) ?
                            <Fragment>
                                <div className={`box-inner-details`}>
                                    <span className={`box-inner-title`}>
                                        Carton/Case packaging
                                    </span>
                                    <div className="box-inner-sub-details  border-bottom">
                                        <div className={`box-column-wrap`}>
                                            <div className={`box-col-one`}>
                                                {
                                                    productDetails?.product_case_package?.[0]?.product_gtin ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title`}>
                                                                    Carton/Case barcode
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {productDetails?.product_case_package?.[0]?.product_gtin}
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_case_package?.[0]?.case_width || productDetails?.product_case_package?.[0]?.case_height || productDetails?.product_case_package?.[0]?.case_depth
                                                        ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                    Carton/Case dimensions ({
                                                                        productDetails?.product_case_package?.[0]?.case_width ?
                                                                            `w${productDetails?.product_case_package?.[0]?.case_height ? 'xh' : ``}${productDetails?.product_case_package?.[0]?.case_depth ? 'xd' : ``}`
                                                                            :
                                                                            productDetails?.product_case_package?.[0]?.case_height ?
                                                                                `h${productDetails?.product_case_package?.[0]?.case_depth ? 'xd' : ``}`
                                                                                : productDetails?.product_case_package?.[0]?.case_depth ?
                                                                                    `d`
                                                                                    :
                                                                                    ``
                                                                    })
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        productDetails?.product_case_package?.[0]?.case_width ?
                                                                            `${productDetails?.product_case_package?.[0]?.case_width} ${productDetails?.product_case_package?.[0]?.case_height ? 'x ' + productDetails?.product_case_package?.[0]?.case_height : ``} ${productDetails?.product_case_package?.[0]?.case_depth ? 'x ' + productDetails?.product_case_package?.[0]?.case_depth : ``} ${productDetails?.product_case_package?.[0]?.dimensiontypename}`
                                                                            :
                                                                            productDetails?.product_case_package?.[0]?.case_height ?
                                                                                `${productDetails?.product_case_package?.[0]?.case_height} ${productDetails?.product_case_package?.[0]?.case_depth ? 'x ' + productDetails?.product_case_package?.[0]?.case_depth : ``} ${productDetails?.product_case_package?.[0]?.dimensiontypename}`
                                                                                : productDetails?.product_case_package?.[0]?.case_depth ?
                                                                                    `${productDetails?.product_case_package?.[0]?.case_depth} ${productDetails?.product_case_package?.[0]?.dimensiontypename}`
                                                                                    :
                                                                                    ``
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_case_package?.[0]?.case_volume ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                    Carton/Case volume<span className="custom-tt" tooltip="Calculated by multiplying the carton/case width by depth by height"><InformationIcon /> </span>
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        `${productDetails?.product_case_package?.[0]?.case_volume || `NA`} ${productDetails?.product_case_package?.[0]?.volumetypename || `NA`}`
                                                                    }
                                                                </div>
                                                            </div></Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_case_package?.[0]?.case_weight ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                    Carton/case weight
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        `${productDetails?.product_case_package?.[0]?.case_weight} ${productDetails?.product_case_package?.[0]?.weighttypename || `NA`}`
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_price?.[0]?.p_p_case ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                Units per carton/case
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {
                                                                        `${productDetails?.product_price?.[0]?.p_p_case || `NA`}`
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_case_package?.[0]?.case_pallete_t1 ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                    Pallet TI<span className="custom-tt-2" tooltip="The number of cartons/cases that fit in one layer of a pallet" flow={isMobile ? "right" : "up"}><InformationIcon /> </span>
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {numberWithCommas(productDetails?.product_case_package?.[0]?.case_pallete_t1)}
                                                                </div>
                                                            </div></Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_case_package?.[0]?.case_pallete_h1 ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                    Pallet HI<span className="custom-tt-2" tooltip="The number of layers you can stack on a pallet" flow={isMobile ? "right" : "up"}><InformationIcon /> </span>
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {numberWithCommas(productDetails?.product_case_package?.[0]?.case_pallete_h1)}
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_case_package?.[0]?.case_pallete_quantity ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                    Pallet Quantity<span className="custom-tt-2" tooltip="The total number of cartons/cases that can fit on a pallet" flow={isMobile ? "right" : "up"}><InformationIcon /> </span>
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {(productDetails?.product_case_package?.[0]?.case_pallete_quantity)}
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_case_package?.[0]?.container_loadability_20_ft && productSetting?.pro_container_20 ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                    Container Loadability 20 ft (Palletized)
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {numberWithCommas(productDetails?.product_case_package?.[0]?.container_loadability_20_ft)}
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_case_package?.[0]?.container_load_20_ft_loose && productSetting?.pro_container_20_loose ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                    Container Loadability 20 ft (Loose)
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {numberWithCommas(productDetails?.product_case_package?.[0]?.container_load_20_ft_loose)}
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_case_package?.[0]?.container_loadability_40_ft && productSetting?.pro_container_40 ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                Container Loadability 40 ft (Palletized)
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {numberWithCommas(productDetails?.product_case_package?.[0]?.container_loadability_40_ft)}
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_case_package?.[0]?.container_load_40_ft_loose && productSetting?.pro_container_40_loose ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                Container loadability 40 ft (Loose)
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {numberWithCommas(productDetails?.product_case_package?.[0]?.container_load_40_ft_loose)}
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                                {
                                                    productDetails?.product_case_package?.[0]?.full_truck_load && productSetting?.pro_full_truck_load ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                Full Truck load (Cases)
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {numberWithCommas(productDetails?.product_case_package?.[0]?.full_truck_load)}
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }

                                                {
                                                    productDetails?.product_inner_package?.[0]?.inner_description ?
                                                        <Fragment>
                                                            <div className="box-row-wrap">
                                                                <div className={`box-inner-column-33p b-title information-icon`}>
                                                                    Additional comments
                                                                </div>
                                                                <div className={`box-inner-column-66p b-details`}>
                                                                    {`${productDetails?.product_inner_package?.[0]?.inner_description || ``}`}
                                                                </div>
                                                            </div>
                                                        </Fragment> : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment> : <></>
                    }

                    <div className={`box-inner-details`}>
                        <div className="box-inner-sub-details">
                            <div className={`box-column-wrap`}>
                                <div className={`box-col-one`}>
                                    {
                                        productDetails?.products_detail?.[0]?.shelf_life && productDetails?.products_detail?.[0]?.shelf_life_value &&
                                        <Fragment>
                                            <div className="box-row-wrap">
                                                <div className={`box-inner-column-33p b-title`}>
                                                    Shelf Life
                                                </div>
                                                <div className={`box-inner-column-66p b-details`}>
                                                    {
                                                        productDetails?.products_detail?.[0]?.shelf_life_typename ? `${productDetails?.products_detail?.[0]?.shelf_life_value} ${productDetails?.products_detail?.[0]?.shelf_life_typename || `NA`}` : ``
                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                    {
                                        productDetails?.products_detail?.[0]?.pro_ingredients && productDetails?.products_ingredients.length > 0 &&
                                        <Fragment>
                                            <div className="box-row-wrap">
                                                <div className={`box-inner-column-33p b-title`}>
                                                    Product Ingredients
                                                </div>
                                                <div className={`box-inner-column-66p b-details`}>
                                                    {productDetails?.products_ingredients.map(ingredients => ingredients?.name).join()}
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                    {
                                        productDetails?.products_detail?.[0]?.nutritional_label && productDetails?.products_detail?.[0]?.nutritioanl_file &&
                                        <div className="box-row-wrap">
                                            <div className={`box-inner-column-33p b-title`}>
                                                Nutritional Label
                                            </div>
                                            <div className={`box-inner-column-66p b-details`}>
                                                {
                                                    productDetails?.products_detail?.[0]?.nutritioanl_file ?
                                                        <div  className="description-text m-i">
                                                            <span tooltip="Click here to view" flow="right">
                                                                <img className={`nutri-img`}
                                                                    src={productDetails?.products_detail?.[0]?.nutritioanl_file}
                                                                    loading="lazy"
                                                                    alt="nutritional label image"
                                                                    onClick={handleClickOpen}
                                                                // onClick={() => { if (productDetails?.products_detail?.[0]?.nutritioanl_file) window.open(productDetails?.products_detail?.[0]?.nutritioanl_file) }}
                                                                />
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className={`description-text`}>No label found</div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        nutriPopup ?
                                            <div class="image-viewer-wrapper read-more-popup MuiBox-root css-0">
                                                <div class="image-view-popup nutri-img">
                                                    <div class="close-icon" onClick={closeImagePopup}><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" width="24" height="24" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></div>
                                                    <div class="title text-left">Nutritional Label</div>
                                                    <img className={`nutri-la`} src={productDetails?.products_detail?.[0]?.nutritioanl_file} alt="" />

                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </Fragment>


                    } */}
                </div>
            </div>
            {/* Re-structuring pls don't delete this commented code */}
        </div>
    )
}

const TechnicalSpecifiction = (props) => {
    const { productDetails } = props;

    return (
        <div className={`technical-specifiction-wrapper`} id="technical-specification">

            <div className={`box`}>
                <div className={`box-title`}>
                    Technical Specifications
                </div>
                <div className={`border-btm`}></div>
                <div className={`box-containr`}>
                    <div className={`box-inner-details`}>
                        <div className="box-inner-sub-details">

                            <div className={`box-column-wrap`}>
                                <div className={`box-col-one`}>
                                    {
                                        productDetails?.products_tech_specification && productDetails?.products_tech_specification.length > 0 &&
                                        productDetails?.products_tech_specification.map((specification, index) => {
                                            return (
                                                <div className="box-row-wrap" key={index}>
                                                    <div className={`box-inner-column-33p b-title ${specification?.dynofieldstype === 3 ? 'mutliselect-label' : ''}`}>
                                                        {specification?.dynofieldsname}
                                                    </div>
                                                    {
                                                        (specification?.dynofieldstype === 1 || specification?.dynofieldstype === 2) &&
                                                        <div className={`box-inner-column-66p b-details`}>
                                                            {
                                                                `${specification?.dynovaluesname?.[0] || 'NA'}`
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        specification?.dynofieldstype === 3 &&
                                                        <div className={`chips-list box-inner-column-66p b-details`}>
                                                            {
                                                                specification?.dynovaluesname && specification?.dynovaluesname.length > 0
                                                                    ? <Fragment>
                                                                        {
                                                                            specification?.dynovaluesname.map((value, index) => {
                                                                                return (
                                                                                    <div key={index} className={`chips`}>
                                                                                        <div>{value}</div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <span className={`box-inner-column-66p b-details`}>
                                                                            {`NA`}
                                                                        </span>
                                                                    </Fragment>

                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        specification?.dynofieldstype === 4 &&
                                                        <div className="box-inner-column-66p b-details">
                                                            {
                                                                specification?.dyno_text_value
                                                                    ?
                                                                    <Fragment>
                                                                        {
                                                                            specification?.dynovaluesname && specification?.dynovaluesname.length > 0 &&
                                                                            <span className={`box-inner-column-66p b-details`}>
                                                                                {specification?.dyno_text_value} {specification?.dynovaluesname?.[0]}
                                                                            </span>
                                                                        }
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <span className={`box-inner-column-66p b-details`}>
                                                                            {`NA`}
                                                                        </span>
                                                                    </Fragment>

                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CertificationsAndApprovals = (props) => {
    const { productDetails } = props;

    // Read More Content Popup

    const [imageViewerData, setImageViewerData] = useState({}),
        [isImageViewShow, setIsImageViewShow] = useState(false);

    const viewImage = (image) => {
        setIsImageViewShow(true);
        setImageViewerData({ image: image })
    }
    // Read More Content Popup
    return (
        <div className={`certifications-wrapper`} id="certification">
            <div className={`left-div`}>
                Certifications And Approvals
            </div>
            <div className={`right-div`}>
                {
                    productDetails?.country_name &&
                    <Fragment>
                        <div className={`heading-parent-text`}>
                            Country of Origin
                        </div>
                        <div className={`description-text`}>
                            <div>
                                <div className={'certification-wrap'}>
                                    <div className={`text country-name`}>
                                        {productDetails?.country_name}
                                    </div>
                                    {
                                        productDetails?.products_certification?.[0]?.origin_certification
                                            ?
                                            <div className={`button-wrap`}>
                                                <Button
                                                    variant="outlined"
                                                    className={`btn-primary-after-login`}
                                                    onClick={() => viewImage(productDetails?.products_certification?.[0]?.origin_certification)}
                                                >
                                                    View
                                                </Button>
                                            </div>
                                            :
                                            <div className={`button-wrap`}>
                                                {/* <Button
                                                    variant="outlined"
                                                    className={`button-with-blue-border-no-effect`}
                                                    style={{ cursor: "default" }}
                                                >
                                                    Not available
                                                </Button> */}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
                {
                    productDetails?.products_certification?.[0]?.other_certification && productDetails?.products_other_certification && productDetails?.products_other_certification.length > 0 &&
                    <Fragment>
                        <div className={`heading-parent-text`}>
                            Other Certifications & Approvals
                        </div>
                        <div className={`description-text`}>
                            {
                                productDetails?.products_other_certification.map((certificate, index) => {
                                    return (
                                        <div key={index}>
                                            <div className={'certification-wrap'}>
                                                <div className={`text country-name`}>
                                                    {certificate?.country_name}
                                                </div>
                                                <div className={`text certification-type`}>
                                                    {certificate?.certificationtypename}
                                                </div>

                                                {
                                                    certificate?.certification
                                                        ?
                                                        <div className={`button-wrap`}>
                                                            <Button
                                                                variant="outlined"
                                                                className={`btn-primary-after-login`}
                                                                onClick={() => viewImage(certificate?.certification)}
                                                            >
                                                                View
                                                            </Button>
                                                        </div>
                                                        :
                                                        <div className={`button-wrap`}>
                                                            {/* <Button
                                                                variant="outlined"
                                                                className={`button-with-blue-border-no-effect`}
                                                                style={{ cursor: "default" }}
                                                            >
                                                                Not available
                                                            </Button> */}
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Fragment>
                }
            </div>
            {
                isImageViewShow &&
                <PopupImageViewer
                    data={imageViewerData}
                    onPopupClose={(value) => { setIsImageViewShow(value); setImageViewerData({}) }}
                />
            }
        </div>
    )
}

const Distribution = (props) => {

    const { productDetails } = props;

    return (
        <div className={`distribution-wrapper`} id="distribution">

            <div className={`box`}>
                <div className={`box-title`}>
                    Distribution
                </div>
                <div className={`border-btm`}></div>
                <div className={`box-containr`}>
                    {
                        !productDetails?.products_distribution?.[0]?.pro_availability && productDetails?.products_distribution?.[0]?.pro_availability_date &&
                        <Fragment>
                            <div className={`box-inner-details`}>
                                <span className={`box-inner-title`}>
                                    Product Availability Date
                                </span>
                                <div className="box-inner-sub-details border-bottom">

                                    <div className={`box-column-wrap`}>
                                        <div className={`box-col-one`}>

                                            <div className="box-row-wrap">
                                                <div className={`box-inner-column-100p b-details`}>
                                                    {
                                                        `${productDetails?.products_distribution?.[0]?.pro_availability_date || `NA`}`
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Fragment>
                    }
                    {
                        (productDetails?.products_sold_countries && productDetails?.products_sold_countries.length > 0) || (productDetails?.products_distribution?.[0]?.pro_avail_distributors && productDetails?.products_distributors && productDetails?.products_distributors.length > 0) ?
                            <Fragment>
                                <div className={`box-inner-details`}>
                                    <div className="box-inner-sub-details border-bottom">
                                        <div className={`box-column-wrap`}>
                                            <div className={`box-col-one`}>
                                                {
                                                    productDetails?.products_sold_countries && productDetails?.products_sold_countries.length > 0 &&
                                                    <Fragment>
                                                        <div className="box-row-wrap display-block">
                                                            <div className={`box-inner-column-100p b-details`}>
                                                                <span className={`box-inner-title`}>
                                                                    Currently sold at
                                                                </span>
                                                            </div>
                                                            <div className={`box-inner-column-100p b-details`}>
                                                                <div className={`chips-list chips-top-space`}>
                                                                    {
                                                                        productDetails?.products_sold_countries.map((country, index) => {
                                                                            return (
                                                                                <div key={index} className={`chips`}>

                                                                                    <div>
                                                                                        {country?.country_name}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                }
                                                {
                                                    productDetails?.products_distribution?.[0]?.pro_avail_distributors && productDetails?.products_distributors && productDetails?.products_distributors.length > 0 &&
                                                    <Fragment>
                                                        <div className="box-row-wrap display-block">
                                                            <div className={`box-inner-column-100p b-details`}>
                                                                <span className={`box-inner-title`}>
                                                                    Distributors/Wholesalers
                                                                </span>
                                                            </div>
                                                            <div className={`box-inner-column-100p b-details`}>
                                                                <div className={`chips-list chips-top-space`}>
                                                                    {
                                                                        productDetails?.products_distributors.map((distributors, index) => {
                                                                            return (
                                                                                <div key={index} className={`chips`}>
                                                                                    <div>
                                                                                        {distributors?.distributorsname}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment> : <></>
                    }

                    {
                        productDetails?.products_distribution?.[0]?.pro_availability && productDetails?.products_retailers.length > 0 &&
                        <div className={`box-inner-details`}>
                            <div className="box-inner-sub-details border-bottom">

                                <div className={`box-column-wrap`}>
                                    <div className={`box-col-one display-block`}>

                                        <div className="box-row-wrap">
                                            <div className={`box-inner-column-100p b-details`}>
                                                <span className={`box-inner-title`}>
                                                    Currently distributed in
                                                </span>
                                            </div>
                                            <div className={`box-inner-column-100p b-details two-col-grid`}>
                                                {
                                                    productDetails?.products_retailers.map((retailers, index) => {
                                                        return (
                                                            <div key={index} className={`retailers-list`}>
                                                                <div className={`retailers-name heading-text`}>
                                                                    {retailers?.retailersname}
                                                                </div>
                                                                {
                                                                    retailers?.all_stores ?
                                                                        <div className={`chips-list`}>
                                                                            <div className={`chips`}>
                                                                                <div>All Stores</div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        retailers?.stores_count
                                                                            ?
                                                                            <div className={`chips-list`}>
                                                                                <div className={`chips`}>
                                                                                    <div>{retailers?.stores_count} Stores</div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className={`chips-list`}>
                                                                                <div className={`chips`}>
                                                                                    <div>{retailers?.stores_count} Stores</div>
                                                                                </div>
                                                                            </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>




                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    <div className={`box-inner-details`}>
                        <div className="box-inner-sub-details">

                            <div className={`box-column-wrap`}>
                                <div className={`box-col-one`}>
                                    {
                                        productDetails?.products_distribution?.[0]?.pro_private_label ?
                                            <Fragment>
                                                <div className="box-row-wrap display-block">
                                                    <div className={`box-inner-column-100p b-title`}>
                                                        <span className={`box-inner-title`}>
                                                            Private Label
                                                        </span>
                                                    </div>
                                                    <div className={`box-inner-column-100p b-details margin-top-16`}>
                                                        Yes
                                                    </div>
                                                </div>
                                            </Fragment> : <></>
                                    }
                                    {
                                        productDetails?.products_distribution?.[0]?.pro_cold_storage ?
                                            <Fragment>
                                                <div className="box-row-wrap display-block">
                                                    <div className={`box-inner-column-100p b-title`}>
                                                        <span className={`box-inner-title`}>
                                                            Cold Storage
                                                        </span>
                                                    </div>
                                                    <div className={`box-inner-column-100p b-details margin-top-16`}>
                                                        {productDetails?.products_distribution?.[0]?.pro_storage_typename}
                                                    </div>
                                                </div>
                                            </Fragment> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const Marketing = (props) => {

    const { productDetails } = props,
        [defaultImage, setDefaultImage] = useState(``),
        [planogramDefaultImage, setPlanogramDefaultImage] = useState(``),
        [isImageViewShow, setIsImageViewShow] = useState(false),
        [imageViewerData, setImageViewerData] = useState({});

    useEffect(() => {
        if (productDetails?.products_additional_gallery && productDetails?.products_additional_gallery?.length > 0) {
            productDetails?.products_additional_gallery.map((img, index) => {
                if (img[`default`]) {
                    setDefaultImage(index);
                }
            })
        }
    }, [productDetails?.products_additional_gallery])

    useEffect(() => {
        if (productDetails?.products_planogram_gallery && productDetails?.products_planogram_gallery?.length > 0) {
            productDetails?.products_planogram_gallery.map((img, index) => {
                if (img[`default`]) {
                    setPlanogramDefaultImage(index);
                }
            })
        }
    }, [productDetails?.products_planogram_gallery])


    return (
        <div className={`marketing-wrapper`} id="marketing">
            <div className={`left-div`}>
                Marketing
            </div>
            <div className={`right-div`}>
                <div className={`product-two-col`}>
                    {
                        productDetails?.products_marketing?.[0]?.budgetname ?
                            <Fragment>
                                <div className={`product-items`}>
                                    <div className={`heading-parent-text`}>
                                        Promotional Budget
                                    </div>
                                    <div className={`description-text`}>
                                        {productDetails?.products_marketing?.[0]?.budgetname}
                                    </div>
                                </div>
                            </Fragment> : <></>
                    }
                    <div className="product-two-col">
                        {
                            productDetails?.products_marketing?.[0]?.video_url &&
                            <Fragment>
                                <div className={`product-vdo-items`}>
                                    <div className={`heading-parent-text`}>
                                        Product Video
                                    </div>
                                    <div className={`description-text`}>
                                        <iframe
                                            title="vimeo-player"
                                            className={`product-video-iframe`}
                                            src={productDetails?.products_marketing?.[0]?.video_url.replace('/watch?v=', '/embed/')}
                                            id="player"
                                            frameBorder="0"
                                            allowfullscreen
                                        >
                                        </iframe>
                                        {/* <a href={productDetails?.products_marketing?.[0]?.video_url} target={`_blank`}>{productDetails?.products_marketing?.[0]?.video_url}</a> */}
                                    </div>
                                </div>
                            </Fragment>
                        }

                        {
                            productDetails?.products_planogram_gallery && productDetails?.products_planogram_gallery?.length > 0 && planogramDefaultImage !== ``
                                ?
                                <Fragment>
                                    <div className={`product-vdo-items detail-carosuel`}>
                                        <div className={`heading-parent-text`}>
                                            Planogram
                                        </div>
                                        <div className={`description-text`}>
                                            <div className={`product-additional-image-gallery`}>
                                                <Slider
                                                    autoRotate={true}
                                                    showNextPrevBtn={true}
                                                    rotationTiming={5000}
                                                    backArrowHtml={
                                                        <div className={`prev-arrow`}>
                                                            <WestIcon />
                                                        </div>
                                                    }
                                                    nextArrowHtml={
                                                        <div className={`next-arrow`}>
                                                            <EastIcon />
                                                        </div>
                                                    }
                                                    defaultActiveSlide={planogramDefaultImage || 0}
                                                >
                                                    {
                                                        productDetails?.products_planogram_gallery && productDetails?.products_planogram_gallery?.length > 0 &&
                                                        productDetails?.products_planogram_gallery?.map((image, index) => {
                                                            return (
                                                                <div className={`image-wrap`} key={index}>
                                                                    <img className={`pro-img`} src={image?.image} alt={`product image`} loading="lazy" />
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                                :
                                <></>
                        }


                        {
                            productDetails?.products_additional_gallery && productDetails?.products_additional_gallery?.length > 0 && defaultImage !== ``
                                ?
                                <Fragment>
                                    <div className={`product-vdo-items detail-carosuel`}>
                                        <div className={`heading-parent-text`}>
                                            Additional images/Gallery
                                        </div>
                                        <div className={`description-text`}>
                                            <div className={`product-additional-image-gallery`}>
                                                <Slider
                                                    autoRotate={false}
                                                    showNextPrevBtn={true}
                                                    rotationTiming={5000}
                                                    backArrowHtml={
                                                        <div className={`prev-arrow`}>
                                                            <WestIcon />
                                                        </div>
                                                    }
                                                    nextArrowHtml={
                                                        <div className={`next-arrow`}>
                                                            <EastIcon />
                                                        </div>
                                                    }
                                                    defaultActiveSlide={defaultImage || 0}
                                                >
                                                    {
                                                        productDetails?.products_additional_gallery && productDetails?.products_additional_gallery?.length > 0 &&
                                                        productDetails?.products_additional_gallery?.map((image, index) => {
                                                            return (
                                                                <div className={`image-wrap`} key={index} onClick={() => { setIsImageViewShow(true); setImageViewerData({ image: image?.image }) }}>
                                                                    <img className={`pro-img`} src={imageResizing(image?.image, 500, 500, `fill`)} alt={`product image`} loading="lazy" />
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                                :
                                <></>
                        }

                        {
                            isImageViewShow &&
                            <PopupImageViewer
                                data={imageViewerData}
                                onPopupClose={(value) => { setIsImageViewShow(value); setImageViewerData({}) }}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
const PreviewDetailsPage = (props) => {

    const { selectedOptionValue, onSelectedOptionChange} = props;
    let catalogueId = useParams()[`catalogueId`] || props?.catalogueId;
    let productId = useParams()[`productId`] || props?.productId;
    let pageType = props?.pageType || `websiteCatalogueProductPreview`;
    let isMobile = props?.appData?.app?.isMobile;

    const { onBackClick, onPreviewClose } = props;
    const intervalTime = 60000, timeout = 180000;

    const [productDetails, setProductDetails] = useState({}),
        [selectedOption, setSelectedOption] = useState(selectedOptionValue || `Overview`),
        [isLoading, setIsLoading] = useState(false),
        [isWebsiteDetailViewEventRegister, setIsWebsiteDetailViewEventRegister] = useState(false),
        [isUserIdle, setIsUserIdle] = useState(false),
        [resetTemp, setResetTempValue] = useState(0),
        [productSettings, setProductSettings] = useState({}),
        [productPageSetting, setProductPageSetting] = useState({}),
        [optionMenu, setOptionMenu] = useState([`Overview`]),
        [isProductDetailsSectionShow, setProductDetailsSectionShow] = useState(false),
        [isTechSpecificationShow, setTechnicalSpecificationShow] = useState(false),
        [isCertificationApprovalSectionShow, setCertificationApprovalSectionShow] = useState(false),
        [isDistributionSectionShow, setDistributionSectionShow] = useState(false),
        [isMarketingSectionShow, setMarketingSectionShow] = useState(false);

    // const optionMenu = [`Overview`, `Product information`, `Distribution`, `Pricing`, `Certifications`, `Marketing`];
    // const optionMenu = [`Overview`];

    const moduleApis = [];
    const moduleApiIndex = [];

    const {
        reset,
        pause,
        resume,
    } = useIdleTimer({
        timeout,
        onActive: () => { setIsUserIdle(false); },
        onIdle: () => { setIsUserIdle(true); }
    })
    useEffect(() => {
        setIsLoading(true);

        moduleApis.push(getWebsiteBrand(catalogueId));
        moduleApiIndex.push(`brandIndex`);

        moduleApis.push(getWebSiteProductDetails(catalogueId, productId));
        moduleApiIndex.push(`productIndex`);


        Promise.all(moduleApis)
            .then((result) => {
                let brandIndex = moduleApiIndex.indexOf('brandIndex');
                if (brandIndex !== -1 && result.length) {
                    const dataResult = result[brandIndex] ? result[brandIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setProductSettings(dataResult.data.data.product_settings);
                        setProductPageSetting(dataResult.data.data.product_page_settings);
                    }
                }

                let productIndex = moduleApiIndex.indexOf('productIndex');
                if (productIndex !== -1 && result.length) {
                    const dataResult = result[productIndex] ? result[productIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setProductDetails(dataResult.data.data[0]);
                    }
                }

                setIsLoading(false);
            })

        if (!isWebsiteDetailViewEventRegister) {
            websiteDetailsPreviewEvent(catalogueId, productId, readCookie(`websitePreviewEmail`) || ``)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status) {
                        setIsWebsiteDetailViewEventRegister(true);
                    } else {
                        setIsWebsiteDetailViewEventRegister(false);
                    }
                })
                .catch((err) => {
                    setIsWebsiteDetailViewEventRegister(false);
                    console.log("We have error at websiteDetailsPreviewEvent", err);
                })
        }

        if(pageType === `catalogue`) {
            document.getElementsByClassName(`website-product-details-wrapper`)[0].scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'start' });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [catalogueId, productId])

    useEffect(() => {
        if (pageType === `websiteCatalogueProductPreview`) {
            websiteDetailsDurationApiCall();
        }
    }, [resetTemp])

    const onCataloguePreviewBackClick = (value) => {
        if (onBackClick) onBackClick();
    }

    useEffect(() => {
        if (!isUserIdle && resetTemp !== 0 && pageType === `websiteCatalogueProductPreview`) {
            websiteDetailsDurationApiCall();
        }
    }, [isUserIdle])

    useEffect(() => {
        let tempArray = ['Overview'];
        for (var key in productPageSetting) {
            if (productPageSetting.hasOwnProperty(key)) {
                if (productPageSetting[key]) {
                    switch (key) {
                        case 'pro_detail_page':
                            if (isProductDetailsSectionShow) {
                                tempArray.push(`Product Details`);
                            }
                            break;
                        case 'tech_spec_page':
                            if (isTechSpecificationShow) {
                                tempArray.push(`Technical Specifications`);
                            }
                            break;
                        case 'cert_and_apvl_page':
                            if (isCertificationApprovalSectionShow) {
                                tempArray.push(`Certifications & Approvals`);
                            }
                            break;

                        case 'distribution_page':
                            if (isDistributionSectionShow) {
                                tempArray.push(`Distribution`);
                            }
                            break;

                        case 'marketing_page':
                            if (isMarketingSectionShow) {
                                tempArray.push(`Marketing`);
                            }
                            break;
                    }
                }

            }
        }
        setOptionMenu(tempArray);
    }, [productPageSetting, isProductDetailsSectionShow, isProductDetailsSectionShow, isCertificationApprovalSectionShow, isDistributionSectionShow, isMarketingSectionShow]);

    useEffect(() => {
        let menu = `Overview`;
        let scrollValue = 500;

        window.addEventListener("scroll", () => {
            if (document.getElementById('overview') && document.getElementById('overview').getBoundingClientRect().top <= scrollValue) {
                menu = `Overview`;
            } if (document.getElementById('product-detail') && document.getElementById('product-detail').getBoundingClientRect().top <= scrollValue) {
                menu = `Product Details`;
            } if (document.getElementById('technical-specification') && document.getElementById('technical-specification').getBoundingClientRect().top <= scrollValue) {
                menu = `Technical Specifications`;
            } if (document.getElementById('certification') && document.getElementById('certification').getBoundingClientRect().top <= scrollValue) {
                menu = `Certifications & Approvals`;
            } if (document.getElementById('distribution') && document.getElementById('distribution').getBoundingClientRect().top <= scrollValue) {
                menu = `Distribution`;
            } if (document.getElementById('marketing') && document.getElementById('marketing').getBoundingClientRect().top <= scrollValue) {
                menu = `Marketing`;
            }

            setSelectedOption(menu);
        })

        return () => {
            window.removeEventListener("scroll", {});
        }
    }, []);

    useEffect(() => {
        setProductDetailsSectionShow(productPageSetting?.pro_detail_page ? true : false)
        // setProductDetailsSectionShow(productPageSetting?.pro_detail_page && (productDetails?.product_packaging?.[0]?.product_packaging || productDetails?.products_detail?.[0]?.shelf_life || productDetails?.products_detail?.[0]?.pro_ingredients || productDetails?.products_detail?.[0]?.nutritional_label) ? true : false)
        setTechnicalSpecificationShow(productPageSetting?.tech_spec_page && productDetails?.products_tech_specification && productDetails?.products_tech_specification.length > 0 ? true : false);
        setCertificationApprovalSectionShow(productPageSetting?.cert_and_apvl_page && (productDetails?.country_name || productDetails?.products_certification?.[0]?.other_certification) ? true : false);
        setDistributionSectionShow(productPageSetting?.distribution_page && (productDetails?.products_distribution?.[0]?.pro_availability || productDetails?.products_distributors && productDetails?.products_distributors.length > 0 || productDetails?.products_sold_countries && productDetails?.products_sold_countries.length > 0 || productDetails?.products_distribution?.[0]?.pro_cold_storage) ? true : false);
        setMarketingSectionShow(productPageSetting?.marketing_page && (productDetails?.products_marketing?.length > 0 || productDetails?.products_additional_gallery && productDetails?.products_additional_gallery?.length > 0) ? true : false);
    }, [productDetails])

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const websiteDetailsDurationApiCall = async () => {
        await delay(intervalTime);
        catalogueViewDetailsDurationEvent(catalogueId, productId, readCookie(`websitePreviewEmail`) || ``, Math.floor(intervalTime / 1000))
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    console.log("Website preview details event register successfully");
                    if (!isUserIdle) setResetTempValue(Math.random())
                }
            })
            .catch((err) => {
                console.log("we have error at catalogueViewDetailsDurationEvent", err);
            });
    }

    const onMenuChange = (menu) => {

        if(onSelectedOptionChange) onSelectedOptionChange(menu);
        let scrollId = ``;
        switch (menu) {
            case `Overview`:
                scrollId = 'overview';
                break;

            case `Product Details`:
                scrollId = 'product-detail';
                break;

            case `Technical Specifications`:
                scrollId = 'technical-specification';
                break;

            case `Certifications & Approvals`:
                scrollId = 'certification';
                break;

            case `Distribution`:
                scrollId = 'distribution';
                break;

            case `Marketing`:
                scrollId = 'marketing';
                break;
        }

        setSelectedOption(menu);

        if(pageType === `catalogue`) {
            let element = document.getElementById(scrollId);
            element.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'start' });
            element.scrollTop -= 120;

        } else {
             window.scrollTo({
                top: document.getElementById(scrollId).offsetTop - 120, left: 0, behavior: "smooth",
            });
        }

    }

    const updateProductDetail = (productDetail) => {
        setProductDetails(productDetail);
    }

    const handleScroll = () => {
        console.log(` onScroll={handleScroll}`)
    }
    return (
        <Box className={`website-product-details-wrapper`}  onScroll={handleScroll}>
            <div className={`box-container`}>
                {isLoading && <Loader />}

                {
                    pageType !== `websiteCatalogueProductPreview` &&
                    <PreviewExitSction
                        onPreviewClose={onPreviewClose}
                        onBackClick={onBackClick}
                    />
                }
                <HeaderSection
                    productDetails={productDetails}
                    catalogueId={catalogueId}
                    pageType={pageType}
                    onBackClick={onCataloguePreviewBackClick}
                    productPageSetting={productPageSetting}
                />

                <Box className={`manage-section`}>
                    <Box className={`option-wrapper`}>
                        {
                            optionMenu && optionMenu.map((menu, index) => {
                                return (
                                    <div key={index} className={`option ${selectedOption === menu ? 'selected' : 'not-selected'}`} onClick={() => { onMenuChange(menu) }}>
                                        {menu}
                                    </div>
                                )
                            })
                        }
                    </Box>
                    <div className={`divider`}></div>
                </Box>

                <OverViewSection
                    productDetails={productDetails}
                    catalogueId={catalogueId}
                    productSettings={productSettings}
                    updateProductDetail={updateProductDetail}
                />

                {
                    isProductDetailsSectionShow &&
                    <ProductDetail
                        productDetails={productDetails}
                        catalogueId={catalogueId}
                        productSettings={productSettings}
                        isMobile={isMobile}
                    />
                }
                {
                    isTechSpecificationShow &&
                    <TechnicalSpecifiction
                        productDetails={productDetails}
                        catalogueId={catalogueId}
                        productSettings={productSettings}
                    />
                }
                {
                    isCertificationApprovalSectionShow &&
                    <CertificationsAndApprovals
                        productDetails={productDetails}
                        catalogueId={catalogueId}
                        productSettings={productSettings}
                    />
                }
                {
                    isDistributionSectionShow &&
                    <Distribution
                        productDetails={productDetails}
                        catalogueId={catalogueId}
                        productSettings={productSettings}
                    />
                }
                {
                    isMarketingSectionShow &&
                    <Marketing
                        productDetails={productDetails}
                        catalogueId={catalogueId}
                        productSettings={productSettings}
                    />
                }
            </div>
        </Box>
    )
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewDetailsPage);